import React from 'react';
import './EmployeeList.css'
import Api from "../../../Providers/Api";
import { Dropdown, Button, Modal, Form, FormControl } from "react-bootstrap";
import { ThemeProvider } from "@material-ui/styles";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    createMuiTheme,
    Tabs,
    Tab,
    Box,
    IconButton
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { ToastContainer, toast } from 'react-toastify';

var _ = require('underscore');
var api = new Api();

/**
 * Default color theme  
 */
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#66CC75'
        },
    },
});

/**
* Functional component to render Tabs content 
*/
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`simple-tabpanel-${index}`}
            // aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}


export default class EmployeeList extends React.Component {
    constructor() {
        super();
        this.state = {
            employeeList: [],
            date: new Date(),
            empid: 0,
            name: "",
            mobile: "",
            email: "",
            emailoffice: "",
            emptype: "",
            birthday: "",
            emproletype: "Select Employee Role",
            isEmployeeModalOpen: false,
            isEmployeeConfirmModalOpen: false,
            //pi abbreviates to personal info
            piPosition: '',
            piDOB: new Date(),
            piPermanentAddress: '',
            piGender: '',
            piCorrespondenceAddress: '',
            piAadhar: '',
            piBankName: '',
            piBankAccNo: '',
            piPanNo: '',
            piEmail: '',
            piIFSC: '',
            piMaritalStatus: '',
            piBranchName: '',
            piDOj: new Date(),
            piPhNo1: '',
            piPhNo2: '',
            // enrll abbreviates to enrollment
            enrllName: '',
            enrllPhNo: '',
            enrllEmail: '',
            enrllEmplType: '',
            enrllInternPeriod: '',
            enrllTimeZone: '',
            enrllDOJ: new Date(),
            tabValue: 0,
            isEditData: false,
            isEmplyDeleteDialogOpen: false,
            emplIdDeactivatingEmpl: ''
        };
        this.GetEmployee();
    }

    /**
     * function to handle the tab rendering
     */
    tabHandler = (e, value) => {
        this.setState({
            tabValue: value
        })
    }

    /**
     * function to get the list of the employees
     */
    GetEmployee() {
        const param = '/ShowAllEmployee';
        api.getApi(param).then(res => {
            console.log(res);
            const tempData = [];
            if (res.data.status === 200) {
                for (let i = 0; i < res.data.response.length; i++) {
                    const temp = res.data.response[i];
                    temp.index = i + 1;
                    tempData.push(temp);
                }
            } else {

            }
            this.setState({
                employeeList: tempData
            })
        }).catch(err => {
            // console.log(err);
        });
    }

    /**
     * function to sort the data
     */
    sortEmployeeList() {
        const tempData = _.sortBy(this.state.employeeList, 'name');
        for (let i = 0; i < tempData.length; i++) {
            tempData[i].index = i + 1
        }
        this.setState({
            employeeList: tempData
        })
    }

    /**
     * function to add the empployee
     */
    addEmployee() {
        if (!this.Editstatus) {
            // Code to get the DOB in YYYY-MM-DD format
            let byear, bmonth, bdate;
            byear = this.state.date.getFullYear();
            if (this.state.date.getMonth() + 1 < 10) {
                bmonth = `0${this.state.date.getMonth() + 1}`;
            }
            else {
                bmonth = this.state.date.getMonth() + 1;
            }
            if (this.state.date.getDate() < 10) {
                bdate = `0${this.state.date.getDate()}`
            }
            else {
                bdate = this.state.date.getDate();
            }
            this.state.birthday = byear + '-' + bmonth + '-' + bdate;
            // console.log(this.state.birthday);
        }
        const param = {
            api: '/addUpdateEmployee',
            data: {
                empid: 0,
                name: this.state.name,
                mobile: this.state.mobile,
                email: this.state.email,
                emailoffice: this.state.emailoffice,
                emptype: 'E',
                birthday: !this.Editstatus ? this.state.birthday : this.state.date  ,
                emproletype: this.state.emproletype,
            }
        };
        // console.log(param);
        api.postApi(param).then(res => {
            console.log(res);
            if (res.data.status === 200) {
                this.GetEmployee();
                this.setState({
                    empid: '',
                    name: '',
                    mobile: '',
                    email: '',
                    emailoffice: '',
                    birthday: '',
                    emproletype: '',
                    date: new Date()
                });
                this.Editstatus = false;
                const notifyEmployeeAddedSuccess = () => {
                    toast.success("Employee Details Added Sucessfully", {
                      position: "top-center",
                      autoclose: 10000,
                    })
                  };
                  notifyEmployeeAddedSuccess();
            } 
            else if(res.data.response === '23505: duplicate key value violates unique constraint "unique_email",EmployeeEntityModel.CS/addUpdateEmployee' ) {
                const notifyEmployeeAddedError = () => {
                    toast.error("Employee Exists", {
                      position: "top-center",
                      autoclose: 10000,
                    })
                  };
                  notifyEmployeeAddedError();
            }
            else{
                const notifyError = () => {
                    toast.error("Something went wrong", {
                      position: "top-center",
                      autoclose: 10000,
                    })
                  };
                  notifyError();
            }
        }).catch(err => {
            window.alert(JSON.stringify(err));
            console.log(err);
        })
    }

    // /**
    //  * function for active deactive employee
    //  */
    // activeDeactiveEmployee(empid) {
    //     const param = {
    //         api: '/deactivateEmployee',
    //         data: {
    //             empid: empid
    //         }
    //     };
    //     this.handleEmplDeleteModal();
    //     console.log(this.state.isEmplDeleting, "deleted empl");
    //     if (this.state.isEmplDeleting === true) {
    //         api.postApi(param).then(res => {
    //             console.log(res);
    //             this.GetEmployee();
    //             const notifyEmployeeDeleted = () => {
    //                 toast.success("Employee removed Sucessfully", {
    //                   position: "top-center",
    //                   autoclose: 10000,
    //                 })
    //               };
    //               notifyEmployeeDeleted();
    //               this.setState({
    //                   isEmplDeleting: false
    //               })
    //         }).catch(err => {
    //             window.alert(JSON.stringify(err));
    //             this.setState({
    //                 isEmplDeleting: false
    //             })
    //         });
    //     }
    // }
    
    /**
     * function to edit the employee details
     */
    editEmployeeDetails = (emplDetails) => {
        console.log(emplDetails);
        let role;
        if (emplDetails.emproletype === 'Probationary Employee') {
            role = 'P';
        } else if (emplDetails.emproletype === 'Full Time Employee') {
            role = 'F';
        } else {
            role = 'I';
        }
        this.setState({
            empid: emplDetails.empid,
            name: emplDetails.name,
            mobile: emplDetails.mobile,
            email: emplDetails.email,
            emailoffice: emplDetails.emailoffice,
            date: emplDetails.birthday,
            emproletype: role
        });
        this.Editstatus = true;
    }

    /**
     * function to get the employee information
     */
    getEmployeeInfo(employee) {
        console.log(employee);
    }

    /**
     * function for add employee modal
     */
    handleAddEmployeeModal() {
        this.setState({
            isEmployeeModalOpen: !this.state.isEmployeeModalOpen
        })
    }

    /**
     * function for confirm employee modal
     */
    handleEmplConfirmModal() {
        // console.log('confirm modal');
        this.setState({
            isEmployeeConfirmModalOpen: !this.state.isEmployeeConfirmModalOpen
        })
    }

    /**
     * function for Employee Delete Confirmation Modal handling
     */
    handleEmplDeleteModal = (empid) => {
        this.setState({
            isEmplyDeleteDialogOpen: !this.state.emplIdDeactivatingEmpl,
            emplIdDeactivatingEmpl: empid 
        })
    }

    /**
     * function to handle confirm button of Empl Delete Modal
     * This function is used to deactive employees
     */
    confirmDeleteEmplModal = () => {
        // console.log("Confirm btn pushed");
        const param = {
            api: '/deactivateEmployee',
            data: {
                empid: this.state.emplIdDeactivatingEmpl
            }
        };
        
            api.postApi(param).then(res => {
                console.log(res);
                this.GetEmployee();
                const notifyEmployeeDeleted = () => {
                    toast.success("Employee removed sucessfully", {
                      position: "top-center",
                      autoclose: 10000,
                    })
                  };
                  notifyEmployeeDeleted();
                  this.handleEmplDeleteModal();
            }).catch(err => {
                window.alert(JSON.stringify(err));
            });
        
    }

    render() {
        return (
            <div className={'employeeList'}>
                <ToastContainer />
                {/* Tab header starts here */}
                <ThemeProvider theme={defaultMaterialTheme}>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.tabHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullwidth"
                        className={'tabsHeader'}
                    >
                        <Tab label="Old Form" className={'tabs'} />
                        <Tab label="New Form" className={'tabs'} />
                    </Tabs>
                </ThemeProvider>
                {/* Tab header ends here */}

                {/* Tab content starts here */}
                {/* For Older form Pattern */}
                <TabPanel value={this.state.tabValue} index={0} className={'tabsPanel'}>
                    <div className="card form-group mt-2">
                        <div className="card-header"><h6>Add new Employee</h6></div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <input type="text" placeholder={'Enter Name'} className={'form-control employeename text-dark m-1'} value={this.state.name} onChange={(ev) => (
                                        this.setState({
                                            name: ev.target.value
                                        })
                                    )} />
                                </div>
                                <div className="col-md-3">
                                    <input type="email" placeholder={'Enter Email'} className={'form-control employeemail text-dark m-1'} value={this.state.email} onChange={(ev) => {
                                        this.setState({
                                            email: ev.target.value
                                        })
                                    }} />
                                </div>
                                <div className="col-md-3">
                                    <input type="email" placeholder={'Enter Office Email'} className={'form-control employeemail text-dark m-1'} value={this.state.emailoffice} onChange={(ev) => {
                                        this.setState({
                                            emailoffice: ev.target.value
                                        })
                                    }} />
                                </div>
                                <div className="col-md-3">
                                    <input type="number" placeholder={'Enter Mobile Number'} className={'form-control employeename text-dark m-1'} value={this.state.mobile} onChange={(ev) => {
                                        this.setState({
                                            mobile: ev.target.value
                                        })
                                    }} />
                                </div>
                                <div className="col-md-3">
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                // margin="normal"
                                                // id="date-picker-dialog"
                                                // label="DOB*"
                                                format="MM/dd/yyyy"
                                                value={this.state.date}
                                                onChange={(e) => this.setState({
                                                    date: e
                                                })}
                                            // KeyboardButtonProps={{
                                            //     'aria-label': 'change date',
                                            // }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </div>
                                <div className="col-md-3">
                                    <Dropdown onSelect={(ev) => {
                                        // console.log(ev)
                                    }}>
                                        <Dropdown.Toggle id="emptyp-dropdown" className="form-control">
                                            {this.state.emproletype === 'F' ? 'Full Time Employee' : (this.state.emproletype === 'P' ? 'Probationary Employee' : (this.state.emproletype === 'I' ? 'Intern' : 'Select Employee Type'))}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={(ev) => {
                                                this.setState({
                                                    emproletype: 'F'
                                                })
                                            }}>
                                                Full Time Employee
                                    </Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                this.setState({
                                                    emproletype: 'P'
                                                });
                                            }}>
                                                Probationary Employee
                                    </Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                this.setState({
                                                    emproletype: 'I'
                                                })
                                            }}>
                                                Intern
                                    </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="offset-9 col-md-3 oldFormBtns">
                                <button
                                    type="submit"
                                    className="submitBtn float-right"
                                    disabled={
                                        !(this.state.name &&
                                        this.state.mobile &&
                                        this.state.email &&
                                        this.state.emailoffice &&
                                        (this.state.emproletype === "F" || this.state.emproletype === 'P' || this.state.emproletype === 'I'))
                                    }
                                    onClick={this.addEmployee.bind(this)}
                                >{this.Editstatus ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* old employee list */}
                    <div className="employee_data">
                        <table className="table mb-0 table-bordered bg-light">
                            <thead>
                                <tr>
                                    <th className={'text-center'}>#</th>
                                    <th onClick={this.sortEmployeeList.bind(this)}>Employee Name</th>
                                    <th className={'text-center'}>Employee Email</th>
                                    <th className={'text-center'}>Employee number</th>
                                    <th className={'text-center'}>Employee DOB</th>
                                    <th className={'text-center'}>Employee type</th>
                                    <th className={'text-center'}>Employee status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    this.state.employeeList.map((item) => (
                                        <tr>
                                            <td className={'align-middle text-center'}>{item.index}</td>
                                            <td className={'align-middle'}>{item.name}</td>
                                            <td className={'align-middle text-center'}>{item.emailoffice}</td>
                                            <td className={'align-middle text-center'}>{item.mobile ? item.mobile : '--'}</td>
                                            <td className={'align-middle text-center'}>{item.birthday}</td>
                                            <td className={'align-middle text-center'}>{item.emproletype}</td>
                                            <td className={'text-center btnSection align-middle'}>
                                                <button
                                                    className={item.isactive === 'Active' ? 'buttons btn btn-primary' : 'btn btn-danger buttons mr-2'}
                                                    onClick={() => (
                                                        this.handleEmplDeleteModal(item.empid)
                                                        // this.activeDeactiveEmployee(item.empid)
                                                    )}
                                                    style={{ width: '55px' }}
                                                >
                                                    Remove
                                                </button>
                                                <button className={'btn btn-secondary buttons'} onClick={(e) => this.editEmployeeDetails(item)}>
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* old employee list end */}

                    <div>
                        <Modal

                            show={this.state.employeeAdd}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <section className={'addEmployeeModal'}>
                                <h6>Add new Employee</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="text" placeholder={'Enter Name'} className={'form-control employeename text-dark'} value={this.state.name} onChange={(ev) => (
                                            this.setState({
                                                name: ev.target.value
                                            })
                                        )} />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="email" placeholder={'Enter Email'} className={'form-control employeemail text-dark'} value={this.state.email} onChange={(ev) => {
                                            this.setState({
                                                email: ev.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="email" placeholder={'Enter Office Email'} className={'form-control employeemail text-dark'} value={this.state.emailoffice} onChange={(ev) => {
                                            this.setState({
                                                emailoffice: ev.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="number" placeholder={'Enter Mobile Number'} className={'form-control employeename text-dark'} value={this.state.mobile} onChange={(ev) => {
                                            this.setState({
                                                mobile: ev.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="col-md-12">
                                        {/* <DatePicker
                                        className={'addEmployeeDatePicker form-control'}
                                        placeholderText={'yyyy-mm-dd'}
                                        dateFormat="yyyy-MM-dd"
                                        selected={this.state.date}
                                        onChange={(ev) => {
                                            this.setState({
                                                date: ev
                                            });
                                            if (ev.getMonth() < 10) {
                                                if (ev.getDate() < 10) {
                                                    this.state.birthday = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                } else {
                                                    this.state.birthday = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                }
                                            } else {
                                                if (ev.getDate() < 10) {
                                                    this.state.birthday = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                } else {
                                                    this.state.birthday = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                }
                                            }
                                            console.log(this.state.birthday);
                                        }}
                                    /> */}
                                    </div>
                                    <div className="col-md-12">
                                        <Dropdown onSelect={(ev) => {
                                            // console.log(ev)
                                        }}>
                                            <Dropdown.Toggle id="emptyp-dropdown" className="form-control">
                                                {this.state.emproletype === 'F' ? 'Full Time Employee' : (this.state.emproletype === 'P' ? 'Probationary Employee' : (this.state.emproletype === 'I' ? 'Intern' : 'Select Employee Type'))}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(ev) => {
                                                    this.setState({
                                                        emproletype: 'F'
                                                    })
                                                }}>
                                                    Full Time Employee
                                    </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    this.setState({
                                                        emproletype: 'P'
                                                    });
                                                }}>
                                                    Probationary Employee
                                    </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    this.setState({
                                                        emproletype: 'I'
                                                    })
                                                }}>
                                                    Intern
                                    </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-light btn-approve addbtn "
                                        onClick={this.addEmployee.bind(this)} onClick={() => (
                                            this.setState({
                                                employeeAdd: false
                                            })
                                        )}
                                    >{this.Editstatus ? 'Update' : 'Add'}
                                    </button>
                                </div>
                            </section>
                        </Modal>
                    </div>

                   
                </TabPanel>

                {/* For New Form Pattern*/}
                <TabPanel value={this.state.tabValue} index={1} className={'tabsPanel'}>
                    <div className={'row mb-4 pt-1'}>
                        <div className={'col-md-4'}>
                            <div className="searchbox">
                                <form>
                                    <input type="search" placeholder="Search" />
                                </form>
                            </div>
                        </div>
                        <div className={'col-md-8'}>
                            <Button className={'addEnrollBtn'} onClick={() => this.handleAddEmployeeModal()}>Enrol <i class="fas fa-plus" aria-hidden="true"></i></Button>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-4 leftEmployeeList'}>
                            <div className={'card p-0'}>
                                <div className={'card-body p-0 pb-1'}>
                                    <div className="employee_data">
                                        <table className="table mb-0 table-bordered bg-light">
                                            <thead>
                                                <tr>
                                                    <th className={'text-center'}>ID</th>
                                                    <th onClick={this.sortEmployeeList.bind(this)}>Name of Employee</th>
                                                    <th className={'text-center'}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.employeeList.map((item) => (
                                                        <tr onClick={(e) => this.getEmployeeInfo(item)}>
                                                            <td className={'align-middle text-center'}>{item.index}</td>
                                                            <td className={'align-middle'}>{item.name}</td>
                                                            <td className={'text-center'}>
                                                                <button
                                                                    className={item.isactive === 'Active' ? 'buttons btn btn-primary' : 'btn btn-danger buttons mr-2'}
                                                                    onClick={() => (
                                                                        this.activeDeactiveEmployee(item.empid)
                                                                    )}
                                                                >
                                                                    Deactive
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-8 rightEmployeeDetail'}>
                            <div className={'card'}>
                                <div className={'card-header'}>
                                    <div className={'row'}>
                                        <div className={'col-10'}>
                                            <div className="userHead">
                                                <div className="user">
                                                    <img src="../iron-man.jpg" alt="user-img" className="img-fluid" />
                                                </div>
                                                <div className="designation">
                                                    <h6>Dummy User <span className={'empType'}>(Full Time)</span></h6>
                                                    <p className="status"><span>DOJ: 01/01/2019</span> <span className={'pl-2'}>ID: 0001</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-2 text-right'}>
                                            <IconButton onClick={() => this.setState({ isEditData: !this.state.isEditData })}>
                                                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div className={'card-body'}>
                                    <h6>Personal Info</h6>
                                    <div className={'row'}>
                                        <div className={'col-sm-10'}>
                                            <div className={'row'}>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Position"
                                                            type="text"
                                                            value={this.state.piPosition}
                                                            onChange={(e) => this.setState({
                                                                piPosition: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col-sm-4'}>
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                // margin="normal"
                                                                // id="date-picker-dialog"
                                                                label="DOB*"
                                                                format="MM/dd/yyyy"
                                                                value={this.state.piDOB}
                                                                onChange={(e) => this.setState({
                                                                    piDOB: e
                                                                })}
                                                            // KeyboardButtonProps={{
                                                            //     'aria-label': 'change date',
                                                            // }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </ThemeProvider>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Permanent Address"
                                                            type="text"
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <select class="mdb-select md-form"
                                                        value={this.state.piGender}
                                                        onChange={(e) => this.setState({
                                                            piGender: e.target.value
                                                        })}>
                                                        <option value="" disabled selected>Gender</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Correspondence Address"
                                                            type="text"
                                                            value={this.state.piCorrespondenceAddress}
                                                            onChange={(e) => this.setState({
                                                                piCorrespondenceAddress: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Aadhar Number*"
                                                            type="text"
                                                            value={this.state.piAadhar}
                                                            onChange={(e) => this.setState({
                                                                piAadhar: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Bank Name"
                                                            type="text"
                                                            value={this.state.piBankName}
                                                            onChange={(e) => this.setState({
                                                                piBankName: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="PAN Number"
                                                            type="text"
                                                            value={this.state.piPanNo}
                                                            onChange={(e) => this.setState({
                                                                piPanNo: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Bank Account Number"
                                                            type="text"
                                                            value={this.state.piBankAccNo}
                                                            onChange={(e) => this.setState({
                                                                piBankAccNo: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <select class="mdb-select md-form"
                                                        value={this.state.piEmail}
                                                        onChange={(e) => this.setState({
                                                            piEmail: e.target.value
                                                        })}>
                                                        <option value="" selected>E mail id</option>
                                                        <option value="1">first email</option>
                                                        <option value="2">secound email</option>
                                                    </select>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="IFSC Code"
                                                            type="text"
                                                            value={this.state.piIFSC}
                                                            onChange={(e) => this.setState({
                                                                piIFSC: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Marital Status"
                                                            type="text"
                                                            value={this.state.piMaritalStatus}
                                                            onChange={(e) => this.setState({
                                                                piMaritalStatus: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <Form className={"form"}>
                                                        <Form.Control
                                                            placeholder="Branch Name"
                                                            type="text"
                                                            value={this.state.piBranchName}
                                                            onChange={(e) => this.setState({
                                                                piBranchName: e.target.value
                                                            })}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className={'col-sm-4'}>
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                // margin="normal"
                                                                // id="date-picker-dialog"
                                                                label="DOJ*"
                                                                format="MM/dd/yyyy"
                                                                value={this.state.piDOj}
                                                                onChange={(e) => this.setState({
                                                                    piDOj: e
                                                                })}
                                                            // KeyboardButtonProps={{
                                                            //     'aria-label': 'change date',
                                                            // }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </ThemeProvider>
                                                </div>
                                                <div className={'col-sm-8'}>
                                                    <div className={'row'}>
                                                        <div className={'col-sm-6'}>
                                                            <Form className={"form"}>
                                                                <Form.Control
                                                                    placeholder="Phone Number 1"
                                                                    type="number"
                                                                    value={this.state.piPhNo1}
                                                                    onChange={(e) => this.setState({
                                                                        piPhNo1: e.target.value
                                                                    })}
                                                                />
                                                            </Form>
                                                        </div>
                                                        <div className={'col-sm-6'}>
                                                            <Form className={"form"}>
                                                                <Form.Control
                                                                    placeholder="Phone Number 2"
                                                                    type="number"
                                                                    value={this.state.piPhNo2}
                                                                    onChange={(e) => this.setState({
                                                                        piPhNo2: e.target.value
                                                                    })}
                                                                />
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-sm-2'}>
                                            <ul>
                                                <li>
                                                    <div className={'imageBox'}></div>
                                                    <p>Aadhar</p>
                                                </li>
                                                <li>
                                                    <div className={'imageBox'}></div>
                                                    <p>PAN</p>
                                                </li>
                                                <li>
                                                    <div className={'imageBox'}></div>
                                                    <p>Other</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                {/* Tab content ends here */}

                {/* Delete Employee Confirmation Modal starts here*/}
                    <Dialog open={this.state.isEmplyDeleteDialogOpen}>
                        <div className={'deleteEmplModal'}>
                            <h5>Do you really want to deactivate this employee ?</h5>
                            <div className={'confirmButtonGroup float-right'}>
                                <button className={'cancelBtn'} onClick={() => this.handleEmplDeleteModal()}>Cancel</button>
                                <button className={'submitBtn'} onClick={() => this.confirmDeleteEmplModal()}>Confirm</button>
                            </div>
                        </div>
                    </Dialog>
                {/* Delete Employee Confirmation Modal ends here*/}

                {/* Enroll Dialog Box start */}
                <Dialog open={this.state.isEmployeeModalOpen} >
                    <div className={'addEmplModal text-center'}>
                        <Form className={'form'}>
                            <Form.Control
                                type='text'
                                placeholder='Name*'
                                value={this.state.enrllName}
                                onChange={(e) => this.setState({
                                    enrllName: e.target.value
                                })}
                            />
                        </Form>
                        <Form className={'form'}>
                            <Form.Control
                                type='number'
                                placeholder='Phone Number*'
                                value={this.state.enrllPhNo}
                                onChange={(e) => this.setState({
                                    enrllPhNo: e.target.value
                                })} />
                        </Form>
                        <Form className={'form'}>
                            <Form.Control
                                type='text'
                                placeholder='E-mail Address*'
                                value={this.state.enrllEmail}
                                onChange={(e) => this.setState({
                                    enrllEmail: e.target.value
                                })} />
                        </Form>
                        <Form className={'form'}>
                            <Form.Control
                                as="select"
                                custom="true"
                                value={this.state.enrllEmplType}
                                onChange={(e) => this.setState({
                                    enrllEmplType: e.target.value
                                })}>
                                <option>Employee</option>
                                <option>Intern</option>
                                <option>Probation</option>
                            </Form.Control>

                        </Form>
                        {
                            this.state.enrllEmplType === 'Intern' ? (
                                <Form className={'form'}>
                                    <Form.Control
                                        type='text'
                                        placeholder='Intern Period'
                                        value={this.state.enrllInternPeriod}
                                        onChange={(e) => this.setState({
                                            enrllInternPeriod: e.target.value
                                        })} />
                                </Form>
                            ) : null
                        }

                        <Form className={'form'}>
                            <Form.Control
                                type='text'
                                placeholder='Time Zone'
                                value={this.state.enrllTimeZone}
                                onChange={(e) => this.setState({
                                    enrllTimeZone: e.target.value
                                })}
                            />
                        </Form>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // margin="normal"
                                    // id="date-picker-dialog"
                                    label="DOJ*"
                                    format="MM/dd/yyyy"
                                    value={this.state.enrllDOJ}
                                    onChange={(e) =>
                                        this.setState({
                                            enrllDOJ: e
                                        })}
                                // KeyboardButtonProps={{
                                //     'aria-label': 'change date',
                                // }}
                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                        <Button className="submitBtn text-center" variant="primary" onClick={() => {
                            this.handleAddEmployeeModal();
                            this.handleEmplConfirmModal();
                        }}>Save Details</Button>
                    </div>
                </Dialog>
                {/* Enroll Dialog Box end */}

                {/* Enroll Confirm Dialog Box start */}
                <Dialog open={this.state.isEmployeeConfirmModalOpen} onBackdropClick={() => this.handleEmplConfirmModal()}>
                    <div className={'emplConfirmModal text-center'}>
                        <h2>Details have been sent to {this.state.enrllEmail} for self enrollment</h2>
                    </div>
                </Dialog>
                {/* Enroll Confirm Dialog Box end */}


                {/* Edit Employee Data modal starts here */}
                <Modal
                    show={this.state.isEditData}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-70w"
                    aria-labelledby="example-custom-modal-styling-title"
                    onHide={() => this.setState({ isEditData: !this.state.isEditData })}
                >
                    <section className={"profilemodal"}>
                        <div className={"row pb-2"}>
                            <div className={"col-sm-3"}>
                                <h6>
                                    Personal Info <i className="fas fa-pen penIcon"></i>
                                </h6>
                            </div>
                            <div className={"col-sm-9"}>
                                <div
                                    className="float-right mb-1 text-right"
                                    role="group"
                                    aria-label="Basic example"
                                >
                                    <button
                                        className={"approve_reject_button btn btn-reject btnDay"}
                                    >
                                        Save{" "}
                                    </button>
                                    <button
                                        className={"approve_reject_button btn btn-approve btnDay"}
                                        onClick={() =>
                                            this.setState({
                                                profileModal: false,
                                            })
                                        }
                                    >
                                        Save and close{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-sm-4 userleftSec"}>
                                <img
                                    src="../user-img.png"
                                    alt="user-img"
                                    className="img-fluid"
                                />
                                <h6>Dummy User</h6>
                                <p>Full Time</p>
                                <p>Joined: 01/01/2019</p>
                            </div>
                            <div className={"col-sm-8  pr-5"}>
                                <div className={"row"}>
                                    <div className={"col-sm-6"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="Designation" type="text" />
                                        </Form>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-sm-4"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="DOB*" type="text" />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Permanent Address"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}>
                                        <select class="mdb-select md-form">
                                            <option value="" disabled selected>
                                                Gender
                        </option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Correspondence Address"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Aadhar Number*"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="Bank Name" type="text" />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="E mail id" type="text" />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Bank Account Number"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Marital Status"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="IFSC Code" type="text" />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}>
                                        <Form className={"form"}>
                                            <Form.Control
                                                placeholder="Anniversary Date"
                                                type="text"
                                            />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-8"}>
                                        <Form className={"form"}>
                                            <Form.Control placeholder="Branch Name" type="text" />
                                        </Form>
                                    </div>
                                    <div className={"col-sm-4"}></div>
                                    <div className={"col-sm-8"}>
                                        <div className={"row"}>
                                            <div className={"col-sm-6"}>
                                                <Form className={"form"}>
                                                    <Form.Control
                                                        placeholder="Phone Number 1"
                                                        type="number"
                                                    />
                                                </Form>
                                            </div>
                                            <div className={"col-sm-6"}>
                                                <Form className={"form"}>
                                                    <Form.Control
                                                        placeholder="Phone Number 2"
                                                        type="number"
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>
                {/* Edit Employee Data modal ends here */}


            </div>
        )
    }
}
