import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton'
import {
  Menu, 
  MenuItem 
} from '@material-ui/core';
import Send from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import './MessageTextBox.css';
import { MentionsInput, Mention } from 'react-mentions';
import Api from "../../../Providers/Api";

const api = new Api();

export default class MessageTextBox extends React.Component {

  constructor() {
    super();

    this.state = {
      chatText: "",
      recieverId: [],
      groupMembers: [],
      showGrpMembersMenu: null
    };
  }

  userDetail = JSON.parse(localStorage.getItem('userdetail'));

  // userTyping = (e) => {
  //   console.log("user typing");
  //   e.keyCode === 13
  //     ? this.sendMessage()
  //     : this.setState({ chatText: e.target.value });
  // };

  // validateMessage = (txt) => txt && txt.replace(/\s/g, "").length;

  componentDidMount(){
    this.getGroupMembers();
  }
  // componentDidUpdate(){
  //   this.getGroupMembers();
  // }

  /**
   * function to handle the text box
   */
  userTyping = (e) => {
   
    this.setState({
        chatText: e.target.value
      })
  }

  /**
   * function to send message
   */
  sendMessage = (e) => {
    e.preventDefault();
    if (this.props.recieverStatus !== 'Group') {
      this.state.recieverId.splice(0, this.state.recieverId.length);
      this.state.recieverId.push(this.props.recieverId);

      const param = {
        api: '/AddEmployeeChat',
        data: {
          sender_empid: this.userDetail.empid,
          receiver_empid: this.state.recieverId,
          chat: this.state.chatText,
        }
      };
      console.log(param);
      api.postApi(param).then((res) => {
        console.log(res);
        this.setState({
          chatText: ''
        })
        // this.props.getMsgView();
      })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      const param = {
        api: '/addEmployeeGroupChat',
        data: {
          sender_empid: this.userDetail.empid,
          group_id: this.props.recieverId,
          chat: this.state.chatText
        }
      };
      api.postApi(param)
        .then((res) => {
          console.log(res);
          this.setState({
            chatText: ''
          })
        })
        .catch((err) => {
          console.log(err);
        });
    }

  };

  /**
   * function to get the group members
   */
  getGroupMembers = () => {
    const param = `/ShowDetailOfGroup?groupid=${this.props.recieverId}`;
    api
    .getApi(param)
    .then((res) => {
      console.log(res);
      this.setState({
        groupMembers: res.data.response[0].emp_name,
      })
    })
    .catch((err) => {
      console.log(err);
    })
  }

  /**
   * function to note the key down event
   */
  keyDown = (e) => {
    if(e.keyCode === 50){
      this.setState({
        showGrpMembersMenu: e.currentTarget
      });
    }
  }

  

  /**
   * function to mention the group members in chat on typing '@' character
   */
  mentionGroupMembers = (e, members) => {
    this.setState({
      chatText: this.state.chatText.concat('@' + members),
      showGrpMembersMenu: null
    });
  }
  // userClickedOnInput = () => this.props.userClickedInputFn();

  render() {
    const { classes } = this.props;
    return (
      <div className={'textBox'}>
        <Menu
          id="simple-menu"
          anchorEl={this.state.showGrpMembersMenu}
          keepMounted
          open={Boolean(this.state.showGrpMembersMenu)}
          onClose={() => this.setState({ showGrpMembersMenu: null})}
        >
          {
            this.state.groupMembers.map((members) => (
              <MenuItem onClick={(e) => this.mentionGroupMembers(e, members)}>{members}</MenuItem>
            ))
          }
        </Menu>
        <TextField
              type='text'
              placeholder='Type your message...'
              value={this.state.chatText}
              onChange={(e) => this.userTyping(e)}
              onKeyDown={this.props.recieverStatus === 'Group' ? (e) => this.keyDown(e) : null}
              className={'input'} />
        {/* {
          this.props.recieverStatus === 'Group' ? (
            <TextField
              type='text'
              placeholder='Type your message...'
              value={this.state.chatText}
              onChange={(e) => this.handleMentionInput(e)}
              className={'input'} />
          ) : (
                <TextField
                  type='text'
                  placeholder="Type your message..."
                  value={this.state.chatText}
                  onChange={(e) => this.userTyping(e)}
                  id="chattextbox"
                  // onFocus={this.userClickedOnInput}
                  className={'input'}
                />
            )
        } */}
        <IconButton onClick={(e) => this.sendMessage(e)} className={'sendBtn'} >
          <Send className={'icon'} />
        </IconButton>
      </div>
    );
  }

}


