import React, { Component } from 'react';
import './ProjectInformation.css';
import {
    IconButton,
    Avatar,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    createMuiTheme,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    Button,
    Table,
} from 'react-bootstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider } from "@material-ui/styles";

/**
 * Default color theme  
 */
const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
          main: '#218838'
      },
    },
  });

/**
 * functional components to render invite team members row
 */
function InviteMembersRow() {
    return (
        <div className={'inviteMembersRow'}>
            <span>1.</span>
            <input className={'inputFields'}
                type="email"
                placeholder="Email" />
            <ThemeProvider theme={defaultMaterialTheme}>
                <FormControl className={'inputSelect'} >
                    <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                    //   value={age}
                    //   onChange={handleChange}
                    >
                        <MenuItem value='Sv'>Supervisor</MenuItem>
                        <p>Can see all team activity</p>
                        <MenuItem value='Wk'>Worker</MenuItem>
                        <p>Can see their own activity</p>
                    </Select>
                </FormControl>
            </ThemeProvider>
        </div>
    )
}


export default class ProjectInformation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isCostCollapse: false,
            isPendingCollapse: false,
            isInviteDialogOpen: false,
        }
    }

    /**
     *  Function to handle the Invite member Dialog 
     */
    inviteMemberDialogHandler = () => {
        this.setState({
            isInviteDialogOpen: !this.state.isInviteDialogOpen
        })
    }

    /**
     * Function to handle the Back Button 
     */
    backHandler = () => {
        this.props.history.push('/TrackerProject');
    }

    /**
     * function to handle the edit project button
     */
    editProjectHandler = () => {
        this.props.history.push('/AddProject')
    }

    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'projectInfo'}>
                    <div className={'header'}>
                        <div className={'leftSide'}>
                            <IconButton className={'backBtn'} onClick={this.backHandler}>
                                <ArrowBackIosIcon className={'backIcon'} />
                            </IconButton>
                            <Avatar className={'avatar'}>O</Avatar>
                            <div>
                                <h6>OPM</h6>
                                <p className={'text'}>Created on Nov 26 | Admin XYZ </p>
                            </div>
                        </div>
                        <div>
                            <IconButton className={'addEmployeeBtn'} onClick={this.inviteMemberDialogHandler}>
                                <GroupAddIcon className={'groupIcon'} />
                            </IconButton>
                            <Button variant="success" onClick={this.editProjectHandler}>Edit Project</Button>
                            <IconButton className={'settingsBtn'}>
                                <MoreHorizIcon className={'settingsIcon'} />
                            </IconButton>
                        </div>
                    </div>

                    {/* Project Information card starts here */}
                    <p className={'text'}> <strong>Payments Summary</strong> Nov 26-30</p>
                    <div className={'infoCard'}>
                        <div className={'cost widthBox'}>
                            <div>
                                <p className={'costTitle'}>Total Paid</p>
                                <p className={'costText'}>Rs 0.00</p>
                            </div>

                            <Collapse in={this.state.isCostCollapse} timeout="auto" unmountOnExit className={'collpaseContent'}>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'costTitle'}>Total Paid Amount</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'costTitle'}>Rs 0.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'text'}>Expenses, Taxes</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'text'}>Rs 0.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'text'}>Discount</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'text'}>Rs 0.0</p>
                                    </div>
                                </div>
                            </Collapse>

                            <IconButton
                                className={'collapseBtn'}
                                onClick={() => {
                                    this.setState({
                                        isCostCollapse: !this.state.isCostCollapse,
                                        isPendingCollapse: false
                                    })
                                }}
                                aria-expanded={this.state.isCostCollapse}
                                aria-label="show more"
                            >
                                {
                                    this.state.isCostCollapse ? <ChevronLeftIcon className={'collpaseIcon'} /> : <ChevronRightIcon className={'collpaseIcon'} />
                                }
                            </IconButton>

                            <div className={'verticalLine'}></div>
                        </div>
                        <div className={'pending widthBox'}>
                            <div>
                                <p className={'pendingTitle'}>Pending</p>
                                <p className={'costText'}>Rs 0.00</p>
                            </div>

                            <Collapse in={this.state.isPendingCollapse} timeout="auto" unmountOnExit className={'collpaseContent'}>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'costTitle'}>Tracked Amount</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'costTitle'}>Rs 0.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'text'}>Expenses, Taxes</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'text'}>Rs 0.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-8'}>
                                        <p className={'text'}>Discount</p>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <p className={'text'}>Rs 0.0</p>
                                    </div>
                                </div>
                            </Collapse>

                            <IconButton
                                className={'collapseBtn'}
                                onClick={() => {
                                    this.setState({
                                        isCostCollapse: false,
                                        isPendingCollapse: !this.state.isPendingCollapse
                                    })
                                }}
                                aria-expanded={this.state.isPendingCollapse}
                                aria-label="show more"
                            >
                                {
                                    this.state.isPendingCollapse ? <ChevronLeftIcon className={'collpaseIcon'} /> : <ChevronRightIcon className={'collpaseIcon'} />
                                }
                            </IconButton>

                            <div className={'verticalLine'}></div>
                        </div>
                        <div className={'outstanding widthBox'}>
                            <div>
                                <p className={'outTitle'}>Total Paid</p>
                                <p className={'costText'}>Rs 396</p>
                                <p className={'text'}>Next payment:</p>
                                <p className={'text'}>Dec 1</p>
                            </div>
                            <div className={'verticalLine'}></div>
                        </div>
                        <div className={'budget'}>
                            <div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <p className={'costTitle'}>Budget</p>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <p className={'costTitle'}>Rs 100.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <p className={'text'}>Logged</p>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <p className={'bText'}>Rs 100.0</p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <p className={'text'}>Over Budget</p>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <p className={'bText'}>Rs 0.0</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Project Information card ends here */}

                    {/* Team Activity table starts here */}
                    <h5 className={'teamTitle'}>Team Members</h5>
                    <Table className={'activityTable'}
                        borderless
                        hover
                        responsive
                        striped
                    >
                        <thead>
                            <tr>
                                <th>Name|Role</th>
                                <th>Rate</th>
                                <th>Logged Past Week</th>
                                <th>Logged This Month</th>
                                <th>Logged Total</th>
                                <th>Paid Total</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'userBlock'}>
                                    <Avatar className={'user'}>X</Avatar>
                                    <p className={'userName'}>XYZ</p>
                                    <div className={'roleTab'}>Supervisor</div>
                                </td>
                                <td>Rs 100.00</td>
                                <td>10:34</td>
                                <td>30:45</td>
                                <td>30:45</td>
                                <td><RemoveIcon /></td>
                                <td><MoreHorizIcon className={'actionsIcon'} /></td>
                            </tr>
                            <tr>
                                <td className={'userBlock'}>
                                    <Avatar className={'user'}>A</Avatar>
                                    <p className={'userName'}>ABC</p>
                                    <div className={'roleTab'}>Worker</div>
                                </td>
                                <td>Rs 100.00</td>
                                <td>10:34</td>
                                <td>30:45</td>
                                <td>30:45</td>
                                <td><RemoveIcon /></td>
                                <td><MoreHorizIcon className={'actionsIcon'} /></td>
                            </tr>
                            <tr>
                                <td className={'userBlock'}>
                                    <Avatar className={'user'}>KL</Avatar>
                                    <p className={'userName'}>KLM</p>
                                    <div className={'roleTab'}>Worker</div>
                                </td>
                                <td>Rs 100.00</td>
                                <td>10:34</td>
                                <td>30:45</td>
                                <td>30:45</td>
                                <td><RemoveIcon /></td>
                                <td><MoreHorizIcon className={'actionsIcon'} /></td>
                            </tr>
                            <tr>
                                <td className={'userBlock'}>
                                    <Avatar className={'user'}>PQ</Avatar>
                                    <p className={'userName'}>PQR</p>
                                    <div className={'roleTab'}>Worker</div>
                                </td>
                                <td>Rs 100.00</td>
                                <td>10:34</td>
                                <td>30:45</td>
                                <td>30:45</td>
                                <td><RemoveIcon /></td>
                                <td><MoreHorizIcon className={'actionsIcon'} /></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className={'text'}>Tracking requirements: Screenshots every 30 minutes | Mouse clicks | Keyboard hits | Manual time entry allowed</p>
                    {/* Team Activity table ends here */}

                    {/* Invite Team Members Dialog starts here */}
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={this.state.isInviteDialogOpen}
                        onClose={this.inviteMemberDialogHandler}
                    >
                        <DialogTitle className={'inviteTitle'}>
                            <h5>Invite Team Members</h5>
                            <IconButton onClick={this.inviteMemberDialogHandler}><CloseIcon /></IconButton>
                        </DialogTitle>
                        <DialogContent className={'inviteBody'}>
                            <p>Enter each team member's email address and role. They will receive an email invitation to your TopTracker project</p>
                            <InviteMembersRow />
                            <InviteMembersRow />
                            <InviteMembersRow />
                            <Button variant="outline-primary" className={'addMoreFieldsBtn'}><AddIcon className={'addIcon'} /> Add another member</Button>
                            <div className={'inviteFooter text-right'}>
                                <Button variant="outline-primary" onClick={this.inviteMemberDialogHandler}>Cancel</Button>
                                <Button variant="success" onClick={this.inviteMemberDialogHandler}>Send Invitations</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                    {/* Invite Team Members Dialog ends here */}



                </div>
            </div>
        )
    }
}

