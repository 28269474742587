import React from 'react';
import Header from "../header/header";
import SideMenu from "../sidebar/sidebar";
import './adminDashboard.css';
import {BrowserRouter as Router, Switch, Route, HashRouter, Redirect} from "react-router-dom";
import EmployeeList from "../Personal/EmployeeList/EmployeeList";
import LeaveDetail from "../Personal/LeaveDetail/LeaveDetail";
import EmployeeSetting from "../Personal/EmployeeSetting/EmployeeSetting";
import AttendanceSheet from "../Personal/AttendanceSheet/AttendanceSheet";
import AdDashboard from "./AdDashboard/AdDashboard";
import Project from "../Projects/project";
import Overview from "../Overview/Overview";
import Personnel from "../Personnel/Personnel";
import Journal from "../Journal-Details/Admin/Journal/Journal Details";
import Tasks from '../TaskManagement/Tasks/Tasks';
import Setting from '../Setting/Setting'; 
// import Toptracker from "../Toptracker/Toptracker";
import TrackerProject from '../Toptracker/TrackerProject/TrackerProject';
import TrackerReports from '../Toptracker/TrackerReports/TrackerReports';
import AddProject from '../Toptracker/AddProject/AddProject';
import ProjectInformation from '../Toptracker/ProjectInformation/ProjectInformation';


export default class AdminDashboard extends React.Component {
    render() {
        return (
            <div className="adminbody">
                <HashRouter hashType={'noslash'}>
                    <Router basename={'OPM/#AdminDashboard'}>
                        <Header />
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3 sidebar">
                                    <SideMenu />
                                </div>
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                    <Switch>
                                        <Route exact path='/' render={
                                            () => (
                                                <Redirect to='/AdDashboard'/>
                                            )
                                        }/>
                                        <Route path='/AdDashboard' component={AdDashboard}/>
                                        <Route path='/EmployeeList' component={EmployeeList}/>
                                        <Route path='/LeaveDetail' component={LeaveDetail}/>
                                        <Route path='/EmployeeSetting' component={EmployeeSetting}/>
                                        <Route path='/AttendanceSheet' component={AttendanceSheet}/>
                                        <Route path='/Project' component={Project}/>
                                        <Route path='/Tasks' component={Tasks}/>
                                        <Route path='/Overview' component={Overview}/>
                                        <Route path='/Personnel' component={Personnel}/>
                                        <Route path='/journal' component={Journal}/>
                                        {/* <Route path='/Toptracker' component={Toptracker}/> */}
                                        <Route path='/TrackerProject' component={TrackerProject}/>
                                        <Route path='/TrackerReports' component={TrackerReports}/>
                                        <Route path='/AddProject' component={AddProject}/>
                                        <Route path='/ProjectInformation' component={ProjectInformation}/>

                                        <Route path='/Setting' component={Setting}/>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </Router>
                </HashRouter>
            </div>
        );
    }
}
