import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import './attendance_card.css';
export default class AttendanceCard extends React.Component{
    constructor(props) {
        super(props);
        console.log('props for time', this.props);
    }
    render() {
        return(
            <OverlayTrigger placement={'left'} overlay={
                <Tooltip>
                    <h6>Full Information</h6>
                    <AllTimeInout list={this.props.time}/>
                </Tooltip>
            }>
                <li className={ 
                    (this.props.Day !== 'Sunday' && this.props.attendance === 'Absent') ? 'listcard Absent' : 
                    this.props.Day === 'Sunday' ? 
                    'listcard sundayLeave' : 
                    ( this.props.index > 2 ? (this.props.time[this.props.time.length -1].timein !== '-- : -- : --' ? 'listcard Present' : 'listcard Absent' )
                    : this.props.index === 2 ? 'listcard Today' : 'listcard')
                    }>
                    <ul>
                        <li className="dateH one">{this.props.Day[0] + this.props.Day[1] + this.props.Day[2]}</li>
                        <li className="dateH two">{this.props.Date}</li>
                        <li>{ ((this.props.time[this.props.time.length -1].timein === '-- : -- : --') && this.props.Day === 'Sunday' && this.props.index > 2) ? 'Holiday' :(this.props.time[this.props.time.length -1].timein === '-- : -- : --' && this.props.index > 2)?  'Absent': (this.props.time[this.props.time.length -1].timein === '-- : -- : --')? '-- : -- : --' : (this.props.time[this.props.time.length -1].timein + ' IN') } </li>
                        <li>{(this.props.time[this.props.time.length -1].timein === '-- : -- : --' && this.props.index > 2)? '' : (this.props.time[this.props.time.length -1].timeout === '-- : -- : --')? '-- : -- : --' : (this.props.time[0].timeout + ' OUT')}</li>
                        <li>{this.props.totalTime}</li>
                    </ul>
                </li>
            </OverlayTrigger>
        )
    }
}
function AllTimeInout(props) {
    // console.log(props.list);
    // console.log(list);
    const all = props.list.map((param) =>
        <div>
            <p>{param.timein} IN  {param.timeout} OUT</p>
        </div>
    );
    return (
        all
    )
}
