import React, { Component } from 'react';
import './TrackerProject.css';
import { 
    Button,
    Table,
}from 'react-bootstrap';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Badge,
    Avatar,
    createMuiTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ThemeProvider } from "@material-ui/styles";
import GaugeChart from 'react-gauge-chart';


/**
 * Default color theme  
 */
const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
          main: '#218838'
      },
    },
  });


/**
 * Functional component to render Tabs content 
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`simple-tabpanel-${index}`}
            // aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}


export default class TrackerProject extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            tabValues: 0,
        }
    }

    /**
     * Custom Styling for gauge meter 
     */
    gaugeStyle = {
        height: 60,
        width: 100,
    }

    /**
     * function to handle the tab rendering
     */
    tabHandler = (e, value) => {
        this.setState({
            tabValues: value
        })
    }

    /**
     * function to handle the add Project button
     */
    addProjectHandler = () => {
        this.props.history.push('/AddProject');
    }

    /**
     * function to open the project information
     */
    openProject = () => {
        this.props.history.push('/ProjectInformation');
    }
    
    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'trackerProjectsAdmin'}>
                    <div className={'header'}>
                        <h5>Projects</h5>
                        <Button variant={'success'} onClick={() => this.addProjectHandler()}>New Project <AddIcon className={'addIcon'}/> </Button>
                    </div>

                    {/* Tab header starts here */}
                    <ThemeProvider theme={defaultMaterialTheme}>
                    <Tabs
                        value={this.state.tabValues}
                        onChange={this.tabHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullwidth"
                        className={'tabsHeader'}
                        // indicatorColor={'#66CC75'}
                    >
                        <Tab label="Active(3)" className={'tabs'} />
                        <Tab label="Archived" className={'tabs'} />
                    </Tabs>
                    </ThemeProvider>
                    {/* Tab header ends here */}

                    {/* Tab content starts here */}
                    <TabPanel value={this.state.tabValues} index={0} className={'tabsPanel'}>
                        <Table borderless hover>
                            <thead>
                                <tr>
                                    <th>
                                        <span>Name <ImportExportIcon /></span>
                                        <span>Creation Date <ImportExportIcon /></span>
                                    </th>
                                    <th>
                                        <span>Budget</span>
                                    </th>
                                    <th>
                                        <span>Team <ImportExportIcon /></span>
                                    </th>
                                    <th>
                                        <span>Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onClick={() => this.openProject()}>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>VD</Avatar>
                                        <div>
                                            <p className={'title'}>Vote Do</p>
                                            <p className={'text'}>Created on Jan 10 Admin</p>
                                        </div>
                                    </td>
                                    <td className={'budget text-center'}>
                                    <GaugeChart id="gauge-chart1" 
                                        nrOfLevels={20}
                                        colors={['#7b7e7c']}
                                        arcWidth={0.2} 
                                        percent={0.0}
                                        textColor={'#218838'} 
                                        needleColor={'#218838'}
                                        needleBaseColor={'#218838'}
                                        style={this.gaugeStyle}
                                        hideText={true}
                                        className={'gaugeChart'}
                                        />
                                        <p>0%</p>
                                    </td>
                                    <td className={'team'}><PersonIcon />5</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                                <tr onClick={() => this.openProject()}>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>TB</Avatar>
                                        <div>
                                            <p className={'title'}>TransferBoss</p>
                                            <p className={'text'}>Created on Jan 10 Admin</p>
                                        </div>
                                    </td>
                                    <td className={'budget text-center'}>
                                    <GaugeChart id="gauge-chart1" 
                                        nrOfLevels={20}
                                        colors={['#7b7e7c']}
                                        arcWidth={0.2} 
                                        percent={0.34}
                                        textColor={'#218838'} 
                                        needleColor={'#218838'}
                                        needleBaseColor={'#218838'}
                                        style={this.gaugeStyle}
                                        hideText={true}
                                        className={'gaugeChart'}
                                        />
                                        <p>34%</p>
                                    </td>
                                    <td className={'team'}><PersonIcon />5</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                                <tr onClick={() => this.openProject()}>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>O</Avatar>
                                        <div>
                                            <p className={'title'}>OPM</p>
                                            <p className={'text'}>Created on Jan 10 Admin</p>
                                        </div>
                                    </td>
                                    <td className={'budget text-center'}>
                                        <GaugeChart id="gauge-chart1" 
                                        nrOfLevels={20}
                                        colors={['#7b7e7c']}
                                        arcWidth={0.2} 
                                        percent={0.56}
                                        textColor={'#218838'} 
                                        needleColor={'#218838'}
                                        needleBaseColor={'#218838'}
                                        style={this.gaugeStyle}
                                        hideText={true}
                                        className={'gaugeChart'}
                                        />
                                        <p>56%</p>
                                    </td>
                                    <td className={'team'}><PersonIcon />3</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel value={this.state.tabValues} index={1} className={'tabsPanel'}>
                        <div className={'archivedProjects text-center'}>
                            <FileCopyIcon className={'iconsArchived'} />
                            <p>You don't have any archived projects yet</p>
                        </div>
                    </TabPanel>
                    {/* Tab content ends here */}
                </div>
            </div>
        )
    }
}


