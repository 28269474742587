import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDZ0sT9V6DVqIT3eSaKzVK14Tr3myDE98I",
  authDomain: "opm-chat.firebaseapp.com",
  databaseURL: "https://opm-chat.firebaseio.com",
  projectId: "opm-chat",
  storageBucket: "opm-chat.appspot.com",
  messagingSenderId: "120024496475",
  appId: "1:120024496475:web:b78f9a6ff362562efa827e",
  measurementId: "G-MNV033GG5C",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();