import React, { Component } from 'react';
import './AddProject.css';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import {
    Stepper,
    Step,
    StepLabel,
    Radio,
    createMuiTheme,
    RadioGroup,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Switch,
} from '@material-ui/core';
import { ThemeProvider } from "@material-ui/styles";

/**
 * Default color theme  
 */
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#218838'
        },
    },
});

/**
 * functional components to render invite team members row
 */
function InviteMembersRow() {
    return (
        <div className={'inviteMembersRow'}>
            <span>1.</span>
            <input className={'inputFields'}
                type="email"
                placeholder="Email" />
            <ThemeProvider theme={defaultMaterialTheme}>
                <FormControl className={'inputSelect'} >
                    <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                    //   value={age}
                    //   onChange={handleChange}
                    >
                        <MenuItem value='Sv'>Supervisor</MenuItem>
                        <p>Can see all team activity</p>
                        <MenuItem value='Wk'>Worker</MenuItem>
                        <p>Can see their own activity</p>
                    </Select>
                </FormControl>
            </ThemeProvider>
        </div>
    )
}

export default class AddProject extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeStep: 0
        }
    }
    steps = this.getSteps();

    /**
     * function to handle the finish button of add Project
     */
    addProjectHandler = () => {
        this.props.history.push('/TrackerProject');
    }

    /**
     * function to get the Stepper steps
     */
    getSteps() {
        return ['General', 'Invite Team', 'Set Tracking Requirements'];
    }

    /**
     * function to get the Stepper content
     */
    getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <React.Fragment>
                        <div className={'row'}>
                            <div className={'col-md-3 text-left'}>
                                <p className={'label'}>Project Name</p>
                            </div>
                            <div className={'col-md-9'}>
                                <input className={'input'}
                                    type="text" />
                            </div>
                        </div>
                        <hr className={'horizontalLine'} />
                        <div className={'row'}>
                            <div className={'col-md-3 text-left'}>
                                <p className={'label'}>Project Currency</p>
                            </div>
                            <div className={'col-md-9'}>
                                <select className={'selectField'}>
                                    <option value='usd'>USD</option>
                                    <option value='euro'>Euro</option>
                                    <option value='rs'>Indian Rupees Rs.</option>
                                    <option value='dinar'>Dinar</option>
                                </select>
                            </div>
                        </div>
                        <hr className={'horizontalLine'} />
                        <div className={'row'}>
                            <div className={'col-md-3 text-left'}>
                                <p className={'label'}>Invoicing Schedule</p>
                            </div>
                            <div className={'col-md-9'}>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <RadioGroup name="invoices">
                                        <FormControlLabel value="GA" control={<Radio color='primary' />} label="Generate Automatically" />
                                        <p className={'labelText'}>Invoices will be generated only for tracked time. No invoices will be</p>
                                        <p className={'labelText'}>generated when freelancer has not tracked time.</p>
                                        <FormControlLabel value="GF" control={<Radio color='primary' />} label="Generate When Freelancer Invoices Me" />
                                        <p className={'labelText'}>Freelancer will invoice you directly when the work is complete.</p>
                                    </RadioGroup>
                                </ThemeProvider>
                            </div>
                        </div>
                        <hr className={'horizontalLine'} />
                        <div className={'row'}>
                            <div className={'col-md-3 text-left'}>
                                <p className={'label'}>Budget Notifications</p>
                                <p className={'labelText'}>Notify me when budget</p> <p className={'labelText'}>threshold will be reached</p>
                            </div>
                            <div className={'col-md-9'}>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <RadioGroup name="budgetNotifications">
                                        <FormControlLabel value="NSB" control={<Radio color='primary' />} label="Do Not Set Budget Threshold" />
                                        <FormControlLabel value="SB" control={<Radio color='primary' />} label="Set Budget Threshold" />
                                    </RadioGroup>
                                </ThemeProvider>
                            </div>
                        </div>
                        <hr className={'horizontalLine'} />
                        <p className={'label'}>As activity in TopTracker is tracked down to the second,
                        you will be billed for every full second tracked by your</p>  <p className={'label'}>project team members.</p>
                        <div className={'footerActivity text-right'}>
                            <Button variant="outline-primary" className={'backBtn'} onClick={this.addProjectHandler}>Cancel</Button>
                            <Button variant="success" className={'nextBtn'} onClick={this.handleNext}>Go to Next</Button>
                        </div>
                    </React.Fragment>
                )
            case 1:
                return (
                    <React.Fragment>
                        <h6>Invite Team Members</h6>
                        <p>Note: once your invitations are sent, the rate assigned to a freelancer cannot be changed.</p>
                        <InviteMembersRow />
                        <InviteMembersRow />
                        <InviteMembersRow />
                        <Button variant="outline-primary" className={'addMoreFieldsBtn'}><AddIcon className={'addIcon'} /> Add another member</Button>
                        <div className={'footerActivity text-right'}>
                            <Button variant="primary" className={'backBtn'} onClick={this.handleBack}>Back to Project Settings</Button>
                            <Button variant="success" className={'nextBtn'} onClick={this.handleNext}>Send Invitations</Button>
                        </div>
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <h6>Set Tracking Requirements</h6>
                        <p>Control freelancer's tracking abilities by setting up permissions for both web and desktop trackers.</p>
                        <Table className={'trackingTable'}>
                            <thead>
                                <tr>
                                    <th>REQUIREMENTS</th>
                                    <th>WEB TRACKER</th>
                                    <th>DESKTOP TRACKER</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Allow Tracking</th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch disabled checked inputProps={{ 'aria-label': 'primary checkbox' }} />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Take Screenshots</th>
                                    <th><p>Not Supported</p></th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Count Mouse Clicks</th>
                                    <th><p>Not Supported</p></th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Count Mouse Clicks</th>
                                    <th><p>Not Supported</p></th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Count Keyboard Hits</th>
                                    <th><p>Not Supported</p></th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Manual Time Entry</th>
                                    <th>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                    <th>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <Switch
                                                // checked={state.checkedA}
                                                // onChange={handleChange}
                                                name="checkedA"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </ThemeProvider>
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <p>Note: These requirements don’t guarantee that functional execution of respective options carry out on desktop clients as some</p>
                        <p>circumstances may prevent from it on user machines.</p>
                        <div className={'footerActivity text-right'}>
                            <Button variant="primary" className={'backBtn'} onClick={this.handleBack}>Go Back to Team Settings</Button>
                            <Button variant="success" className={'nextBtn'} onClick={this.addProjectHandler}>Finish</Button>
                        </div>
                    </React.Fragment>
                )
            default:
                return 'Unknown step';
        }
    }

    /**
     * function to handle the next rendering of Next Button
     */
    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    };

    /**
     * function to handle the back rendering of Back Button
     */
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    /**
     * function to handle the reset rendering of Reset Button
     */
    handleReset = () => {
        // setActiveStep(0);
        this.setState({
            activeStep: 0
        })
    };


    render() {
        return (
            <div className={'addProject'}>
                <h4 className={'heading'}>Start New Project</h4>

                {/* Stepper Header starts here */}
                <ThemeProvider theme={defaultMaterialTheme}>
                    <Stepper activeStep={this.state.activeStep} className={'stepper'}>
                        {this.steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </ThemeProvider>
                {/* Stepper Header ends here */}

                {/* Stepper Body starts here */}
                <div className={'content'}>
                    {this.getStepContent(this.state.activeStep)}
                </div>
                {/* Stepper Body ends here */}

            </div>
        )
    }
}

