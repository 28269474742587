import React from 'react';
import './leave-request.css';
import "react-datepicker/dist/react-datepicker.css";
import Api from "../../Providers/Api";
import { OverlayTrigger, Tooltip, Modal, Table, Form, Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import {
    Radio,
    InputLabel,
    MenuItem,
    Input,
    Select,
    Chip,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid } from "@material-ui/core";
import { css } from "@emotion/react";
import DatePicker from 'react-datepicker';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import LeavePolicy from './LeavePolicies.pdf';
// import { PDFDownloadLink, Document, Page, View, Text } from '@react-pdf/renderer';

let selectedDate = new Date();
let startDate = '';
let endDate = '';
let startFullDate = '';
let endFullDate = '';
let array = [];
let selectedEmployeeEmail = [];
let leaveDe = [];
let informEmployees = '';

const api = new Api();
const loaderCSS = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 80%;
    z-index: 5;
`;
var leaveJSON = [];
const customStyles2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#dad9e9',
        width: '800px',
    }
};

export default class LeaveRequest extends React.Component {
    userDetail = {};
    // applyingFor = 'E';
    toaster = {};
    date = {};
    constructor(props) {
        super(props);
        this.state = {
            showDatePicker: false,
            leave: {
                reason: ''
            },
            toasterStatus: false,
            toasterMessage: '',
            toasterHeader: '',
            time: 0,
            showT: false,
            loadingL: false,
            applyingFor: 'E',
            isDayCriterionModalOpen: false,
            isLeaveRequestModalOpen: false,
            leaveDaysArray: [],
            leaveStatusArray: [],
            startDate: '',
            endDate: '',
            leaveStatus: '',
            fullDay: 0,
            halfDay: 0,
            employeeDetails: [],
            informTo: [],
        };
        this.userDetail = JSON.parse(localStorage.getItem('userdetail'));
        this.ShowEmployeeLeave();
    }

    /**
     * function to request the leave
     */
    LeaveRequest() {
        this.setState({
            loadingL: true,
        });
        setInterval(this.ShowTime.bind(this), 1000);

        // if(this.state.informTo.length === 0){
        //     console.log('inform to is empty');
        //    this.setState({
        //        informTo: null,
        //    });
        // }
        // console.log("Debugged response", startDate, endDate, this.state.applyingFor, selectedEmployeeEmail.join(), this.state.informTo, this.state.leave.reason, leaveDe);
        this.userDetail = JSON.parse(localStorage.getItem('userdetail'));
        const param = {
            api: '/AddEmployeeleave',
            data: {
                empid: this.userDetail.empid,
                leavefrom: startDate,
                leaveto: endDate,
                leavetype: this.state.applyingFor,
                informto: this.state.informTo.length === 0 ? null : selectedEmployeeEmail.join(),
                reason: this.state.leave.reason,
                leavede: leaveDe
            }
        };
        console.log('sent param', param);
        api.postApi(param).then(res => {
            this.ShowEmployeeLeave();
            console.log('res', res);
            console.log('message', res.data.message);
            this.userDetail.empid = '';
            this.setState({
                leave: {
                    reason: '',
                },
                loadingL: false,
            });
            if (res.data.status === 200) {

                ToastsStore.success("Leave Requested Successfully");
                this.setState({
                    showT: true,
                    applyingFor: 'E'
                });


            } else if (res.data.message === 'Please Select Another Leave Type') {

                this.setState({
                    showT: true,
                    applyingFor: 'E'
                });

                ToastsStore.warning(res.data.message);
                return;
            } else {
                window.alert(JSON.stringify(res.data));

                this.setState({
                    showT: false,
                })
            }
        }).catch(err => {
            console.error(err);
            this.setState({
                loadingL: false,
            })
        })
    }

    /**
     * function to get the list of the employees
     */
    async getEmployeeList() {
        // this.setState({
        //     loadingL: true
        // });
        const param = "//ShowEmployeeNameList";
        await api
            .getApi(param)
            .then((res) => {
                this.setState({
                    employeeDetails: res.data.response
                })
                // console.log(this.state.employeeDetails);  
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * function to show the leaves of the employees
     * */
    ShowEmployeeLeave() {
        this.setState({
            loadingL: true,
        });
        // console.log(this.userDetail.empid);
        const param = '/ShowEmpLeaveRequest?empid=' + this.userDetail.empid;
        api.getApi(param).then(res => {
            // console.log('response from leave detail api:', res);
            leaveJSON = res.data.response;
            if (leaveJSON === 'No Record Found') {
                leaveJSON = []
            } else {
                for (let i = 0; i < leaveJSON.length; i++) {
                    leaveJSON[i].index = i + 1;
                }
            }
            // console.log('leaveJSON', leaveJSON);
            this.setState({
                loadingL: false,
            });
        }).catch(err => {
            // console.log('error from leave detail api:', err);
            this.setState({
                loadingL: false,
            });
        })
    }

    /**
     * function to handle the change in reason
     */
    handleReason(ev) {
        this.setState({
            leave: {
                reason: ev.target.value,
                leavetype: this.state.leavetype
            }
        })
    }



    /**
     * function for Sick leave type
     */
    // SickLeaveType(ev) {
    //     this.applyingFor = 'E';
    // }
    /**
     * function for Casual leave type
     */
    // CasualLeaveType(ev) {
    //     console.log('ok')
    //     this.applyingFor = 'C'
    // }

    /**
     * function to show the timer onto the toaster
     */
    ShowTime() {
        this.setState({
            time: this.state.time + 1
        })
    }
    /**
     * function to close the toaster
     */
    close() {
        this.setState({
            showT: false
        })
    }

    /**
     *Returns an array of dates between the two dates
    */
    getDates = function (startDate, endDate) {
        // console.log(startDate, endDate);
        let dates = [];
        let currentDate = startDate,
            addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {
            let leaveDate = currentDate;
            let cyear, cdate, cmonth;
            let formatedDate;
            cyear = leaveDate.getFullYear();
            cmonth = leaveDate.getMonth() + 1;
            cdate = leaveDate.getDate();
            if (cmonth < 10) {
                cmonth = '0' + cmonth;
            }
            if (cdate < 10) {
                cdate = '0' + cdate;
            }
            formatedDate = cyear + '-' + cmonth + '-' + cdate;
            dates.push(formatedDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    };

    /**
     * function to calculate days between two dates and push it in state
     */
    calculateDays = () => {
        let dates = this.getDates(
            new Date(startFullDate.getFullYear(), startFullDate.getMonth(), startFullDate.getDate()),
            new Date(endFullDate.getFullYear(), endFullDate.getMonth(), endFullDate.getDate())
        );
        if (this.state.leaveDaysArray.length > 0) {
            this.state.leaveDaysArray.splice(0, this.state.leaveDaysArray.length)
        }
        for (let i = 0; i < dates.length; i++) {
            this.state.leaveDaysArray.push({
                date: dates[i],
                day: `day${i + 1}`
            });
        }
        this.state.startDate = this.state.leaveDaysArray[0];
        this.state.endDate = this.state.leaveDaysArray[dates.length - 1];
        console.log(this.state.startDate, this.state.endDate);
        // console.log(startFullDate.getDate() + '-' + startFullDate.getMonth() + '-' + startFullDate.getFullYear());
        // console.log(this.state.leaveDaysArray, this.state.leaveDaysArray.length);
    }

    /**
     * function to handle the leave status
     */

    leaveStatusHandler = (e, date) => {
        let cDate = date;
        let index, index2;
        let count = 0;
        let flag = 0;
        let fullDay = 0, halfDay = 0;
        let fullDayLeave = false;
        let firstHalfLeave = false;
        let secondHalfLeave = false;

        // console.log(cDate.getFullYear(), 'full year');
        this.setState({ leaveStatus: e.target.value }, () => {
            if (this.state.leaveStatus === 'fullDay') {
                fullDayLeave = true;
            }
            else if (this.state.leaveStatus === 'firstHalf') {
                firstHalfLeave = true;
            }
            else if (this.state.leaveStatus === 'secondHalf') {
                secondHalfLeave = true;
            }
            // console.log(this.state.leaveStatus);
            if (!leaveDe.length) {
                // console.log('array push when array is empty');
                leaveDe.push({
                    leaveDate: cDate,
                    fullDay: fullDayLeave,
                    firstHalfDay: firstHalfLeave,
                    secondHalfday: secondHalfLeave
                });
            }
            else {
                for (let k = 0; k < leaveDe.length; k++) {
                    if (leaveDe[k].leaveDate === cDate) {
                        index2 = k;
                        flag++;
                        break;
                    }
                    else {
                        flag = 0;
                    }
                }
                if (flag > 0) {
                    // console.log('array splice');
                    leaveDe.splice(index2, 1, {
                        leaveDate: cDate,
                        fullDay: fullDayLeave,
                        firstHalfDay: firstHalfLeave,
                        secondHalfday: secondHalfLeave
                    })
                }
                else {
                    // console.log('array push if array already has element');
                    leaveDe.push({
                        leaveDate: cDate,
                        fullDay: fullDayLeave,
                        firstHalfDay: firstHalfLeave,
                        secondHalfday: secondHalfLeave
                    });
                }
            }

            console.log(leaveDe);


            if (!array.length) {
                array.push({
                    date: date,
                    leaveStatus: this.state.leaveStatus
                });
            }
            else {
                for (let i = 0; i < array.length; i++) {
                    // console.log(array[i].date);
                    if (array[i].date === cDate) {
                        index = i;
                        count++;
                        // console.log(index);
                        break;
                    }
                    else {
                        count = 0;
                    }
                }
                if (count > 0) {
                    // console.log('array splice');
                    array.splice(index, 1, {
                        date: date,
                        leaveStatus: this.state.leaveStatus
                    })
                }
                else {
                    // console.log('array push if array already has element');
                    array.push({
                        date: date,
                        leaveStatus: this.state.leaveStatus
                    });
                }
            }

            for (let j = 0; j < array.length; j++) {
                // console.log('this loop is to calculate the no of leave days');
                if (array[j].leaveStatus === 'fullDay') {
                    fullDay++;
                }
                else if (array[j].leaveStatus === 'firstHalf' || array[j].leaveStatus === 'secondHalf') {
                    halfDay++;
                }
            }
            this.setState({
                fullDay: fullDay,
                halfDay: halfDay
            });
        });
        // console.log(date);
    }

    /**
     * function to handle the inform to dropdown
     */
    informToHandler(newValue) {
        // console.log(newValue);
        this.setState({
            informTo: newValue
        }, () => {
            // console.log(this.state.informTo);
            selectedEmployeeEmail.splice(0, selectedEmployeeEmail.length);
            for (let i = 0; i < this.state.informTo.length; i++) {
                selectedEmployeeEmail.push(this.state.informTo[i].email);
            }

            // console.log(selectedEmployeeEmail.join());
        })
    }


    // modal function
    /**
    * function to open the leave modal
    */
    openDayCriterionModel = () => {
        this.setState({
            isDayCriterionModalOpen: true,
            fullDay: 0,
            halfDay: 0,
            informingName: [],
        });
        array.splice(0, array.length);

    }//ends here

    /**
     * function to open the leave request confirm modal
     */
    openLeaveConfirmModal = () => {
        this.setState({
            isLeaveRequestModalOpen: true
        });
        this.getEmployeeList();
    }//ends here


    OpenDayCountmodal(data) {
        this.setState({
            leaveRequestModal: true
        })
    }

    OpenLeaveStatusmodal(data) {
        this.setState({
            leaveStatusModal: true
        })
    }

    /**
     * function to reset the leave form
     */
    resetForm = () => {
        console.log('resetform');
        this.setState({
            applyingFor: 'E',
            leave: {
                reason: ''
            },
            startDate: '',
            endDate: '',
            halfDay: 0,
            fullDay: 0
        });
        startFullDate = '';
        endFullDate = '';
    }



    render() {
        return (
            <div className="leaveWrapwer">
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
                <div className="card col-12">
                    {/* <a className={'text-right mt-2 cursor-pointer policy'} href="../LeavePolicies.pdf" download>
                        Leave Policy
                    </a> */}
                    <form action="">
                        <div className="card-body">
                            <a href='../LeavePolicies.pdf' download className="downloadLink text-center" target="_blank">
                                Download Leave Policy
                                </a>
                            <div className="row leaveForm">
                                <div className="col-md-4"><label>Applying for</label></div>
                                <div className="col-md-8">
                                    <select value={this.state.applyingFor} onChange={(ev) => (
                                        this.setState({
                                            applyingFor: ev.target.value
                                        })
                                    )}    >
                                        <option value="E" >Earned Leave</option>
                                        <option value="C" >Casual Leave</option>

                                        {/* <option value="Earned Leave" onChange={this.SickLeaveType.bind(this)}>Earned Leave</option>
                                        <option value="Casual Leave" onChange={this.CasualLeaveType.bind(this)}>Casual Leave</option> */}

                                    </select>
                                    {/* <button type="button" className={this.applyingFor === 'E' ? 'btn btn-outline-secondary btn-sm activeLeave' : " btn btn-outline-secondary btn-sm"} onClick={this.SickLeaveType.bind(this)}>Earned Leave</button>
                                    <button type="button" className={this.applyingFor === 'C' ? 'btn btn-outline-secondary btn-sm activeLeave' : " btn btn-outline-secondary btn-sm"} onClick={this.CasualLeaveType.bind(this)}>Casual Leave</button> */}
                                </div>
                            </div>
                            <div className=" row datepickerL">
                                <div className="col-md-4"><label>Select Date <span><i className="fa fa-calendar" aria-hidden="true"></i></span></label></div>
                                <div className="col-md-8 dates">
                                    <DatePicker
                                        placeholderText={'yyyy-mm-dd'}
                                        dateFormat="yyyy-MM-dd"
                                        selected={startFullDate}
                                        onChange={(ev) => {
                                            // console.log(ev);
                                            if ((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0)) <= ev) {
                                                startFullDate = ev;
                                                if (ev.getMonth() + 1 < 10) {
                                                    if (ev.getDate() < 10) {
                                                        startDate = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                    } else {
                                                        startDate = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                    }
                                                } else {
                                                    if (ev.getDate() < 10) {
                                                        startDate = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                    } else {
                                                        startDate = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                    }
                                                }
                                            } else {
                                                window.alert('Please select a valid date');
                                            }
                                        }}
                                    />
                                    <span>To</span>
                                    <DatePicker
                                        placeholderText={'yyyy-mm-dd'}
                                        dateFormat="yyyy-MM-dd"
                                        selected={endFullDate}
                                        onChange={(ev) => {
                                            endFullDate = ev;
                                            if (startFullDate > endFullDate) {
                                                window.alert('select a valid date');
                                                endFullDate = '';
                                            } else {
                                                if (ev.getMonth() + 1 < 10) {
                                                    endFullDate = ev;
                                                    if (ev.getDate() < 10) {
                                                        endDate = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                    } else {
                                                        endDate = ev.getFullYear() + '-0' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                    }
                                                } else {
                                                    if (ev.getDate() < 10) {
                                                        endDate = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-0' + ev.getDate();
                                                    } else {
                                                        endDate = ev.getFullYear() + '-' + (ev.getMonth() + 1) + '-' + ev.getDate();
                                                    }
                                                }
                                                this.openDayCriterionModel();
                                                this.calculateDays();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row leaves">
                                <div className="col-md-4"><label>Days</label></div>
                                <div className="col-md-8"><label>{this.state.fullDay} Days {this.state.halfDay} Half Days</label></div>
                            </div>
                            {/* <div className="row DaysTop">
                                <div className="col-md-4"><label onClick={() => (this.OpenDayCountmodal(true))}>Days</label></div>
                                <div className="col-md-8">
                                    <label><span className="daycount pl-0">0</span>Days<span className="daycount">0</span>Half Days</label>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-4"><label>Reason</label></div>
                                <div className="col-md-8 text-right">
                                    <input type="text" value={this.state.leave.reason} onChange={this.handleReason.bind(this)} />
                                </div>
                                <div className="col-md-12 btnGroup text-right">
                                    <button type="reset" className="btn btn-outline-secondary btn-sm btn-reset" onClick={this.resetForm}>Reset</button>
                                    <button type="button" className="btn btn-outline-secondary btn-sm btn-submit" onClick={this.openLeaveConfirmModal} disabled={!(this.state.leave.reason && startFullDate && endFullDate)}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>

                <Accordion className="history-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h6' className='accordion-heading'>History</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="history-accordion-details">
                        {
                            leaveJSON.map((data) => (
                                <OverlayTrigger placement={'bottom'}
                                    overlay={
                                        <Tooltip id={'tooltip'}>
                                            <p className={'leavetooltipRemark'}>
                                                {data.remark}
                                            </p>
                                        </Tooltip>}
                                >
                                    <p className={data.isapprove === "Pending" ? 'Pending' : (data.isapprove === "Approved" ? 'approved' : 'rejected')}>
                                        <span>{data.leavefrom}</span>
                                        {data.reason}
                                    </p>
                                </OverlayTrigger>
                            ))
                        }
                        {
                            !leaveJSON.length &&
                            <p>No leaves Yet</p>
                        }
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant='h6' className='accordion-heading'>Total Leaves</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="totalLeaves">
                        <div>
                            <p>Earned Leaves</p>
                            <h6>2 Days</h6>
                        </div>
                        <div>
                            <p>Casual Leaves</p>
                            <h6>6.5 Days</h6>
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* <h5 className="mt-4">History</h5>
                <div className="card col-12 mt-3">
                    <div className="card-body">
                        <div className="col-12 history">
                            {
                                leaveJSON.map((data) => (
                                    <OverlayTrigger placement={'bottom'} 
                                    overlay={
                                        <Tooltip id={'tooltip'}>
                                            <p className={'leavetooltipRemark'}>
                                                {data.remark}
                                            </p>
                                        </Tooltip>}
                                    >
                                        <p className={data.isapprove === "Pending" ? 'Pending' : (data.isapprove === "Approved" ? 'approved' : 'rejected')}>
                                            <span>{data.leavefrom}</span>
                                            {data.reason}
                                        </p>
                                    </OverlayTrigger>
                                ))
                            }
                            {
                                !leaveJSON.length &&
                                <p>No leaves Yet</p>
                            }
                        </div>
                    </div>
                </div>

                <div className="card col-12 totalLeaves" >
                    <div>
                        <p>Earned Leaves</p>
                        <h6>2 Days</h6>
                    </div>
                    <div>
                        <p>Casual Leaves</p>
                        <h6>6.5 Days</h6>
                    </div>
                </div> */}


                <div>
                    <DotLoader sizeUnit={"px"}
                        css={loaderCSS}
                        size={150}
                        color={'#123abc'}
                        loading={this.state.loadingL}
                    />
                </div>



                {/* Modal for leave days criterion */}
                <Modal
                    show={this.state.isDayCriterionModalOpen}
                    onHide={() => this.setState({ isDayCriterionModalOpen: false })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static">
                    <section className='dayCriterionModal'>
                        <h5>Confirm details of dates</h5>
                        <Grid container>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>Dates</Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>Full Day</Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>First Half</Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>Second Half</Grid>
                        </Grid>
                        {
                            this.state.leaveDaysArray.map((leave, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className="date">
                                        {leave.date}
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                        <FormControl component="fieldset">
                                            {/* <FormLabel component="legend">Gender</FormLabel> */}
                                            <RadioGroup aria-label="leave" name={leave.day} className="radioBtns" onChange={(e) => this.leaveStatusHandler(e, leave.date)}>
                                                <FormControlLabel value="fullDay" control={<Radio />} className="fullD" />
                                                <FormControlLabel value="firstHalf" control={<Radio />} className="firstH" />
                                                <FormControlLabel value="secondHalf" control={<Radio />} className="secondH" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        <div className="leaveModalFooter">
                            <p>{this.state.fullDay} Days {this.state.halfDay} Half Days</p>
                            <button className="cancelBtn" onClick={() => (
                                this.setState({
                                    isDayCriterionModalOpen: false,
                                    leaveStatus: 'fullDay',
                                    fullDay: 0,
                                    halfDay: 0
                                })
                            )}> Cancel </button>
                            <button className="submitBtn"
                                disabled={this.state.fullDay + this.state.halfDay !== this.state.leaveDaysArray.length}
                                onClick={() => (
                                    this.setState({
                                        isDayCriterionModalOpen: false
                                    })
                                )}> Confirm </button>
                        </div>
                        {/* <div className="float-right">
                            <p><span>1</span> full day and <span>2</span> half days</p>
                            <button onClick={() => this.setState({ isDayCriterionModalOpen: false })}>Close</button>
                        </div> */}
                    </section>
                </Modal>

                {/* Modal for leave day confirmation */}
                <Modal
                    show={this.state.isLeaveRequestModalOpen}
                    onHide={() => this.setState({ isLeaveRequestModalOpen: false })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static">
                    <section className="confirmModal">
                        <h6>Please confirm the details mentioned here</h6>
                        <div className="head">
                            {this.state.applyingFor === 'E' ? <p className="text">Earned Leave</p> : <p className="text">Casual Leave</p>}
                            <p className="text date">
                                {`${startDate} to ${endDate}`}
                            </p>
                        </div>
                        <p className="text">{this.state.fullDay} Days {this.state.halfDay} Half Days</p>
                        <p className="text">{this.state.leave.reason}</p>

                        <Autocomplete
                            multiple
                            value={this.state.informTo}
                            onChange={(e, newValue) => this.informToHandler(newValue)}
                            id="tags-standard"
                            options={this.state.employeeDetails}
                            getOptionLabel={(option) => option.name}
                            // defaultValue={[top100Films[13]]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Also Inform"
                                    placeholder="Also Inform"
                                />
                            )}
                        />
                        <div className="btns">
                            <button className="cancelBtn" onClick={() => (
                                this.setState({
                                    isLeaveRequestModalOpen: false,
                                    informTo: []
                                })
                            )}> Cancel </button>
                            <button className="submitBtn"
                                onClick={() => {
                                    this.LeaveRequest();
                                    this.resetForm();
                                    this.setState({
                                        isLeaveRequestModalOpen: false,
                                        informTo: []
                                    });
                                }} > Confirm </button>
                        </div>
                    </section>
                </Modal>

                {/* <Modal
                    show={this.state.isDayCriterionModalOpen}
                    onHide={() => this.setState({ isDayCriterionModalOpen: false })}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <section className={'dayCountmodal'}>
                        <h5>Confirm details</h5>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Dates</th>
                                    <th className="text-center">Full Day</th>
                                    <th className="text-center">First Half</th>
                                    <th className="text-center">Second Half</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01 Jan 2020</td>
                                    <td className="text-center"> <input type="radio" id="featured-1" name="featured" /><label for="featured-1"></label>
                                    </td>
                                    <td className="text-center"><input type="radio" id="featured-2" name="featured" /><label for="featured-2"></label></td>
                                    <td className="text-center"><input type="radio" id="featured-3" name="featured" /><label for="featured-3"></label></td>
                                </tr>
                                <tr>
                                    <td>01 Jan 2020</td>
                                    <td className="text-center"> <input type="radio" id="featured-4" name="featured" /><label for="featured-4"></label>
                                    </td>
                                    <td className="text-center"><input type="radio" id="featured-5" name="featured" /><label for="featured-5"></label></td>
                                    <td className="text-center"><input type="radio" id="featured-6" name="featured" /><label for="featured-6"></label></td>
                                </tr>
                                <tr>
                                    <td>01 Jan 2020</td>
                                    <td className="text-center"> <input type="radio" id="featured-7" name="featured" /><label for="featured-7"></label>
                                    </td>
                                    <td className="text-center"><input type="radio" id="featured-8" name="featured" /><label for="featured-8"></label></td>
                                    <td className="text-center"><input type="radio" id="featured-9" name="featured" /><label for="featured-9"></label></td>
                                </tr>
                                <tr>
                                    <td>01 Jan 2020</td>
                                    <td className="text-center"> <input type="radio" id="featured-10" name="featured" /><label for="featured-10"></label>
                                    </td>
                                    <td className="text-center"><input type="radio" id="featured-11" name="featured" /><label for="featured-11"></label></td>
                                    <td className="text-center"><input type="radio" id="featured-12" name="featured" /><label for="featured-12"></label></td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="float-right mb-1 text-right" role="group" aria-label="Basic example">
                            <p><span>1</span> full day and <span>2</span> half days</p>
                            <button className={'approve_reject_button btn btn-reject btnDay'} onClick={() => (
                                this.setState({
                                    leaveRequestModal: false
                                })
                            )}>Cancel </button>
                            <button className={'approve_reject_button btn btn-approve btnDay'}
                                onClick={() => (
                                    this.OpenLeaveStatusmodal(true),
                                    this.setState({
                                        leaveRequestModal: false
                                    })
                                )}  >Confirm </button>
                        </div>
                    </section>
                </Modal> */}
                {/* <div className={'leavestatusmodal'}>
                    <Modal
                        show={this.state.leaveStatusModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <section className={'dayCountmodal'}>
                            <h5>Please confirm the details mentioned here</h5>
                            <div className={'statusModalBody'}>
                                <div className={'row'}>
                                    <div className={'col-md-4'}><h5>Casual Leave</h5></div>
                                    <div className={'col-md-8'}>
                                        <h5><span>10 Mar 2020</span> to <span>10 Mar 2020</span></h5>
                                    </div>
                                    <div className={'col-md-12'}>
                                        <h5><span>1</span> Day <span>0</span> half days</h5>
                                    </div>
                                    <div className={'col-md-12'}>
                                        <h5>Reason here</h5>
                                    </div>
                                    <div className={'col-md-12'}>
                                        <Form >
                                            <Form.Control
                                                placeholder="Also inform:"
                                                type="text" />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <div className="float-right mb-1 text-right" role="group" aria-label="Basic example">
                                <button className={'approve_reject_button btn btn-reject btnDay'} onClick={() => (
                                    this.setState({
                                        leaveStatusModal: false
                                    })
                                )}>Back</button>
                                <button className={'approve_reject_button btn btn-approve btnDay'}
                                 onClick={() => (this.OpenDayCountmodal(true))}>Submit </button>
                            </div>
                        </section>
                    </Modal>
                </div> */}

            </div>

        )
    }
}
