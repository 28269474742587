import axios from 'axios';

export default class Api {
    token;
    configdetail;
    mode;
    constructor() {
        //this.mode = 'development';  /***  comment this line for the production */
        this.mode = 'production'; /***  uncomment this line for the production */
    }
    ProjectLogin() {
        const param = {
            Username: 'kritin.in',
            Password: 'kdsplnpp@4321'
        };
        axios.post('https://kritin.in:8181/api/login', param).then(res => {
            // console.log('response from project login api:', res);
            const temp = {
                headers: {
                    Authorization: 'Bearer ' + res.data.token
                }
            };
            localStorage.setItem('token', res.data.token);
            this.GetConfigDetail(temp);
        }).catch(err => {
            // console.error('error from project login api:', err);
            window.alert(JSON.stringify(err));
        })
    }

    GetConfigDetail(param) {
        const temp = {
            projectname: 'onion-scrum'
        };
        axios.post(' https://kritin.in:8181/api/projectinfo', temp, param).then(resp => {
            console.log('config detail', resp);
            this.SetConfigDetail(resp.data.response);
            localStorage.setItem('config_detail', JSON.stringify(resp.data.response));
        }).catch(error => {
            // console.error('error from config detail', error);
            window.alert(JSON.stringify(error));
        })
    }

    /**
     * function to set the config detail
     * @param param
     * @constructor
     */
    SetConfigDetail(param) {
        for (let i = 0; i < param.length; i++) {
            if (this.mode === param[i].servermode) {
                this.configdetail = param[i];
            }
        }
        // console.log('config detail : ', this.configdetail);
    }

    /**
     * function for the getapi
     * @param param: api name to be send to the api
     */
    getApi(param) {
        let temp = (localStorage.getItem('config_detail'));
        this.SetConfigDetail(JSON.parse(temp));
        return new Promise((resolve, reject) => {
            axios.get(this.configdetail.endpointurl + param, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }

    /**
     * function for the post api
     * @param param: api name and data to be send to the api
     */
    postApi(param) {
        let temp = (localStorage.getItem('config_detail'));
        this.SetConfigDetail(JSON.parse(temp));
        const Auth = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        };
        return new Promise((resolve, reject) => {
            axios.post(this.configdetail.endpointurl + param.api, param.data, Auth).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }

    /**
     * api for user login
     */
    UserLogin(param) {
        let temp = (localStorage.getItem('config_detail'));
        this.SetConfigDetail(JSON.parse(temp));
        // console.log('param', param);
        // console.log(this.configdetail[0].endpointurl);
        return new Promise((resolve, reject) => {
            axios.post(this.configdetail.endpointurl + param.api, param.data).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }

    /**
     * api for forgot password
     */
    ForgotPassword(param) {
        let temp = (localStorage.getItem('config_detail'));
        this.SetConfigDetail(JSON.parse(temp));
        // console.log('param', param);
        // console.log(this.configdetail[0].endpointurl);
        return new Promise((resolve, reject) => {
            axios.post(this.configdetail.endpointurl + param.api, param.data).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    }
}