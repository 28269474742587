import React from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import Api from "../../../../Providers/Api";
var api = new Api();
var journalData = {};
export default class JournalPreview extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showJournal: [],
            data: {}
        };
        // console.log(props);
    }

    /**
     * function to set the journal details
     */
    setJournalData(){
        this.setState({
            modalIsOpen: true,
        });
        // console.log(JSON.parse(sessionStorage.getItem('journalDetails')));
        if (sessionStorage.getItem('journalDetails')) {
            journalData = JSON.parse(sessionStorage.getItem('journalDetails'));
            this.setState({
                modalIsOpen: false,
            });
            // console.log(journalData.createdate);
            /*this.setState({
                data:  JSON.parse(localStorage.getItem('journalDetails'))
            })*/
        }
        this.setState({
            modalIsOpen: false,
        });
    }
    /**
     * function to show the journal of the user
     */
    ShowJournal(empid, date) {
        this.setJournalData();
        // console.log(this.props);
        this.setState({
            modalIsOpen: true,
        });
        const param = '/ShowEmpJournal_OfaDate?empid=' + empid + '&date=' + date;
        api.getApi(param).then(res => {
             console.log(res);
            if (res.data.status === 200) {
                if (res.data.message === 'No Record Found') {
                    this.setState({
                        showJournal: [
                            {
                                start_at: '--',
                                end_at: '--',
                                remark: 'No Data Found'
                            }
                        ]
                    })
                } else {
                    this.setState({
                        showJournal: res.data.response,
                        modalIsOpen: false
                    });
                }
            } else {
                window.alert(JSON.stringify(res));
            }
            // console.log(this.state.showJournal);
        }).catch(err => {
            // console.log(err);
            this.setState({
                modalIsOpen: true
            });
        });
    }
    render() {
        return (
            <div>
                <p>
                    Preview
                </p>
                <div className={'previewCard'}>
                    <h6>Journal Preview of {journalData.empname} <span className={'cursor-pointer'}></span></h6>
                    <div className={'cardlist'}>
                        {<p> {journalData.createdates} <small className={'line_separator'}>|</small> {journalData.hours} <small className={'line_separator'}>|</small> {journalData.project_count} Project <small className={'line_separator'}></small> </p>}
                    <MDBTable className="table-bordered">
                        <MDBTableHead>
                            <tr>
                                <th className={'inlineText'}>
                                    Time(From - to)
                                </th>
                                <th>
                                    Description in details
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {
                                this.state.showJournal.map((data) => (
                                    <tr>
                                        <td className={'journal_time_admin inlineText'}>
                                            {data.start_at} - {data.end_at}
                                        </td>
                                        <td>
                                            {data.remark}
                                        </td>
                                    </tr>
                                ))
                            }
                        </MDBTableBody>
                    </MDBTable>
                    </div>
                </div>
            </div>
        )
    }
}
