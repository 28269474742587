const styles = (theme) => ({
  signOutBtn: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "300px",
    borderRadius: "0px",
    height: "35px",
    boxShadow: "0px 0px 2px black",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#60cd35",
    fontWeight: 600,
  },
  messageContainer: {
    marginTop: "85px"
  }
});

export default styles;
