import React from "react";
import "./login.css";
import { Form, Toast } from "react-bootstrap";
import Api from "../../Providers/Api";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import { DotLoader } from "react-spinners";
import { auth, firestore } from "../../config/firebase.config";
const api = new Api();
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 80%;
  z-index: 5;
`;
export default class Login extends React.Component {
  toaster = {};
  constructor() {
    super();
    var date = new Date();
    this.state = {
      username: "",
      password: "",
      MonthArray: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      Hours: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds(),
      date: date.getDate(),
      year: date.getFullYear(),
      loading: false,
      time: 0,
      show: false,
    };
    this.CallProjectapi();
    setInterval(this.ChangeTime.bind(this), 1000);
  }

  /**
   * function to change the time every second
   */
  ChangeTime() {
    var date = new Date();
    this.setState({
      Hours: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds(),
      date: date.getDate(),
      year: date.getFullYear(),
    });
  }

  /**
   * function to handle the change of username
   * @param ev: event
   */
  handleUsernameChange(ev) {
    // console.log(ev);
    this.setState({
      username: ev.target.value,
    });
  }

  /**
   * function to handle the change of password
   * @param ev
   */
  handlePasswordChange(ev) {
    this.setState({
      password: ev.target.value,
    });
  }

  /**
   * function to call the apis
   */
  CallProjectapi() {
    api.ProjectLogin();
    this.setState({
      loading: false,
    });
  }

  // loginForChat = async () => {
  //   await auth.signInWithEmailAndPassword(this.state.username, this.state.password)
  //     .then((loginRes) => {
  //       console.log('Successfully connected with chat application: ', loginRes);
  //   }, (err) => {
  //       console.log('error on login: ', err);
  //   })
  // }


  // registerForChat = async (name) => {

  //   await auth
  //     .createUserWithEmailAndPassword(this.state.username, this.state.password)
  //     .then((authRes) => {
  //       const userObj = {
  //         email: authRes.user.email,
  //         displayName: name,
  //         photoURL: null,
  //       };
  //       firestore
  //         .collection("users")
  //         .add(userObj)
  //         .then((res) => {
  //           console.log('add user response: ', res);
  //         }, err => alert('Error to register user for chat. ' + err));
  //     })
  //     .catch((err) => {
  //       if (err.code === "auth/email-already-in-use") {
  //         this.loginForChat();
  //       } else {
  //         alert('Error while connecting to chat: ' + err);
  //       }
  //     });
  // };

  /**
   * function for login
   */
  Login = async () => {
    this.setState({
      loading: true,
    });
    // console.log(md5(this.state.password));
    const param = {
      api: "/EmployeeLogin",
      data: {
        emailoffice: this.state.username,
        password: this.state.password,
      },
    };
    // console.log('sent param to the login api:', param);
    await api
      .UserLogin(param)
      .then((res) => {
        console.log('response from user login api:', res);
        if (res.data.response === "Email or Password Incorrect") {
          this.setState({
            loading: false,
            show: true,
          });
          const notifyWrongEmail = () => {
            toast.error("Wrong Email or Password !", {
              position: "top-center",
              autoclose: 10000,
            })
          }
          notifyWrongEmail();
        } else {
          console.log(JSON.stringify(res.data.response));
          localStorage.setItem("userdetail", JSON.stringify(res.data.response));
          // this.registerForChat(res.data.response.name);
          this.setState({
            loading: false,
          });
          if (res.data.response.emptype === "Employee") {
            this.props.history.push("/dashboard");
          } else {
            this.props.history.push("/AdminDashboard");
          }
        }
      })
      .catch((err) => {
        console.error("error from login api:", err);
        window.alert(JSON.stringify(err));
        this.setState({
          loading: false,
        });
      });
  };

  /**
   * function for forgot password
   */
  ForgotPassword() {
    this.setState({
      loading: true,
    });
    const pass = this.GenerateRandomPassword();
    // console.log(this.state);
    // console.log('random password', this.GenerateRandomPassword());
    const param = {
      api: "/ForgotPassword",
      data: {
        emailoffice: this.state.username,
        // password: pass,
        // encrypassword: md5(pass)
      },
    };
    // console.log('json sent to the forgot password api: ', param);
    api
      .ForgotPassword(param)
      .then((res) => {
        // console.log('resposne from forgot passowrd api:', res);
        // window.alert('A random password has been sent to your email, Please Check and login.')
        this.setState({
          loading: false,
          show: true,
        });
        setInterval(this.ShowTime.bind(this), 1000);
        const notifyForgetPassword = () => {
          toast.success("A random password has been sent to your email, Please Check and login.", {
            position: "top-center",
            autoclose: 10000,
          })
        };
        notifyForgetPassword();
      })
      .catch((err) => {
        // console.error('error form forgot password api:', err);
        window.alert(JSON.stringify(err));
      });
  }

  /**
   * function to generate random password for the case of forgot password
   */
  GenerateRandomPassword() {
    const tempStr =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const stringlength = 8;
    let randomstring = "";
    for (let i = 0; i < stringlength; i++) {
      const rnum = Math.floor(Math.random() * tempStr.length);
      randomstring += tempStr.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
  /**
   * function to show the timer onto the toaster
   */
  ShowTime() {
    this.setState({
      time: this.state.time + 1,
    });
  }

  /**
   * function to close the toaster
   */
  close() {
    this.setState({
      show: false,
    });
  }
  /**
   * function to render the html onto the page
   */
  render() {
    return (
      <div className="login">
        <div className="row">
          <div className="col-md-5 hedding">
            <ToastContainer />
            <h4 className={'devMode'}>{api.mode === "development" ? "Development Mode" : ""}</h4>
            <h1>
              time is<br></br>what we want <br></br> most, but <br></br>what we
              use <br></br>worst
            </h1>
          </div>
          <div className="col-md-7 gradBnner">
            <div className="row">
              <div className="col-md-6">
                <div className="clock">
                  <div className="clockbg">
                    <h1>
                      {this.state.Hours >= 10
                        ? this.state.Hours
                        : "0" + this.state.Hours}
                      :
                      {this.state.min >= 10
                        ? this.state.min
                        : "0" + this.state.min}
                      :
                      {this.state.sec >= 10
                        ? this.state.sec
                        : "0" + this.state.sec}
                    </h1>
                    <h4>
                      {this.state.date +
                        " " +
                        this.state.MonthArray[new Date().getMonth()] +
                        ", " +
                        this.state.year}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <Form className={"form"}>
                  <Form.Control
                    placeholder="Enter Your Email Address"
                    type="email"
                    name="email"
                    value={this.state.username}
                    onChange={this.handleUsernameChange.bind(this)}
                  />
                  <Form.Control
                    placeholder="Password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange.bind(this)}
                    name="password"
                  />
                  <div className="btngroup">
                    <input
                      type="button"
                      disabled={!(this.state.username && this.state.password)}
                      className={"btn submitbtn"}
                      onClick={this.Login.bind(this)}
                      value="Login"
                    />
                    <input
                      type="button"
                      className={"btn active"}
                      disabled={!this.state.username}
                      onClick={this.ForgotPassword.bind(this)}
                      value="Forgot Password?"
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DotLoader
            sizeUnit={"px"}
            css={loaderCSS}
            size={150}
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}
