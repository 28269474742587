import React from 'react';
import './projects.css';
import Api from "../../Providers/Api";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import Modal from "react-modal";
import {CircleLoader} from "react-spinners";
import {css} from "@emotion/react";
const api = new Api();
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        border: 'none',
    }
};
export default class Project extends React.Component {
    constructor() {
        super();
        this.state = {
            projectname: '',
            projectJson: [],
            modalIsOpen: false
        };
        this.getProjects();
    }

    /**
     * function to add project
     */
    addProject() {
        this.setState({
            modalIsOpen: true
        });
        const param = {
            api: '/AddUpdateProject',
            data: {
                pjid: 0,
                name: this.state.projectname
            }
        };
        api.postApi(param).then(res => {
            // console.log(res);
            if (res.data.response === 'Project Name Already Exists') {
                window.alert('Project Already Exist');
                this.setState({
                    modalIsOpen: false
                });
            } else if (res.data.status === 200) {
                this.setState({
                    projectname: ''
                });
                this.getProjects();
                this.setState({
                    modalIsOpen: false
                });
            } else {
                window.alert(JSON.stringify(res));
                this.setState({
                    modalIsOpen: false
                });
            }
        }).catch(err => {
            // console.log(err);
            window.alert(JSON.stringify(err));
            this.setState({
                modalIsOpen: false
            });
        });
    }

    /**
     * function to get the projects
     */
    getProjects() {
        this.setState({
            modalIsOpen: true
        });
        const param = '/ShowProjects';
        api.getApi(param).then(res => {
            // console.log(res);
            var json = [];
            if (res.data.status === 200) {
                for (let i=0 ; i< res.data.response.length; i++) {
                    let data = res.data.response[i];
                    data.index = i+1;
                    json.push(data);
                }
                this.setState({
                    projectJson: json
                });
                this.setState({
                    projectJson: res.data.response,
                });
                this.setState({
                    modalIsOpen: false
                });
                // console.log(this.state.projectJson)
            } else {
                window.alert(JSON.stringify(res));
                this.setState({
                    modalIsOpen: false
                });
            }

        }).catch(err => {
            // console.log(err);
            window.alert(JSON.stringify(err));
            this.setState({
                modalIsOpen: false
            });
        });
    }

    /**
     * function to handle the change of the project name
     */
    handleChange(ev) {
        this.setState({
            projectname: ev.target.value,
        })
    }

    /**
     * change status of the project
     */
    changeProjectStatus(pid) {
        this.setState({
            modalIsOpen: true
        });
        const param = {
            api: '/UpdateprojectStatus',
            data: {
                pjid: pid
            }
        };
        api.postApi(param).then(res => {
            // console.log(res);
            this.setState({
                modalIsOpen: false
            });
            if (res.data.status === 200) {
                this.getProjects();
            } else {
                window.alert(JSON.stringify(res));
            }
        }).catch(err => {
            // console.log(err)
            this.setState({
                modalIsOpen: false
            });
        })
    }

    render() {
        return (
            <section className={'project'}>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <CircleLoader  css={override}
                                   sizeUnit={"px"}
                                   size={50}
                                   color={'#123abc'}
                                   loading={true}
                    />
                </Modal>
                <div className="form-group projectform mt-2">
                    <input type="text" placeholder={'Enter project name'} className={'form-control projectname text-dark'} value={this.state.projectname} onChange={this.handleChange.bind(this)}/>
                    <button
                        type="submit"
                        className="ml-2 btn btn-light addbtn"
                        disabled={!this.state.projectname}
                        onClick={this.addProject.bind(this)}
                    >{this.Editstatus? 'Update': 'Add'}
                    </button>
                </div>
                <MDBTable className="table table-bordered table-responsive bg-light">
                    <MDBTableHead>
                        <tr>
                            <th>#</th>
                            <th className={'text-left'}>Project Name</th>
                            <th>Status</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {
                            this.state.projectJson.map((item) => (
                                <tr>
                                    <td className={'align-middle'}>{item.index}</td>
                                    <td className={'text-left'}>{item.name}</td>
                                    <td>
                                        <button className={ item.status === 'Deactive'? 'btn btn-danger': 'btn btn-primary'} onClick={() => this.changeProjectStatus(item.pjid)}>
                                            {item.status}
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </MDBTableBody>
                </MDBTable>
            </section>
        )
    }
}
