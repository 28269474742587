import React from 'react';
import './task.css';
import { Tabs, Tab, Button, ButtonGroup, Card, Modal, Form } from 'react-bootstrap';

export default class Tasks extends React.Component {
    userDetail = {};
    applyingFor = 'E';
    toaster = {};
    date = {};
    constructor(props) {
        super(props);
        this.state = {
            showDatePicker: false,
            toasterStatus: false,
            toasterMessage: '',
            toasterHeader: '',
            time: 0,
            showT: false,
            loadingL: false
        };
    }

    /**
     * function for add employee modal
     */
    OpenDayCountmodal(data) {
        this.setState({
            pendingDetailsModal: true
        })
    }



    render() {

        return (
            <section className={'adminTasks'}>
                <Tabs defaultActiveKey="All" >
                    <Tab eventKey="All" title="All">
                        <div className={'row pt-2 pb-2'}>
                            <div className={'col-md-6'}>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="secondary" className={'taskBtn'} onClick={() => (this.OpenDayCountmodal(true))}>Add Task<i class="fas fa-plus" aria-hidden="true"></i></Button>
                                    <Button variant="secondary" className={'ml-4 sortBtn'}>Sort by<i class="fas fa-sort-amount-down-alt" aria-hidden="true"></i></Button>
                                </ButtonGroup>
                            </div>
                            <div className={'col-md-6 text-right'}>
                                <ul className={'searchInfo'}>
                                    <li className={'iconInfo'}>
                                        <i class="fa fa-info" aria-hidden="true"></i>
                                        <span>Help</span>
                                    </li>
                                    <li className={'taskSearch'}>
                                        <div className="searchbox">
                                            <form>
                                                <input type="search" placeholder="Search" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'allStatusCard'}>
                            <div className={'row pt-3'}>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                       <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                       <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Pending" title="Pending">
                        <div className={'row pt-2 pb-2'}>
                            <div className={'col-md-6'}>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="secondary" className={'taskBtn'}>Add Task<i class="fas fa-plus" aria-hidden="true"></i></Button>
                                    <Button variant="secondary" className={'ml-4 sortBtn'}>Sort by<i class="fas fa-sort-amount-down-alt" aria-hidden="true"></i></Button>
                                </ButtonGroup>
                            </div>
                            <div className={'col-md-6 text-right'}>
                                <ul className={'searchInfo'}>
                                    <li className={'iconInfo'}>
                                        <i class="fa fa-info" aria-hidden="true"></i>
                                        <span>Help</span>
                                    </li>
                                    <li className={'taskSearch'}>
                                        <div className="searchbox">
                                            <form>
                                                <input type="search" placeholder="Search" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'allStatusCard'}>
                            <div className={'row pt-3'}>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status pending'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="progress" title="progress">
                        <div className={'row pt-2 pb-2'}>
                            <div className={'col-md-6'}>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="secondary" className={'taskBtn'}>Add Task<i class="fas fa-plus" aria-hidden="true"></i></Button>
                                    <Button variant="secondary" className={'ml-4 sortBtn'}>Sort by<i class="fas fa-sort-amount-down-alt" aria-hidden="true"></i></Button>
                                </ButtonGroup>
                            </div>
                            <div className={'col-md-6 text-right'}>
                                <ul className={'searchInfo'}>
                                    <li className={'iconInfo'}>
                                        <i class="fa fa-info" aria-hidden="true"></i>
                                        <span>Help</span>
                                    </li>
                                    <li className={'taskSearch'}>
                                        <div className="searchbox">
                                            <form>
                                                <input type="search" placeholder="Search" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'allStatusCard'}>
                            <div className={'row pt-3'}>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status inProgress'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Completed" title="Completed">
                        <div className={'row pt-2 pb-2'}>
                            <div className={'col-md-6'}>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="secondary" className={'taskBtn'}>Add Task<i class="fas fa-plus" aria-hidden="true"></i></Button>
                                    <Button variant="secondary" className={'ml-4 sortBtn'}>Sort by<i class="fas fa-sort-amount-down-alt" aria-hidden="true"></i></Button>
                                </ButtonGroup>
                            </div>
                            <div className={'col-md-6 text-right'}>
                                <ul className={'searchInfo'}>
                                    <li className={'iconInfo'}>
                                        <i class="fa fa-info" aria-hidden="true"></i>
                                        <span>Help</span>
                                    </li>
                                    <li className={'taskSearch'}>
                                        <div className="searchbox">
                                            <form>
                                                <input type="search" placeholder="Search" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'allStatusCard'}>
                            <div className={'row pt-3'}>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className={'col-md-4 mb-4'}>
                                    <Card>
                                        <Card.Header>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                <div className={'status completed'}>
                                                3
                                        </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={'actionIcon'}>
                                                <li className={'iconInfo'}>
                                                    <img src="../archive.png" alt="archive image" className={'archiveIcon'} />
                                                    <span>Archive</span>
                                                </li>
                                                <li className={'iconInfo'}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                    <span>Delete</span>
                                                </li>

                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                <div>
                    <Modal

                        dialogClassName="modal-70w"
                        show={this.state.pendingDetailsModal}

                        aria-labelledby="contained-modal-title-vcenter"
                        centered >
                        <section className={'pendingModal'}>
                            <h4 className={'mainHeading'}>Name of your task</h4>
                            <div className={'taskDetail'}>
                                <Form className={"form"}>
                                    <Form.Control
                                        placeholder="Details about the task"
                                        type="text" />
                                    <div className={'row selectSubtasc'}>
                                        <div className={'col-md-6'}>
                                            <Form.Control
                                                placeholder="Name of subtask, if any"
                                                type="text" />
                                        </div>
                                        <div className={'col-md-3'}>
                                            <select>
                                                <option value="Assign">Assign</option>
                                                <option value="Lokesh">Lokesh</option>
                                                <option selected value="Lokendra">Lokendra</option>
                                                <option value="Atal">Atal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Form.Control
                                        placeholder="Add another subtask"
                                        type="text" />
                                    <Form.Control
                                        placeholder="Due date and time"
                                        type="Date" />
                                </Form>
                            </div>
                            <div className={'asignPeople'}>
                                <h6>Assigned people</h6>
                                <ul>
                                    <li>
                                        <img src="../iron-man.jpg" alt="user-img" className="img-fluid" />
                                        <p>Becky</p>
                                    </li>
                                    <li>
                                        <img src="../iron-man.jpg" alt="user-img" className="img-fluid" />
                                        <p>John</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="float-right mb-1 text-right" role="group" aria-label="Basic example">
                                <button className={'approve_reject_button btn btn-reject btnMark'} onClick={() => (
                                    this.setState({
                                        pendingDetailsModal: false
                                    })
                                )}>Reject</button>
                                <button className={'approve_reject_button btn btn-approve btnMark'} onClick={() => (
                                    this.setState({
                                        pendingDetailsModal: false
                                    })
                                )}>Approve</button>
                            </div>
                        </section>
                    </Modal>
                </div>
            </section>

        )
    }
}
