import React, { Component } from 'react';
import './TrackerProjects.css';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Badge,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Stepper,
    Step,
    StepLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';

/**
 * Functional component to render Tabs content 
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`simple-tabpanel-${index}`}
            // aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

/**
 * functional components to render invite team members row
 */
function InviteMembersRow() {
    return (
        <div className={'inviteMembersRow'}>
            <span>1.</span>
            <input className={'inputFields'}
                type="email"
                placeholder="Email" />
            <FormControl className={'inputSelect'} >
                <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                //   value={age}
                //   onChange={handleChange}
                >
                    <MenuItem value='Sv'>Supervisor</MenuItem>
                    <p>Can see all team activity</p>
                    <MenuItem value='Wk'>Worker</MenuItem>
                    <p>Can see their own activity</p>
                </Select>
            </FormControl>
        </div>
    )
}

export default class TrackerProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabValues: 0,
            isAddProjectDialogOpen: false,
            activeStep: 0,

        }
    }
    steps = this.getSteps();

    /**
     * function to handle the tab rendering
     */
    tabHandler = (e, value) => {
        this.setState({
            tabValues: value
        })
    }

    /**
     * function to get the Stepper steps
     */
    getSteps() {
        return ['General', 'Invite Team', 'Confirm Details'];
    }

    /**
     * function to get the Stepper content
     */
    getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <React.Fragment>
                        <div className={'row'}>
                            <div className={'col-md-3'}>
                                <p className={'label'}>Project Name</p>
                            </div>
                            <div className={'col-md-9'}>
                                <input className={'input'} type='text' autoFocus />
                            </div>
                        </div>
                        <hr />

                        <div className={'footer text-right'}>
                            <Button variant="outline-primary" className={'cancelBtn'} onClick={this.handleAddProjectDialog}>Cancel</Button>
                            <Button variant="success" className={'goToTeamBtn'} onClick={this.handleNext}>Go to Team </Button>
                        </div>
                    </React.Fragment>
                )
            case 1:
                return (
                    <React.Fragment>
                        <h6>Invite Team Members</h6>
                        <p>Enter each team member's email address and role. They will receive an email invitation to your TopTracker project</p>
                        <InviteMembersRow />
                        <InviteMembersRow />
                        <InviteMembersRow />
                        <Button variant="outline-primary" className={'addMoreFieldsBtn'}><AddIcon className={'addIcon'}/> Add another member</Button>
                        <div className={'footer text-right'}>
                            <Button variant="primary" className={'backBtn'} onClick={this.handleBack}>Back</Button>
                            <Button variant="success" className={'nextBtn'} onClick={this.handleNext}>Go to Confirm Details</Button>
                        </div>
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <p>Project Name</p>
                        <h4>XYZ</h4>
                        <p>Project Details</p>
                        <h5><AccountCircleIcon className={'userIcon'} />XYZ@abc.com :- Worker</h5>
                        <h5><AccountCircleIcon className={'userIcon'} />XYZ@abc.com :- Worker</h5>
                        <h5><AccountCircleIcon className={'userIcon'} />XYZ@abc.com :- Worker</h5>
                        <h5><AccountCircleIcon className={'userIcon'} />XYZ@abc.com :- Worker</h5>
                        <div className={'footer text-right'}>
                            <Button variant="primary" className={'backBtn'} onClick={this.handleBack}>Back</Button>
                            <Button variant="success" className={'nextBtn'}>Confirm</Button>
                        </div>
                    </React.Fragment>

                )
            default:
                return 'Unknown step';
        }
    }

    /**
     * function to handle the next rendering of Next Button
     */
    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    };

    /**
     * function to handle the back rendering of Back Button
     */
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    /**
     * function to handle the reset rendering of Reset Button
     */
    handleReset = () => {
        // setActiveStep(0);
        this.setState({
            activeStep: 0
        })
    };


    /**
     * function to handle the Add Project Dialog
     */
    handleAddProjectDialog = () => {
        this.setState({
            isAddProjectDialogOpen: !this.state.isAddProjectDialogOpen
        })
    }


    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'trackerProjects'}>
                    <div className={'header'}>
                        <h4>Projects</h4>
                        <button className={'btn btn-success'} onClick={this.handleAddProjectDialog}>New Project +</button>
                    </div>

                    {/* Tab header starts here */}
                    <Tabs
                        value={this.state.tabValues}
                        onChange={this.tabHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullwidth"
                        className={'tabsHeader'}
                    >
                        <Tab label="Active(3)" className={'tabs'} />
                        <Tab label="Archived" className={'tabs'} />
                    </Tabs>
                    {/* Tab header ends here */}

                    {/* Tab content starts here */}
                    <TabPanel value={this.state.tabValues} index={0}>
                        <Table borderless hover>
                            <thead>
                                <tr>
                                    <th>
                                        <span>Name <ImportExportIcon /></span>
                                        <span>Creation Date <ImportExportIcon /></span>
                                    </th>
                                    <th>
                                        <span>Team <ImportExportIcon /></span>
                                    </th>
                                    <th>
                                        <span>Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className={'projectInfo'}>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>VD</Avatar>
                                        <div>
                                            <p className={'title'}>Vote Do</p>
                                            <p className={'text'}>Joined at Sep 22 | Worker</p>
                                        </div>
                                    </td>
                                    <td className={'team'}><PersonIcon />1</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                                <tr>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>TB</Avatar>
                                        <div>
                                            <p className={'title'}>TransferBoss</p>
                                            <p className={'text'}>Joined at Sep 10 | Worker</p>
                                        </div>
                                    </td>
                                    <td className={'team'}><PersonIcon />5</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                                <tr>
                                    <td className={'projectBlock'}>
                                        <Avatar className={'avatar'}>O</Avatar>
                                        <div>
                                            <p className={'title'}>OPM</p>
                                            <p className={'text'}>Joined at Oct 5 | Worker</p>
                                        </div>
                                    </td>
                                    <td className={'team'}><PersonIcon />3</td>
                                    <td className={'actions'}><MoreHorizIcon /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel value={this.state.tabValues} index={1}>
                        <div className={'archivedProjects text-center'}>
                            <FileCopyIcon className={'icons'} />
                            <p>You don't have any archived projects yet</p>
                        </div>
                    </TabPanel>
                    {/* Tab content ends here */}

                    {/* Start new project dialog starts here */}
                    <Dialog maxWidth={"md"} fullWidth={true} open={this.state.isAddProjectDialogOpen} onClose={() => {
                        this.handleAddProjectDialog();
                        this.handleReset();
                    }} className={'addProjectDialog'}>
                        <DialogTitle className={'dialogTitle'}>
                            <p className={'heading'}>Start New Project</p>
                            <Stepper activeStep={this.state.activeStep} className={'stepper'}>
                                {this.steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </DialogTitle>
                        <DialogContent className={'dialogContent'}>
                            <div className={'content'}>{this.getStepContent(this.state.activeStep)}</div>
                        </DialogContent>
                    </Dialog>
                    {/* Start new project dialog ends here */}


                </div>
            </div>
        )
    }
}


