import React from "react";
import {
  Avatar,
  IconButton,
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import './MessageView.css';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class MessageView extends React.Component {
  userDetail = JSON.parse(localStorage.getItem('userdetail'));

  componentDidMount = () => {
    const container = document.getElementById("msgView-container");
    if (container) container.scrollTo(0, container.scrollHeight);
  };
  // componentDidUpdate = () => {
  //   const container = document.getElementById("msgView-container");
  //   if (container) container.scrollTo(0, container.scrollHeight);
  // };

  render() {
    const { classes, chat, recieverName, recieverLogo} = this.props;
    // console.log(recieverLogo);
    return(
      <React.Fragment>
    {
      chat.length !== 0 ? (
        <div className={'msgView'}>
          <div className={'header'}>
            <div className={'avatarSection'}>
            <Avatar className={'avatar'}>
              {recieverLogo}
            </Avatar>
            <h5>{recieverName}</h5>
            </div>
            <IconButton>
              <CloseIcon className={'icon'} onClick={() => this.props.closeFn()}/>
            </IconButton>
          </div>
          <main id="msgView-container" className={'msgView-container'}>
            {
              chat.map((chatData, index) => (
                <div key={index}
                className={
                  this.userDetail.empid === chatData.sender_empid ? 'userSent' : 'friendSent'
                }>
                  {chatData.chat}
                  <br />
                  <span className={'timeStamp'}>
                    {chatData.send_time}
                  </span>
                </div>
              ))
            }
          </main>
        </div>
      ) : (
        <div className={'loader'}>
          <CircularProgress />
        </div>
        // null
      )
    }
    </React.Fragment>
    )
  }
}

