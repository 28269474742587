import React, { Component } from 'react';
import './TrackerWebTracker.css';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Badge,
    Avatar
} from '@material-ui/core';
import Table from 'react-bootstrap/Table';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';


export default class TrackerProjects extends Component {
    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'webTracker'}>
                    <h4 className={'heading'}>Web Tracker</h4>
                    <Table borderless hover>
                        <thead>
                            <tr>
                                <th>
                                    <span>Name <ImportExportIcon /></span>
                                    <span>Total Tracked <ImportExportIcon /></span>
                                    <span>Last Tracked <ImportExportIcon /></span>
                                </th>
                                <th>
                                    <span>Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'projectBlock'}>
                                <Avatar className={'avatar'}>Rs</Avatar>
                                        <div>
                                            <p className={'title'}>Vote Do</p>
                                            <p className={'text'}>Total-234:59 Last Tracked on 18 Nov</p>
                                        </div>
                                </td>
                                <td className={'playIconBlock'}><PlayCircleFilledIcon className={'playIcon'} /></td>
                            </tr>
                            <tr>
                                <td className={'projectBlock'}>
                                <Avatar className={'avatar'}>Rs</Avatar>
                                        <div>
                                            <p className={'title'}>Onion Project Management</p>
                                            <p className={'text'}>Total-23:59 Last Tracked on 13 Nov</p>
                                        </div>
                                </td>
                                <td className={'playIconBlock'}><PlayCircleFilledIcon className={'playIcon'} /></td>
                            </tr>
                            <tr>
                                <td className={'projectBlock'}>
                                <Avatar className={'avatar'}>Rs</Avatar>
                                        <div>
                                            <p className={'title'}>Transferboss</p>
                                            <p className={'text'}>Total-24:59 Last Tracked on 13 Oct</p>
                                        </div>
                                </td>
                                <td className={'playIconBlock'}><PlayCircleFilledIcon className={'playIcon'} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}


