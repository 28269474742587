import React from 'react';
import './holidays.css';
export default class Holidays extends React.Component {
    render() {
        return (
            <ul>
                <li className="holidayCard">
                    <ul className="inline-list">
                        <li>{this.props.data.holidaydate}</li>
                        <li><strong>{this.props.data.holidayday[0] + this.props.data.holidayday[1] + this.props.data.holidayday[2]}</strong></li>
                        {/*<li>{this.props.Status}</li>*/}
                        <li>{this.props.data.occassion}</li>
                        {/* <li>{this.props.checkout} OUT</li> */}
                    </ul>
                </li>
            </ul>

        )
    }

}
