import React from 'react';
import './Overview.css'; 

export default class Overview extends React.Component{
    render() {
        return (
             <section className={'overview'}>
               <div className={'overviewCard'}></div>
             </section>
        )
    }
}
