import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../shared/card.js';
import './userProjectDetail.css';
import ChatBox from '../chatBox/chatBox.jsx';
import mileStone from '../../../shared/milestones.svg'
var card = new Card();
export default class UserProjectDetail extends React.Component {

    constructor(props) {
        super(props);
        console.log('data from props', this.props.id);
        console.log('project detail call');


    }// constructor end..
    render() {
        let mileStoneArray = ['a', 'b', 'c', 'd']
        let images = mileStoneArray.map(images => {
            return <img
                key={images}
                src={mileStone}
                alt="milestone"
                className="mileStoneImg"
            />
        });
        return (

            <div className="col-md-12 p-0">
                <div className="projecDetail">
                    {/* card list start */}
                    <div className="cardList pb-3">
                        {
                            card.cardjson.map((Item) => (
                                <div class="card text-center cardProjectDeatil" >
                                    <Link to={'/'}>
                                        <img
                                            src={Item.icon}
                                            alt="dash-icon"
                                            className="projectIcons"
                                        />
                                        <span className="cardName" > {Item.name} </span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    {/* card list end.. */}
                    <div className="row">
                        <div className="col-md-9 p-0">
                            <div className="boxprocjectDetailcss">
                                <span className="ml-3"> Project Status</span>
                                <div className="projectStatus">
                                    <span><i class="fa fa-caret-right trangleIcon" aria-hidden="true"></i></span>
                                    <div className="mainProgressHeight">
                                        {images}
                                        <div className="customProgressMain">
                                            <div className="inProgress"></div>
                                        </div>
                                    </div>
                                    <span ><i class="fa fa-square ml-1 " aria-hidden="true"></i></span>
                                </div>
                                <div className="row">

                                    <div className="col-md-4 ">
                                        <h6 >Task Status</h6>
                                        <span className="noOfTask">30/35</span>
                                        <div className="customProgress">
                                            <div className="inProgress"></div>
                                        </div>
                                        <span > 64.82%</span>
                                        {/*  task progress bar end*/}
                                        <h6 className="space">Hours</h6>
                                        <span className="noOfTask">150/250</span>
                                        <div className="customProgress">
                                            <div className="inProgress"></div>
                                        </div>
                                        <span> 60.00%</span>
                                        {/* hours progress bar end */}
                                        <h6 className="space" s>Issues</h6>
                                        <span className="noOfTask">09/10</span>
                                        <div className="customProgress">
                                            <div className="inProgress"></div>
                                        </div>
                                        <span> 60.00%</span>
                                    </div>
                                    <div className="col-md-4 ">
                                        {/* project status card */}
                                        <h6 className="m-0"> Project Status</h6>
                                        <div class="card text-center projectStatusCard" >
                                            <h3 class="mt-1"> 12 % </h3>
                                            <span>  Complete </span>
                                        </div>


                                        <h6 className="cardspace mb-0"> Current Sprint Status</h6>
                                        <div class="card text-center projectStatusCard" >
                                            <h3 className="mt-1"> 37 % </h3>
                                            <span>  Complete </span>
                                        </div>


                                        <h6 className="cardspace mb-0"> Total Sprint </h6>
                                        <div class="card text-center projectStatusCard" >
                                            <h3 className="mt-1">  2 </h3>
                                            <span> Sprints </span>

                                        </div>

                                    </div>
                                    {/* <div className="col-md-1"></div> */}
                                    <div className="col-md-4 p-0">
                                        <h6 className="m-0"> Status of task </h6>
                                        <div class="card text-center projectStatusCard cardStatus" >
                                            <div className="row">
                                                <div className="col-md-4  borderRight ">
                                                    <h3 className="mt-1 text-center ml-1 mb-0" >  54 </h3>
                                                    <span className="statusTaskName text-center ml-1"> Assigned </span>
                                                </div>
                                                <div className="col-md-4 borderRight">
                                                    <h3 className="mt-1 text-center mb-0">  4 </h3>
                                                    <span className="statusTaskName text-center"> Doing </span>
                                                </div>
                                                <div className="col-md-4 p-0">
                                                    <h3 className="mt-1 text-center mb-0">  35 </h3>
                                                    <span className="statusTaskName text-center"> Finished </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* box end */}
                        </div>
                        <div className="col-md-3">
                            {/* chat component */}
                            <ChatBox />
                        </div>
                    </div>
                </div>
            </div>

        ) // return end..
    } // render end..


}//main class ned..