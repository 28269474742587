import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Paper,
  withStyles,
  CssBaseline,
  Typography,
} from "@material-ui/core";
import styles from "./styles";
import { auth, firestore } from "../../../config/firebase.config";

class NewChat extends React.Component {
  constructor() {
    super();
    this.state = {
      username: null,
      message: null,
      serverError: false,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Send a Message
          </Typography>
          <form
            className={classes.form}
            onSubmit={(e) => this.submitNewChat(e)}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="new-chat-username">
                Enter Your Friend's Email
              </InputLabel>
              <Input
                className={classes.input}
                required
                autoFocus
                onChange={(e) => this.userTyping("username", e)}
                id="new-chat-username"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="new-chat-message">
                Enter Your Message...
              </InputLabel>
              <Input
                className={classes.input}
                required
                onChange={(e) => this.userTyping("message", e)}
                id="new-chat-message"
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
            >
              Send
            </Button>
          </form>
          {this.state.serverError ? (
            <Typography
              component="h5"
              variant="h6"
              className={classes.errorText}
            >
              This user does not exist.
            </Typography>
          ) : null}
        </Paper>
      </main>
    );
  }

  userTyping = (type, e) => {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "message":
        this.setState({ message: e.target.value });
        break;
      default:
        break;
    }
  };

  submitNewChat = async (e) => {
    e.preventDefault();
    const userExists = await this.userExists();
    if (userExists) {
      const chatExists = await this.chatExists();
      chatExists ? this.goToChat() : this.createChat();
    }
  };

  createChat = () => {
    this.props.newChatSubmitFn({
      sendTo: this.state.username,
      message: this.state.message,
    });
  };

  goToChat = () =>
    this.props.goToChatFn(this.buildDocKey(), this.state.message);

  buildDocKey = () => {
    return [auth.currentUser.email, this.state.username].sort().join(":");
  };

  chatExists = async () => {
    const docKey = this.buildDocKey();
    const chat = await firestore.collection("chats").doc(docKey).get();
    console.log("chat exists: ", chat.exists);
    return chat.exists;
  };

  userExists = async () => {
    const userSnapshot = await firestore.collection("users").get();
    const exists = userSnapshot.docs
      .map((_doc) => _doc.data().email)
      .includes(this.state.username);
    this.setState({ serverError: !exists });
    return exists;
  };
}

export default withStyles(styles)(NewChat);
