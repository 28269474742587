import React, { Component } from 'react';
import './TrackerActivities.css';
import {
    DropdownButton,
    Dropdown,
    Button
} from 'react-bootstrap';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import {
    IconButton,
    DialogTitle,
    DialogContent,
    Dialog,
    Tooltip,
    TextField,
    MenuItem,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export default class TrackerActivities extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isTimeEntryDialogOpen: false,
            isExpand: false,
        }
    }

    /**
     * Activities data to render on activities block
     */
    activitiesData = [
        {
            date: 'Nov 19',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 18',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 17',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 16',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 15',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 14',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
        {
            date: 'Nov 13',
            startTime: '11:58am',
            endTime: '07:10pm',
            description: 'Tracker implementation',
            project: 'Onion Project Management',
            keyboardHits: 1240,
            mouseClicks: 1340,
            screenshots: 345,
        },
    ]
    /**
     * function to open or close the timeEntryDialog
     */
    handleTimeEntryDialog = () => {
        this.setState({
            isTimeEntryDialogOpen: !this.state.isTimeEntryDialogOpen
        })
    }



    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'trackerActivities'}>
                    <h4 className={'heading'}>
                        My Activities
                    </h4>
                    <div className={'header'}>
                        <DropdownButton id="dropdown-basic-button" title={<span> <WorkOutlineOutlinedIcon className={'icons'} /> All Projects (3)</span>} className={'btnDropdown'}>
                            <Dropdown.Item>All Projects</Dropdown.Item>
                            <Dropdown.Item>OPM</Dropdown.Item>
                            <Dropdown.Item>Transferboss</Dropdown.Item>
                            <Dropdown.Item>Vote Do</Dropdown.Item>
                        </DropdownButton>
                        <div className={'btnGrp'}>
                            <Tooltip title="Add Manual Time Entry" arrow placement="top">
                                <IconButton className={'iconBtn'} onClick={this.handleTimeEntryDialog}><AccessTimeIcon /></IconButton>
                            </Tooltip>

                            <DropdownButton id="dropdown-basic-button" title={<span> <CalendarTodayOutlinedIcon className={'icons'} /> 3-10 Nov</span>} className={'btnDropdown'}>
                                <Dropdown.Item>All Projects</Dropdown.Item>
                                <Dropdown.Item>OPM</Dropdown.Item>
                                <Dropdown.Item>Transferboss</Dropdown.Item>
                                <Dropdown.Item>Vote Do</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>

                    {/* Add Manual Time Entry Dialog starts here */}
                    <Dialog open={this.state.isTimeEntryDialogOpen} className={'timeEntryDialog'} maxWidth={"sm"} fullWidth={true} onClose={this.handleTimeEntryDialog}>
                        <IconButton onClick={this.handleTimeEntryDialog} className={'closeBtn'}><CloseOutlinedIcon /></IconButton>
                        <DialogTitle>Add Manual Time Entry</DialogTitle>
                        <DialogContent className={'dialogContent'}>
                            <div className={'row'}>
                                <div className={'col-md-4 text-right inputLabel'}>Project</div>
                                <div className={'col-md-8 text-left'}>
                                    <select className={'inputField'}>
                                        <option value='Transferboss'>Transferboss</option>
                                        <option value='Vote Do'>Vote Do</option>
                                        <option value='OPM'>Onion Project Management</option>
                                    </select>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-md-4 text-right inputLabel'}>Activity Description <br /> (Optional)</div>
                                <div className={'col-md-8 text-left'}>
                                    <input type='text' className={'inputField activityDescription'} placeholder="Description" />
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-md-4 text-right inputLabel'}>Start Date</div>
                                <div className={'col-md-8 text-left'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            // label="Date picker inline"
                                            className={'dateField'}
                                        // value={selectedDate}
                                        // onChange={handleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-md-4 text-right inputLabel'}>Duration, HH:mm <br /> (Up to 24h)</div>
                                <div className={'col-md-8 text-left'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            id="time-picker"
                                            disableToolbar
                                            // label="Time picker"
                                            variant="inline"
                                            className={'timeField'}
                                        // value={selectedDate}
                                        // onChange={handleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-md-4 text-right inputLabel'}>Worked From</div>
                                <div className={'col-md-8 text-left'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={'workedFrom'}>
                                        <KeyboardTimePicker
                                            id="time-picker"
                                            disableToolbar
                                            // label="Time picker"
                                            variant="inline"
                                            className={'timeField'}
                                        // value={selectedDate}
                                        // onChange={handleDateChange}
                                        />
                                        To
                                        <KeyboardTimePicker
                                            id="time-picker"
                                            disableToolbar
                                            // label="Time picker"
                                            variant="inline"
                                            className={'timeFieldEnd'}
                                        // value={selectedDate}
                                        // onChange={handleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div classname={'col-md-12 text-right'}>
                                <Button variant="success" className={'submitBtn'}>Submit</Button>
                            </div>

                        </DialogContent>

                    </Dialog>
                    {/* Add Manual Time Entry Dialog ends here */}

                    {/* Activties Block starts here */}
                    {
                        this.activitiesData.map((data) => (
                            <div className={'activtiesBlock'} key={data.date}>
                                <p className={'title'}><strong>{data.date} &#8729; </strong> Tracked 5 hours 11 minutes</p>
                                <div className={'row activitiesCard'}>
                                    <div className={'col-md-2 timeBlock'}>
                                        <Tooltip title="Expand Activities" arrow placement="top">
                                            <div className={'activitiesBtn'}>
                                                <span className={'activitiesNo'}>5</span>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Expand Activities" arrow placement="top">
                                            <div className={'expandBtn'}>
                                                {this.state.isExpand ? <RemoveOutlinedIcon className={'expandIcon'} /> : <AddOutlinedIcon className={'expandIcon'} />}
                                            </div>
                                        </Tooltip>
                                        <div>
                                            <span>{data.startTime} - {data.endTime}</span><br />
                                            <p className={'timeText'}>7 hours 4 minutes</p>
                                        </div>
                                    </div>
                                    <div className={'col-md-5 projectBlock'}>
                                        <div>
                                            <h5>{data.description}</h5>
                                            <p className={'timeText'}>{data.project}</p>
                                        </div>
                                        <div className={'verticalLines'}></div>
                                    </div>
                                    <div className={'col-md-2 tracking'}>
                                        <p className={'timeText'}>{data.keyboardHits} keyboard hits</p>
                                        <p className={'timeText'}>{data.mouseClicks} mouse clicks</p>
                                    </div>

                                    <div className={'col-md-3 screenshotsBlock'}>
                                        <div className={'verticalLines'}></div>
                                        <CameraAltOutlinedIcon className={'screenShotIcon'} />
                                        <div>
                                            <p className={'timeText'}><span>Desktop Activity</span></p>
                                            <p className={'timeText'}>{data.screenshots} screenshots</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {/* Activties Block ends here */}

                    <Button variant="outline-primary" className={'extendingBtn'}>Extend selection to 31 days</Button>
                    
                </div>
            </div>
        )
    }
}


