import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Paper,
  withStyles,
  CssBaseline,
  Typography,
} from "@material-ui/core";
import { auth, firestore } from "../../../config/firebase.config";
import './NewMessage.css';
import Api from "../../../Providers/Api";
import {
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const api = new Api();

export default class NewMessage extends React.Component {

  constructor() {
    super();

    this.state = {
      username: null,
      message: null,
      serverError: false,
      loadingSpinner: false,
      employeeList: [],
      selectedEmployees: [],
      selectedEmployeesId: [],
    };
    this.getEmployeeList();
  }

  userDetail = JSON.parse(localStorage.getItem('userdetail'));

  userTyping = (type, e) => {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "message":
        this.setState({ message: e.target.value });
        break;
      default:
        break;
    }
  };

  // submitNewChat = async (e) => {
  //   e.preventDefault();
  //   const userExists = await this.userExists();
  //   if (userExists) {
  //     const chatExists = await this.chatExists();
  //     chatExists ? this.goToChat() : this.createChat();
  //   }
  // };

  // createChat = () => {
  //   this.props.newChatSubmitFn({
  //     sendTo: this.state.username,
  //     message: this.state.message,
  //   });
  // };

  // goToChat = () =>
  //   this.props.goToChatFn(this.buildDocKey(), this.state.message);

  // buildDocKey = () => {
  //   return [auth.currentUser.email, this.state.username].sort().join(":");
  // };

  // chatExists = async () => {
  //   const docKey = this.buildDocKey();
  //   const chat = await firestore.collection("chats").doc(docKey).get();
  //   console.log("chat exists: ", chat.exists);
  //   return chat.exists;
  // };

  // userExists = async () => {
  //   const userSnapshot = await firestore.collection("users").get();
  //   const exists = userSnapshot.docs
  //     .map((_doc) => _doc.data().email)
  //     .includes(this.state.username);
  //   this.setState({ serverError: !exists });
  //   return exists;
  // };

  /**
   * function to submit the new chat
   */
  submitNewChat = (event) => {
    event.preventDefault();
    
    const param = {
      api: '/AddEmployeeChat',
      data: {
        sender_empid: this.userDetail.empid,
        receiver_empid: this.state.selectedEmployeesId,
        chat: this.state.message,
      }
    };

    api.postApi(param).then((res) => {
      console.log(res);
      this.resetMsgForm();
    })
    .catch(err => {
      console.log(err);
    })
  }

  /**
   * function to get the list of all employees
   */
  async getEmployeeList() {
    const param = `/ShowEmployeeData?employeeId=${this.userDetail.empid}`;
    // console.log(this.userDetail.empid);
    await api
          .getApi(param)
          .then((res) => {
            console.log('Empl list', res);
            this.setState({
              employeeList: res.data.response
            })
          })
          .catch((err) => {
            console.log(err);
          })
  }

  /**
   * function to handle the multi-select dropdown of employees
   */
  selectEmployeesHandler = (newValue) => {
    this.setState({
      selectedEmployees: newValue
    }, () => {
      console.log(this.state.selectedEmployees, 'List of employees');
      this.state.selectedEmployeesId.splice(0, this.state.selectedEmployeesId.length);
      // this.state.selectedEmployees.splice(0, this.state.selectedEmployees.length);
      for(let empIndex = 0; empIndex < this.state.selectedEmployees.length; empIndex++){
        this.state.selectedEmployeesId.push(this.state.selectedEmployees[empIndex].empid);
      }
      console.log(this.state.selectedEmployeesId);
    })
  }

  /**
   * function to reset the msg form
   */
  resetMsgForm = () => {
    this.setState({
      selectedEmployees: [],
      selectedEmployeesId: [],
      message: null,
    })
  }



  render() {
    return (
      <main >
        <CssBaseline />
        <Paper className={'newChatForm'}>
          <form
            className={'form'}
            onSubmit={(e) => this.submitNewChat(e)}
            
          >
            <Autocomplete
              multiple
              value={this.state.selectedEmployees}
              onChange={(e, newValue) => this.selectEmployeesHandler(newValue)}
              id="tags-standard"
              options={this.state.employeeList}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter your friend's name"
                />
              )}
            />
           
            <textarea
              required
              rows='3'
              onChange={(e) => this.userTyping("message", e)}
              className={'input msgBox'}
              placeholder=" Enter Your Message..."
              id="new-chat-message" />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={'sendBtn'}
              disabled={this.state.selectedEmployees.length === 0 || !this.state.message}
            >
              Send
            </Button>
          </form>
          {/* {this.state.serverError ? (
            <Typography
              component="h5"
              variant="h6"
              className={'serverError'}
            >
              This user does not exist.
            </Typography>
          ) : null} */}
        </Paper>
      </main>
    );
  }
}


