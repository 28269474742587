import EmployeeList from "./Components/Personal/EmployeeList/EmployeeList";
import LeaveDetail from "./Components/Personal/LeaveDetail/LeaveDetail";
import EmployeeSetting from "./Components/Personal/EmployeeSetting/EmployeeSetting";
import AttendanceSheet from "./Components/Personal/AttendanceSheet/AttendanceSheet";
import AdDashboard from "./Components/Admin/AdDashboard/AdDashboard";
import Login from "./Components/login/login";
import Project from "./Components/Projects/project";
import Tasks from "./Components/TaskManagement/Tasks/Tasks";
import Overview from "./Components/Overview/Overview";
import Personnel from "./Components/Personnel/Personnel";
import Setting from "./Components/Setting/Setting";
import Journal from "./Components/Journal-Details/Admin/Journal/Journal Details";
import Toptracker from "./Components/Toptracker/Toptracker";
import TrackerProject from './Components/Toptracker/TrackerProject/TrackerProject';
import TrackerReports from "./Components/Toptracker/TrackerReports/TrackerReports";

const routing = [{
        name: 'Dashboard',
        path: '/AdDashboard',
        component: AdDashboard,
        layout: '/Admindashboard'
    },
    {
        name: 'Employee List',
        path: '/EmployeeList',
        component: EmployeeList,
        layout: '/Admindashboard'
    },

    {
        name: 'Attendance Sheet',
        path: '/AttendanceSheet',
        component: AttendanceSheet,
        layout: '/Admindashboard'
    },
    {
        name: 'Leave Detail',
        path: '/LeaveDetail',
        component: LeaveDetail,
        layout: '/Admindashboard'
    },
    {
        name: 'Employee Setting',
        path: '/EmployeeSetting',
        component: EmployeeSetting,
        layout: '/Admindashboard'
    },
    {
        name: 'Project',
        path: '/Project',
        component: Project,
        layout: '/Admindashboard'
    }, 
    {
        name: 'Overview',
        path: '/Overview',
        component: Overview,
        layout: '/Admindashboard'
    },
    
    {
        name: 'Personnel',
        path: '/Personnel',
        component: Personnel,
        layout: '/Admindashboard'
    },
    {
        name: 'Journal Details',
        path: '/journal',
        component: Journal,
        layout: '/Admindashboard'
    },
    {
        name: 'Projects',
        path: '/TrackerProject',
        component: TrackerProject,
        layout: '/Admindashboard'
    },
    {
        name: 'Reports',
        path: '/TrackerReports',
        component: TrackerReports,
        layout: '/Admindashboard'
    },
    {
        name: 'Tasks',
        path: '/Tasks',
        component: Tasks,
        layout: '/Admindashboard'
    }, 
     {  name: 'Setting',
        path: '/Setting',
        component: Setting,
        layout: '/Admindashboard'
    },
    {
        name: 'Logout',
        path: '/logout',
        component: Login,
        layout: '/'
    }
];
export default routing;
