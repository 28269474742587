import React, { Component } from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import CircularProgress from '@material-ui/core/CircularProgress';
import PeopleIcon from '@material-ui/icons/People';
import './MessageList.css';
import Api from "../../../Providers/Api";

const api = new Api();


export default class MessageList extends Component {

    constructor() {
        super()

        this.state = {
            employeeList: [],
            loading: false,
        }
        this.getEmployeeList();
    }

    userDetail = JSON.parse(localStorage.getItem('userdetail'));

    /**
     * calling getEmployeeList function after every 5 seconds
     */
    componentDidMount() {
        setInterval(() => {
            // console.log('call api');
            this.getEmployeeList();
        }, 5000);
    }

    /**
     * function to get the communicated employee list
     */
    async getEmployeeList() {
        this.setState({
            loading: true
        })

        const param = `/ShowCommunicatedEmployeeList?empid=${this.userDetail.empid}`;

        await api
            .getApi(param)
            .then((res) => {
                // console.log(res);
                if (res.data.message = 'Ok') {
                    this.setState({
                        employeeList: res.data.response
                    })
                }
                // console.log(this.state.employeeList);
                this.setState({
                    loading: false
                })
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                })
            })
    }

    /**
     * function to send the reciever id to Message Box comp. 
     */
    sendRecieverId = (e, recieverId, recieverName, recieverLogo, recieverStatus) => {
        // console.log(recieverId, recieverName);
        this.props.getId(recieverId, recieverName, recieverLogo, recieverStatus);
    }

    render() {
        return (
            <div className={'messageList'}>
                {
                    this.state.employeeList.length !== 0 ? (
                        <List className={'list'}>
                            {
                                this.state.employeeList.map((emplData) => (
                                    <ListItem className={'listItem'}
                                        onClick={(e) => this.sendRecieverId(e, emplData.sender_empid, emplData.sender_name, emplData.logo, emplData.status)}
                                        key={emplData.index}>
                                        <ListItemAvatar>
                                            <Avatar className={'listAvatar'}>
                                                {emplData.status === "Group" ? <PeopleIcon /> : emplData.logo}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={emplData.sender_name}
                                            secondary={
                                                <Typography component="span" color="textPrimary" className={'chatString'}>
                                                    {emplData.chat.length < 38 ? emplData.chat : emplData.chat.substr(0, 38) + '...'}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    ) : (
                            <h5>No Chat...</h5>
                        )
                }
            </div>
        )
    }
}
