import React from 'react';
import './EmployeeSetting.css';
import { Form, Button } from 'react-bootstrap';
export default class EmployeeSetting extends React.Component {

    render() {
        return (
            <section className={'employeeSetting'}>
                <div className={'row'}>
                    <div className={'col-md-4'}>
                        <h6>Type of Employment and Leave Policy</h6>
                        <div className={'row'}>
                            <div className={'col-9'}>
                                <div className={'employementadd'}>
                                    <Form>
                                        <Form.Control
                                            placeholder="Name of employment"
                                            type="text" />
                                    </Form>
                                </div>
                            </div>
                            <div className={'col-3 text-right'}>
                                <Button className={'addBtn'}> Add</Button>
                            </div>
                        </div>
                        <div class="card mt-3 cardDitailList">
                            <div class="card-body">

                            </div>
                        </div>
                    </div>
                    <div className={'col-md-4'}>
                        <h6>Leave Definition</h6>
                        <div className={'row'}>
                            <div className={'col-9'}>
                                <div className={'employementadd'}>
                                    <Form>
                                        <Form.Control
                                            placeholder="Name of Leave"
                                            type="text" />
                                    </Form>
                                </div>
                            </div>
                            <div className={'col-3 text-right'}>
                                <Button className={'addBtn'}> Add</Button>
                            </div>
                        </div>
                        <div class="card mt-3 cardDitailList">
                            <div class="card-body">

                            </div>
                        </div>
                    </div>
                    <div className={'col-md-4'}>
                        <h6>Leave Policy</h6>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control as="select">
                                        <option>Name of Leave</option> 
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className={'col-12'}>
                                <Form.Group controlId="exampleForm.ControlSelect2">
                                <Form.Control as="select">
                                        <option>Name of employment</option> 
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className={'col-9'}>
                                <div className={'employementadd'}>
                                    <Form>
                                        <Form.Control
                                            placeholder="Days per year"
                                            type="text" />
                                    </Form>
                                </div>
                            </div>
                            <div className={'col-3 text-right'}>
                                <Button className={'addBtn'}> Add</Button>
                            </div>
                        </div>
                        <div class="card mt-3 cardSelect">
                            <div class="card-body">

                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </section>
        )
    }
}
