import React from 'react';
import './chatBox.css';
import userImage from '../../../shared/user.png';
import timeImage from '../../../shared/time.svg';
import messageImage from '../../../shared/message.svg';
export default class ChatBox extends React.Component {
    render(){
        return(
            <div>
                <div className="row"> 
                <div className="col-md-12 p-0">
                    <div className="chatboxcss">
                    <div class="card newTopicCard">
                    <input className="newTopic" type="text" name="name"  align="center"placeholder="  start a new topic" />
                    </div>
                    <div className="figurediv pt-2"> 
                    {/* chat box start */}
                    
                    <figure className="figureClass">
                        <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive userImgClass"
                        />
                        <figcaption className="imageText">
                        <p className="timeText">
                            <img
                            src={timeImage}
                            alt="time"
                            className="timeImage"
                            />
                             6 hours ago
                             
                            <img
                            src={messageImage}
                            alt="time"
                            className="timeImage ml-1"
                            />
                             3 Messages</p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem molestiae recusandae mollitia? Beatae saepe 
                        </figcaption>
                    </figure>
                    
                    <figure className="figureClass">
                        
                    <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive messagerImgClass"
                        />
                        <figcaption className="imageText responseText">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, dicta.
                        </figcaption>
                    </figure>
                    <span className="line"></span>
                    {/* 2 start */}
                    <figure className="figureClass">
                        <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive userImgClass"
                        />
                        <figcaption className="imageText">
                        <p className="timeText">
                            <img
                            src={timeImage}
                            alt="time"
                            className="timeImage"
                            />
                             6 hours ago
                             
                            <img
                            src={messageImage}
                            alt="time"
                            className="timeImage ml-1"
                            />
                             3 Messages</p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem molestiae recusandae mollitia? Beatae saepe 
                        </figcaption>
                    </figure>
                    <span></span>
                    <figure className="figureClass">
                        
                    <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive messagerImgClass"
                        />
                        <figcaption className="imageText responseText">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, dicta.
                        </figcaption>
                    </figure>
                    <span className="line"></span>
                    {/*  2 end */}
                     {/*  3 start */}
                     <figure className="figureClass">
                        <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive userImgClass"
                        />
                        <figcaption className="imageText">
                        <p className="timeText">
                            <img
                            src={timeImage}
                            alt="time"
                            className="timeImage"
                            />
                             6 hours ago
                             
                            <img
                            src={messageImage}
                            alt="time"
                            className="timeImage ml-1"
                            />
                             3 Messages</p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem molestiae recusandae mollitia? Beatae saepe 
                        </figcaption>
                    </figure>
                    
                    <figure className="figureClass">
                        
                    <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive messagerImgClass"
                        />
                        <figcaption className="imageText responseText">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, dicta.
                        </figcaption>
                    </figure>
                    {/*  3 end. */}
                    <span className="line"></span>
                    {/* chat box end.. */}
                    </div>
                    </div>

                    <div className="chatMemeberBox p-2">
                        <figure> 
                    <img
                        src={userImage}
                        alt= "user"
                        className="img-circle img-responsive messagerImgClass"
                        />
                        <figcaption>
                            
                        </figcaption>
                        </figure>
                       
                       
                    </div>
                </div>
                </div> 
            </div>
        );
    }
}// main class end..