import React from 'react';
import './LeaveDetail.css'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { DateRange } from "react-date-range";
import Api from "../../../Providers/Api";
import { DotLoader } from "react-spinners";
import Avatar from '@material-ui/core/Avatar';
import { css } from "@emotion/react";
import close from './close.png';
var date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
var day = new Date().getDay();
const api = new Api();
const loaderCSS = css`
    display: block;
    margin: 0 auto;
    border-color: white;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 55;
`;
var id;
var holidaysJSON = [];
let leaveStatus = [];
// var leaveJSON = []; 

export default class LeaveDetail extends React.Component {
  Editstatus = false;
  constructor(props) {
    super();
    this.ShowHoliday();
    this.edit = this.edit.bind(this);
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || 1,
      showDatePicker: false,
      dayArray: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      monthArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      holidayName: '',
      loadingL: false,
      leaveDetail: {},
      leaveJSON: [],
      leaveStatus: '',
      leaveRequestModal: false,
      showModalData: {},
      remark: ''
    };

    // Bind the handleSelect function already here (not in the render function)
    this.handleSelect = this.handleSelect.bind(this);
    this.ShowEmployeeLeave();
    this.ShowEmployeeLeaveDetails();
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    this.setState({
      activeTab: selectedTab
    });
  }

  /**
   * function to handle the range of the datepicker
   * @param range
   */
  handleSelectDate(range) {
    // console.log(range.startDate._d.getDate() + '-' + (range.startDate._d.getMonth() + 1) + '-' +range.startDate._d.getFullYear());
    date = range.startDate._d.getFullYear() + '-' + (range.startDate._d.getMonth() + 1) + '-' + range.startDate._d.getDate();
    day = range.startDate._d.getDay();
    // console.log(day);
    // console.log(range);
  }

  /**
   * function to show date picker
   */
  ShowDatePicker() {
    this.setState({
      showDatePicker: true
    })
  }

  /**
   * function to hide the datepicker
   */
  HideDatePicker(ev) {
    this.setState({
      showDatePicker: false
    })
  }

  /**
   * function to handle the change in holiday name
   */
  HandleHolidayName(ev) {
    this.setState({
      holidayName: ev.target.value
    })
  }

  /**
   * function to get the holidaylist
   */
  async ShowHoliday() {
    this.setState({
      loadingL: true,
    });
    const param = '/ShowOfficeHoliday';
    await api.getApi(param).then(res => {
      // console.log(res);
      holidaysJSON = res.data.response;
      this.setState({
        loadingL: false
      });
    }).catch(err => {
      console.error(err);
      this.setState({
        loadingL: false
      });
    })
  }
  /**
   * function to add holiday
   */
  AddHoliday() {
    this.setState({
      loadingL: true,
    });
    const param = {
      api: '/AddUpdateOfficeHoliday',
      data: {
        "ohid": id,
        "holidaydate": date,
        "holidayday": this.state.dayArray[day],
        "occassion": this.state.holidayName
      }
    };
    // console.log(param);
    api.postApi(param).then(res => {
      // console.log('response from add holiday api:', res);
      if (res.data.status === 200) {
        this.state.holidayName = '';
        date = '';
        day = new Date().getDay();
        this.setState({
          loadingL: false
        });
        this.ShowHoliday();
      }
    }).catch(err => {
      console.error('error from add holiday api:', err);
      this.setState({
        loadingL: false
      });
    })
  }

  /**
   * function to edit the holiday
   * @param data: data to be sent to the fields
   */
  edit(data) {
    // console.log(data);
    this.setState({
      holidayName: data.occassion
    });
    date = data.holidaydate;
    id = data.ohid;
    day = this.state.dayArray.indexOf(data.holidayday);
    // console.log(day);
    // console.log(date);
    // console.log(this.state.holidayName);
    // console.log(id);
  }
  /**
   * function to render holidays
   * */
  RenderHolidays() {
    const list = holidaysJSON.map((param =>
      <tr>
        <td><i onClick={() => this.edit(param)} className="fas fa-pen"></i></td>
        <td>{param.holidaydate}</td>
        <td>{param.holidayday}</td>
        <td>{param.occassion}</td>
      </tr>
    ));
    return (
      list
    )
  }

  /**
   * function to show the leaves of the employees
   * */
  ShowEmployeeLeave() {
    this.setState({
      loadingL: true,
    });
    const param = '/ShowEmpLeaveRequest?empid=0';
    api.getApi(param).then(res => {
      // console.log('response from leave detail api:', res);
      if (res.data.response === 'No Record Found') {
        this.setState({
          leaveJSON: [],
          loadingL: false,
        });
      } else {
        this.setState({
          leaveJSON: res.data.response,
          loadingL: false,
        });
        // console.log(this.state.leaveJSON); 
        for (let i = 0; i < this.state.leaveJSON.length; i++) {
          this.state.leaveJSON[i].index = i + 1;
        }
      }
      // console.log('leaveJSON', this.state.leaveJSON);
    }).catch(err => {
      this.setState({
        loadingL: false,
      });
      // console.log('error from leave detail api:', err);
    })
  }

  /**
   * function to show the details of the employee leaves
   */
  ShowEmployeeLeaveDetails(eLeaveId) {
    this.setState({
      loadingL: true,
    });
    const param = `/ShowEmpLeaveRequestDetail?empleaveid=${eLeaveId}`;
    api.getApi(param).then(res => {
      // console.log('Response of show employee leave details', res);
      leaveStatus.splice(0, leaveStatus.length);
      if (res.data.response === 'No Record Found') {
        this.setState({
          leaveRequestDetail: [],
          loadingL: false,
          leaveStatus: 'No leave status found for applied days'
        });

      }

      else {
        for (let i = 0; i < res.data.response.length; i++) {

          if (res.data.response[i].fullDay) {
            leaveStatus.push({
              leaveDate: res.data.response[i].leaveDate,
              status: 'Full Day Leave'
            });
          }

          else if (res.data.response[i].firstHalfDay) {
            leaveStatus.push({
              leaveDate: res.data.response[i].leaveDate,
              status: 'First Half Day Leave'
            });
          }

          else if (res.data.response[i].firstHalfDay) {
            leaveStatus.push({
              leaveDate: res.data.response[i].leaveDate,
              status: 'Second Half Day Leave'
            });
          }

        }
        // console.log('leave status', leaveStatus);
        this.setState({
          // leaveRequestDetail: res.data.response,
          loadingL: false,
          leaveStatus: ''
        });

      }
    }).catch(err => {
      this.setState({
        loadingL: false,
      });
      // console.log('Error in show employee leave details', err);
    })
  }

  /**
   * function to render the list of leave
   */
  RenderLeaves() {
    const leave = this.state.leaveJSON.map((param =>
      <tr onClick={() => this.showLeaveDetail(param)}>
        <td>{param.index}</td>
        <td>{param.name}</td>
        <td>3/5</td>
        <td>3/5</td>
      </tr>
    ));
    return (
      leave
    )
  }

  /**
   * function to show the detail of the leave
   * @returns {*}
   */
  showLeaveDetail(data) {
    // console.log(data);
    this.setState({
      leaveDetail: data
    });
    // console.log('detail of the leave', this.state.leaveDetail);
  }

  /**
   * function to approved and reject the leaves
   * @param: param: status
   */
  ApproveRejectLeave(data, status) {
    this.setState({
      loadingL: true,
    });
    const temp = {
      api: '/ResponseToEmpLeaveRequest',
      data: {
        "empid": data.empid,
        "elid": data.elid,
        "response": status,
        "remark": this.state.remark
      }
    };
    api.postApi(temp).then(res => {
      // console.log('response from change leave status api:', res);
      window.alert('leave request status changed successfully');
      this.setState({
        loadingL: false,
        leaveRequestModal: false,
        remark: ''
      });
      this.ShowEmployeeLeave();

    }).catch(err => {
      console.error('error from change leave status api:', err);
      window.alert(err);
      this.setState({
        loadingL: false,
        leaveRequestModal: false,
        remark: ''
      });
    })
  }

  /**
   * function to open the leave modal
   */
  OpenLeaveModal(data) {
    // console.log(data);
    this.setState({
      leaveRequestModal: true,
      showModalData: data
    })
  }

  render() {
    const holidays = this.RenderHolidays();
    const leaves = this.RenderLeaves();
    return (
      <div className="leaveDetail">
        <DotLoader sizeUnit={"px"}
          css={loaderCSS}
          size={150}
          color={'#123abc'}
          loading={this.state.loadingL}
        />
        <Tabs className="tabview" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="kritin Holidays">
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-3">
                    <div class="form-group">
                      <input type="text" class="form-control text-dark" placeholder="dd/mm/yyyy" value={date} onClick={this.ShowDatePicker.bind(this)} />
                      {this.state.showDatePicker &&
                        <div className={'datepicker'}>
                          <DateRange
                            onInit={this.handleSelectDate}
                            onChange={this.handleSelectDate}
                            minDate={new Date()}
                            rangedCalendars={true}
                            calendars={1}
                          />
                          <button className={'btn btn-outline-secondary btn-sm btn-submit float-right w-25'}
                            onClick={this.HideDatePicker.bind(this)}>OK
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <input type="text" className="form-control text-dark" placeholder="Day" value={this.state.dayArray[day]} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Holiday Name" value={this.state.holidayName} onChange={this.HandleHolidayName.bind(this)} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button type="button" className="btn btn-light addbtn" onClick={this.AddHoliday.bind(this)} disabled={!this.state.holidayName}>
                      {this.Editstatus ? 'Update' : 'Add'}
                    </button>
                  </div>
                </div>
                <MDBTable className="table table-bordered table-responsive">
                  <MDBTableHead>
                    <tr>
                      <th className="fixarea">#</th>
                      <th className="fixarea">Date & Month</th>
                      <th className="fixarea">Day</th>
                      <th className="fixarea">Occasion</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {holidays}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title="Employee Leaves">
            <div className="row">
              <div className="col-md-7">
                <p>as on {new Date().getDate() + ' ' + this.state.monthArray[new Date().getMonth()] + ' ' + new Date().getFullYear()}</p>
                <MDBTable className="table table-bordered table-responsive">
                  <MDBTableHead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Casual Leave</th>
                      <th>Sick Leave</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {leaves}
                  </MDBTableBody>
                </MDBTable>
              </div>
              {
                <div className="col-md-5 leaveright">
                  <Tabs className="tabview1 holidayCard" defaultActiveKey="leave">
                    <Tab title="Leave Detail" eventKey="leave">
                      {
                        !this.state.leaveJSON.length &&
                        <div className="leaveCard">
                          <ul className="inline-list">
                            <li>No leaves Yet</li>
                          </ul>
                        </div>
                      }
                      {this.state.leaveJSON.map((data) => (
                        <div>
                          {(data.isapprove === 'Pending') &&
                            <div onClick={() => {
                              this.OpenLeaveModal(data);
                              this.ShowEmployeeLeaveDetails(data.elid);
                            }}>
                              <div className="leaveCard">
                                <ul className="inline-list">
                                  <li>{data.name}</li>
                                  <li>{data.leavefrom}</li>
                                  <li>{data.days} days - {data.leavetype}</li>
                                </ul>
                                <p>{data.reason}</p>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                      }
                    </Tab>
                    <Tab title="History" eventKey="history">
                      {this.state.leaveJSON.map((data) => (
                        <div>
                          {!(data.isapprove === 'Pending') &&
                            <div>
                              <div className="leaveCard">
                                <ul className="inline-list">
                                  <li>{data.name}</li>
                                  <li>{data.leavefrom}</li>
                                  <li>{data.days} days - {data.leavetype}</li>
                                </ul>
                                <p>{data.reason}</p>
                              </div>
                              {
                                data.isapprove === 'Pending' &&
                                <div className="btn-group" role="group" aria-label="Basic example">
                                  <button type="button" onClick={() => this.ApproveRejectLeave('A')} className="btn btn-success"><i className="fas fa-check"></i></button>
                                  <button type="button" onClick={() => this.ApproveRejectLeave('R')} className="btn btn-danger"><i className="fas fa-close"></i></button>
                                </div>
                              }
                              {data.isapprove === 'Approved' &&
                                <button type="button" className="btn btn-success btn-sm">Approved</button>
                              }
                              {data.isapprove === 'Rejected' &&
                                <button type="button" className="btn btn-danger btn-sm">Rejected</button>
                              }
                            </div>
                          }
                        </div>
                      ))
                      }
                    </Tab>
                  </Tabs>
                </div>
              }
            </div>
          </Tab>
        </Tabs>
        <Modal
          show={this.state.leaveRequestModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <section className={'leave_modal'}>
            <button className={'modalCrossButton'} onClick={() => (
              this.setState({
                leaveRequestModal: false
              })
            )}>
              <img src={close} alt={"close"} width="40" height="40" />
            </button>
            <h5>
              {this.state.showModalData.name}
            </h5>
            <p className={'darkcolor'}>
              {this.state.showModalData.emprole_type}
            </p>
            <p className={'mt-3 color-dark'}>
              {this.state.showModalData.name} | {this.state.showModalData.leavefrom} | {this.state.showModalData.days} day - {this.state.showModalData.leavetype}
            </p>
            <DotLoader sizeUnit={"px"}
              css={loaderCSS}
              size={150}
              color={'#123abc'}
              loading={this.state.loadingL}
            />
            {
              !this.state.leaveStatus ? (
                <div className={'row'}>
                  <div className={'col-md-6'}>
                    <ul>
                      <li className={'mt-1 color-dark dates'}>Dates</li>
                      <li className={'mt-1 color-dark'}>Full day</li>
                      <li className={'mt-1 color-dark'}>First Half</li>
                      <li className={'mt-1 color-dark'}>Second Half</li>
                    </ul>
                    {
                      leaveStatus.map((data) => (
                        <ul className={'leaveAvatar'}>
                          <li className={'leaveDate mt-1 color-dark'}>{data.leaveDate}</li>
                          <li className={'fullDay'}>{data.status === 'Full Day Leave' ? (
                            <div className={'filledCircle'}><div className={'filledInnerCircle'}></div></div>
                          ) : (
                              <div className={'emptyCircle'} ></div>
                            )}</li>
                          <li className={'firstHalf'}>{data.status === 'First Half Day Leave' ? (
                            <div className={'filledCircle'} ><div className={'filledInnerCircle'}></div></div>
                          ) : (
                              <div className={'emptyCircle'} ></div>
                            )}</li>
                          <li className={'secondHalf'}>{data.status === 'Second Half Day Leave' ? (
                            <div className={'filledCircle'} ><div className={'filledInnerCircle'}></div></div>
                          ) : (
                              <div className={'emptyCircle'} ></div>
                            )}</li>
                        </ul>
                      ))
                    }
                  </div>
                </div>

              ) : (
                  <p className={'mt-3 color-dark'}>{this.state.leaveStatus}</p>
                )
            }
            <p className={'mt-3 mb-5 darkcolor'}>
              {this.state.showModalData.reason}
            </p>
            <textarea className={'leave_reason mt-2 mb-5 p-2'} name="" id="" cols="30" rows="10" value={this.state.remark} onChange={(ev) => (
              this.setState({
                remark: ev.target.value
              })
            )} placeholder={'please mention reason'}></textarea>
            <div className="float-right mb-1" role="group" aria-label="Basic example">
              <button className={'approve_reject_button btn btn-reject'} disabled={!this.state.remark} onClick={() => (this.ApproveRejectLeave(this.state.showModalData, 'R'))}>Reject </button>
              <button className={'approve_reject_button btn btn-approve'} disabled={!this.state.remark} onClick={() => (this.ApproveRejectLeave(this.state.showModalData, 'A'))}>Approve </button>
            </div>
          </section>
        </Modal>
      </div>
    )
  }
}

