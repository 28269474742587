import React, { useState } from 'react';
import './sidebar.css';
import { BrowserRouter as Link, NavLink } from 'react-router-dom';
import routes from '../../routing';
import { Collapse, Button } from 'react-bootstrap';
import { FormatListBulleted } from '@material-ui/icons';
export default class SideMenu extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <SideBar />
            </div>
        )
    }
}
function SideBar() {
    const [openPersonal, OpenPersonal] = useState(false);
    const [openproject, openProject] = useState(false);
    const [openTopTracker, setOpenTopTracker] = useState(false);
    const [openTask, setOpenTask] = useState(false);

    return (
        <div className="sideNav">
            <ul>
                <li>
                    <p onClick={() => {
                        OpenPersonal(false);
                        openProject(false);
                        setOpenTask(false);
                    }
                    }>
                        <NavLink to={routes[0].path}>{routes[0].name}</NavLink>
                    </p>
                </li>
                <li>
                    <p
                        onClick={() => {
                            OpenPersonal(!openPersonal);
                            openProject(false);
                            setOpenTopTracker(false);
                            setOpenTask(false);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={openPersonal}
                    >
                        Personal
                        <i className={
                            openPersonal ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                        }></i>
                    </p>
                    <Collapse in={openPersonal}>
                        <div id="example-collapse-text">
                            <ul>
                                <li>
                                    <NavLink to={routes[1].path}>{routes[1].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[2].path}>{routes[2].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[3].path}>{routes[3].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[4].path}>{routes[4].name}</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li>
                    <p
                        onClick={() => {
                            openProject(!openproject);
                            OpenPersonal(false);
                            setOpenTopTracker(false);
                            setOpenTask(false);
                        }
                        }
                        aria-controls="example-collapse-text2"
                        aria-expanded={openproject}>
                        Project Management
                        <i className={
                            openproject ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                        }></i>
                    </p>
                    <Collapse in={openproject}>
                        <div id="example-collapse-text">
                            <ul>
                                <li>
                                    <NavLink to={routes[5].path}>{routes[5].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[6].path}>{routes[6].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[7].path}>{routes[7].name}</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li>
                    <div id="example-collapse-text2">
                        <NavLink to={routes[8].path}>{routes[8].name}</NavLink>
                    </div>
                </li>
                <li>
                    <p
                        onClick={() => {
                            openProject(false);
                            OpenPersonal(false);
                            setOpenTopTracker(false);
                            setOpenTask(!openTask);
                        }
                        }
                        aria-controls="example-collapse-text2"
                        aria-expanded={openTask}>
                        Task Management
                        <i className={
                            openTask ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                        }></i>
                    </p>
                    <Collapse in={openTask}>
                        <div id="example-collapse-text">
                            <ul>
                                <li>
                                    <NavLink to={routes[11].path}>{routes[11].name}</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </li>
                <li>
                <p
                        onClick={() => {
                            setOpenTopTracker(!openTopTracker);
                            openProject(false);
                            OpenPersonal(false);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={openTopTracker}
                    >
                        Top-Tracker
                        <i className={
                            openTopTracker ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                        }></i>
                    </p>
                    <Collapse in={openTopTracker}>
                        <div id="example-collapse-text">
                            <ul>
                                <li>
                                    <NavLink to={routes[9].path}>{routes[9].name}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={routes[10].path}>{routes[10].name}</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                    
                </li>
            </ul>
        </div>
    )
}
