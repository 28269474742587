import React from 'react';
import './AttendanceSheet.css'
import { Tab, Tabs, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Api from "../../../Providers/Api";
import { DotLoader } from "react-spinners";
import {JsonToExcel } from 'react-json-excel';
import { css } from "@emotion/react";
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const api = new Api();
var currentDayList = [];
var GlobalCurrentDayList = [];
var weekList = [];

const loaderCSS = css`
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 5;
`;
const className = 'class-name-for-style',
  filename = 'Excel-file',
  fields = {
    "index": "Index",
    "guid": "GUID"
  },
  style = {
    padding: "5px"
  },
 
 
  text = "Export";
export default class extends React.Component {
    date_for_week_data;
    date;
    currentDate;
    day;
    weekdate;
    month  = new Date().getMonth();
    MonthDataMonth = new Date().getMonth();
    year = new Date().getFullYear();
    EmployeeMonthname = [];
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';
    constructor(props) {
        super();
        this.state = {
            dayArray: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            // Takes active tab from props if it is defined there
            activeTab: props.activeTab || 1,
            loading: true,
            searchText: '',
            monthdata: [],
            globalmonthdata: [],
            employeeEmpidname: [],
            EmployeeWeekname: [],
            isExport: false,
            monthdataexport:[]
        };

        
        this.exportToCSV = this.exportToCSV.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.day = new Date().getDay();
        this.currentDate = new Date().getDate();
        if (this.currentDate < 10) {
            if (new Date().getMonth()+1 > 9) {
                this.date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' +  '0' + this.currentDate;
            } else {
                this.date = new Date().getFullYear() + '-' + '0' + (new Date().getMonth() + 1) + '-' +  '0' + this.currentDate;
            }
        } else {
            if (new Date().getMonth()+1 > 9) {
                this.date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + this.currentDate;
            } else {
                this.date = new Date().getFullYear() + '-' + '0' + (new Date().getMonth() + 1) + '-' + this.currentDate;
            }
        }
        this.GetCurrentDayList(this.date);
        this.GetCurrentDate();
        this.MonthDataMonth = this.MonthDataMonth + 1;
        this.MonthData();
        this.getMonday(new Date());
    }
    
    
    async  exportToCSV  ()  {
        this.setState({
            loading: true
        });
        
        const param = '/EmpTimeHistoryMonthdownload?date=' +  this.MonthDataMonth;
         await api.getApi(param).then(res => {
            console.log('response from month api', res);
            // console.log('json string',JSON.stringify(res.data.response));
            // monthDayList = res.data.response;
            this.setState({
                monthdataexport : res.data.response,
            });
          
             console.log('month day list ', this.state.monthdataexport)
            // console.log(this.state.employeeEmpidname);
            // this.EmployeeMonthname = this.state.employeeEmpidname;
        }).catch(err => {
            console.error(err);
            window.alert(JSON.stringify(err));
            this.setState({
                loading: false
            });
        });

         console.log("this.monthdata",this.state.monthdataexport);
        //  let exportData = JSON.stringify(this.state.monthdataexport);
        //  console.log('exportData',exportData);

          let fileName ="attendence"
         const ws = XLSX.utils.json_to_sheet (this.state.monthdataexport);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(data, fileName + '.xlsx');

        this.setState({
            loading: false
        });
    }
    /**
     * function to handle the text of the search
     */
    HandleSearch(ev) {
        // console.log('value from search bar', ev.target.value);
        this.setState({
            searchText: ev.target.value
        });
        const val = ev.target.value;
        if (val && val.trim() !== '') {
            currentDayList = GlobalCurrentDayList.filter(item => {
                return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
            });
        } else {
            currentDayList = GlobalCurrentDayList;
        }
    }

    /**
     * function to get the data of one day
     */
    async GetCurrentDayList(date) {
        const param = '/ShowEmpTimeHistoryDaily?date=' + date;
        await api.getApi(param).then(res => {
            // console.log('response from current day list api:', res);
            currentDayList = res.data.response;
            GlobalCurrentDayList = currentDayList;
            this.setState({
                loading: false
            });
        }).catch(err => {
            console.error('error from current day list APi', err);
        })
    }
    handleSelect(selectedTab) {
        // The active tab must be set into the state so that
        // the Tabs component knows about the change and re-renders.
        this.setState({
            activeTab: selectedTab
        });
        console.log('activeTab',this.state.activeTab)
         if(this.state.activeTab === 3){
             console.log('ok')
            this.setState({
                isExport: true
            });
         }
    }

    /**
     * function to show the data of previous and next day
     */
    ShowNext(ev) {
        this.currentDate = this.currentDate + 1;
        if (this.currentDate >= 28 && this.currentDate < 30) {
            if (this.month === 1 ) {
                if (new Date().getFullYear() % 4) {
                    this.currentDate = 29
                } else {
                    this.currentDate = 1;
                    this.month = this.month + 1;
                }
            }
            // this.month = this.month + 1;
        } else if (this.currentDate >= 30) {
            if (this.month < 6) {
                if (this.month % 2 === 0 ) {
                    this.currentDate = 1;
                    this.month = this.month + 1;
                } else {
                    // this.currentDate = this.currentDate + 1;
                }
            } else {
                if (this.month === 11) {
                    this.month = 0;
                } else {
                    this.month = this.month + 1;
                }
                if (this.month % 2 === 0) {
                    this.currentDate = 1;
                } else {
                    this.currentDate = 1;
                }
            }
        }
        if (this.month === 11) {
            this.year = this.year + 1;
        }
        if (this.currentDate < 10) {
            if (this.month + 1 > 9) {
                this.date = this.year + '-' + (this.month + 1) + '-0' + this.currentDate;
            } else {
                this.date = this.year + '-' + '0' + (this.month + 1) + '-0' + this.currentDate;
            }
        } else {
            if (this.month + 1 > 9) {
                this.date = this.year + '-' + (this.month + 1) + '-' + this.currentDate;
            } else {
                this.date = this.year + '-' + '0' + (this.month + 1) + '-' + this.currentDate;
            }
        }
        // this.date = new Date().getFullYear() + '-' + (this.month + 1)+ '-' + this.currentDate;
        if (this.day === 6 ) {
            this.day = 0;
        } else {
            this.day = this.day + 1;
        }
        // console.log(this.day);
        this.setState({
            loading: true
        });
        this.GetCurrentDayList(this.date)
    }
    /**
     * function to show the data of previous and next day
     */
    ShowPrev(ev) {
        this.currentDate = this.currentDate -1;
        if (this.currentDate < 1 ) {
            if (this.month === 2 ) {
                if (new Date().getFullYear() % 4) {
                    this.currentDate = 29
                } else {
                    this.currentDate = 28
                }
            } else if (this.month < 6) {
                if (this.month % 2 === 0) {
                    this.currentDate = 31;
                } else {
                    this.currentDate = 30;
                }
            } else {
                if (this.month % 2 === 0 ) {
                    this.currentDate = 31;
                } else {
                    this.currentDate = 30;
                }
            }
            if (this.month === 0) {
                this.month = 11;
                this.year = this.year-1;
            } else {
                this.month = this.month - 1;
            }
        }
        if (this.currentDate < 10) {
            if (this.month + 1 > 9) {
                this.date = this.year + '-' + (this.month + 1) + '-0' + this.currentDate;
            } else {
                this.date = this.year + '-' + '0' + (this.month + 1) + '-0' + this.currentDate;
            }
        } else {
            if (this.month + 1 > 9) {
                this.date = this.year + '-' + (this.month + 1) + '-' + this.currentDate;
            } else {
                this.date = this.year + '-' + '0' + (this.month + 1) + '-' + this.currentDate;
            }
        }
        if (this.day === 0) {
            this.day = 6;
        } else {
            this.day = this.day - 1;
        }
        // console.log(this.day);
        this.setState({
            loading: true
        });
        // console.log(this.date);
        this.GetCurrentDayList(this.date)
    }

    /**
     * function to get the start date of current week
     */
    GetCurrentDate() {
        const day = new Date().getDay();
        this.weekdate =  (new Date().getDate() - day + 1);
        // console.log(this.weekdate);
    }

    /**
     * function to show next or previous
     */
    PrevWeek() {
        if (this.weekdate <= 7) {
            this.weekdate = this.weekdate - 7;
            const temp = this.weekdate;
            // console.log(temp);
            this.month = this.month - 1;
            if (this.month === 1 ) {
                if ((new Date().getFullYear() % 4) === 0 ) {
                    this.weekdate = 29;
                }  else {
                    this.weekdate = 28;
                }
            } else if (this.month % 2 !== 0) {
                this.weekdate = 31
            } else {
                this.weekdate = 30
            }
            // console.log(this.weekdate);
            this.weekdate = this.weekdate - temp;
        } else {
            this.weekdate = this.weekdate - 7;
        }
        // console.log(this.weekdate);
    }

    /**
     * function to show the next week
     */
    NextWeek() {

    }

    /**
     * function to get the first day of week
     */
    getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
        // console.log(new Date(d.setDate(diff)).getMonth() + 1);
        const month = new Date(d.setDate(diff)).getMonth();
        this.date_for_week_data =  new Date(d.setDate(diff)).getFullYear() + '-' + (month + 1) + '-' + new Date(d.setDate(diff)).getDate();
        this.WeekData(this.date_for_week_data);
    }

    /**
     * function to show the week data
     */
    async WeekData(date) {
        // const param = '/ShowEmpTimeHistoryWeekly?date='+ date;
        const param = '/ShowEmpTimeHistoryWeekly_test?date=' + date;
        await  api.getApi(param).then(res => {
            // console.log('response from week api', res);
            if (res.data.status === 200) {
                weekList = res.data.response;
                this.setState({
                    EmployeeWeekname: weekList[0].employeeEmpidname
                });
                // console.log('week day list ', weekList);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    /**
     * function to show the month data
     */
    async MonthData() {
        console.log('ok',this.MonthDataMonth);
        // const {isExport} = this.state;
        this.setState({
            loading: true,
            isExport: true
        });
        console.log('isexport',this.state.isExport);
        if (this.MonthDataMonth < 10) {
            this.MonthDataMonth = '0' + this.MonthDataMonth;
        }
        const param = '/ShowEmpTimeHistoryWeeklyMonthly?date=' +  this.MonthDataMonth + '&type=Monthly';
        await  api.getApi(param).then(res => {
            console.log('response from month api', res);
            console.log('json string',JSON.stringify(res.data.response));
            // monthDayList = res.data.response;
            this.setState({
                globalmonthdata: res.data.response,
                monthdata: res.data.response,
                employeeEmpidname: res.data.response[0].employeeEmpidname
            });
            this.setState({
                loading: false
            });
             console.log('month day list ', this.state.monthdata)
            // console.log(this.state.employeeEmpidname);
            this.EmployeeMonthname = this.state.employeeEmpidname;
        }).catch(err => {
            console.error(err);
            window.alert(JSON.stringify(err));
            this.setState({
                loading: false
            });
        });
    }

    /**
     * function to show the next of month
     */
    MonthNext() {
        if (parseInt(this.MonthDataMonth) === 12) {
            this.MonthDataMonth = 1;
        } else {
            this.MonthDataMonth = parseInt(this.MonthDataMonth) + 1;
        }
        this.MonthData();
    }

    /**
     * function to show prev of month
     */
    MonthPrev(){
        // console.log(this.MonthDataMonth);
        if (parseInt(this.MonthDataMonth) === 1) {
            this.MonthDataMonth = 12;
        } else {
            this.MonthDataMonth = parseInt(this.MonthDataMonth) - 1;
        }
        // console.log(this.MonthDataMonth);
        this.MonthData();
    }

    render() {
        return (
            <div className="attebdencewrapper">
                <div className="searchbox">
                    <form>
                        <input type="search" placeholder="Search" value={this.state.searchText} onChange={this.HandleSearch.bind(this)} />
                    </form>
                </div>
                <Tabs className="myClass" activeKey={this.state.activeTab} onSelect={this.handleSelect} defaultActiveKey="1">
                    <Tab eventKey={1} title="Day">
                        <div className="paginationBotton">
                            <ul className="pagination pagination-sm">
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.ShowPrev.bind(this)}><span class="fas fa-angle-left"></span>Prev</a></li>
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.ShowNext.bind(this)}>Next <span class="fas fa-angle-right"></span></a></li>
                            </ul>
                        </div>
                        <MDBTable className="table-bordered table-responsive">
                            <MDBTableHead>
                                <tr>
                                    <th className="fixed">Date & Day</th>
                                    <RenderEmployeeList />
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr>
                                    <td className="text-center innerConntent fixed">
                                        <tr>
                                            <td>{this.state.dayArray[this.day]}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{this.date}</strong></td>
                                        </tr>
                                    </td>
                                    <RenderTime />
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </Tab>
                    <Tab eventKey={2} title="Week">
                        <div className="paginationBotton">
                            <ul className="pagination pagination-sm">
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.PrevWeek.bind(this)}> <span class="fas fa-angle-left"></span>Prev</a></li>
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.NextWeek.bind(this)}>Next <span class="fas fa-angle-right"></span></a></li>
                            </ul>
                        </div>
                        <MDBTable className="table-bordered table-responsive week">
                            <MDBTableHead >
                                <tr>
                                    <th className="fixed">Date & Day</th>
                                    {
                                        this.state.EmployeeWeekname.map((param) => (
                                            <th>{param.name}</th>
                                        ))
                                    }
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                { weekList.map((param) => (
                                    <tr>
                                        <td className="text-center innerConntent fixed">
                                            <tr>
                                                <td>{param.day}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>{param.date}</strong></td>
                                            </tr>
                                        </td>
                                        { param.employeeEmpidname.map((Data) => (
                                            <td className={Data.name === 'Admin' ? "innerConntent text-center d-none" : "innerConntent text-center"}>
                                                <tr>
                                                    <td>in</td>
                                                    <td>out</td>
                                                </tr>
                                                {Data.eTimeinout.map((time) => (
                                                    <tr>
                                                        <td>{time.timein}</td>
                                                        <td>{time.timeout}</td>
                                                    </tr>
                                                ))
                                                }
                                            </td>
                                        ))
                                        }
                                    </tr>
                                ))
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </Tab>
                    <Tab eventKey={3} title="Month">
                        <div className="paginationBotton">
                            <ul className="pagination pagination-sm">
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.MonthPrev.bind(this)}><span class="fas fa-angle-left"></span>Prev</a></li>
                                <li className="page-item"><a className="page-link color-primary"
                                                             onClick={this.MonthNext.bind(this)}>Next<span class="fas fa-angle-right"></span></a></li>
                            </ul>
                        </div>
                        <MDBTable className="table-bordered table-responsive montht">
                            
                            <MDBTableHead>
                                <tr>
                                    <th className="fixed">Date & Day</th>
                                    {
                                        this.state.employeeEmpidname.map((param) => (
                                            <th>{param.name}</th>
                                        ))
                                    }
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                { this.state.monthdata.map((param) => (
                                    <tr>
                                        <td className="text-center innerConntent fixed">
                                            <tr>
                                                <td>{param.day}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>{param.date}</strong></td>
                                            </tr>
                                        </td>
                                        { param.employeeEmpidname.map((Data) => (
                                            <td className={Data.name === 'Admin' ? "innerConntent text-center d-none" : "innerConntent text-center"}>
                                                <tr>
                                                    <td>in</td>
                                                    <td>out</td>
                                                </tr>
                                                {Data.eTimeinout.map((time) => (
                                                    <tr>
                                                        <td>{time.timein}</td>
                                                        <td>{time.timeout}</td>
                                                    </tr>
                                                ))
                                                }
                                            </td>
                                        ))
                                        }
                                    </tr>
                                ))
                                }
                            </MDBTableBody>
                        </MDBTable>
                        <br/>
                        <button  onClick={this.exportToCSV}> Export!</button>
                       
                    </Tab>
                    {this.state.isExport && <box/>}
                </Tabs>
                <DotLoader sizeUnit={"px"}
                           css={loaderCSS}
                           size={150}
                           color={'#123abc'}
                           loading={this.state.loading}
                />
                {/* <Button variant="success" onClick={exportToCSV()}>Export</Button> */}
                {/* {this.state.isExport?
                    <div>
                   
                    </div>
                :null} */}
            </div>
        )
    }
}




function RenderEmployeeList() {
    // console.log(currentDayList);
    if (currentDayList.length > 0) {
        var list = currentDayList.map((param) =>
            <th className={param.name === 'Admin' ? 'd-none' : ''}>{param.name}</th>
        );
    } else {
        var list = <th>No Data for Today</th>;
    }
    return (
        list
    )
}

function RenderTime() {
    if (currentDayList.length > 0) {
        var data = currentDayList.map((param) =>
            <OverlayTrigger placement={'left'} overlay={
                <Tooltip id={'tooltip'}>
                    <h6>Full Information</h6>
                    <AllTimeInout list={param.timeinout} />
                </Tooltip>
            }>
                <td className={param.name === 'Admin' ? "innerConntent text-center d-none" : "innerConntent text-center"}>
                    <tr>
                        <td>in</td>
                        <td>out</td>
                    </tr>
                    <tr>
                        <td><strong>{param.timeinout[param.timeinout.length - 1].timein}</strong></td>
                        <td><strong>{param.timeinout[0].timeout}</strong></td>
                    </tr>
                </td>
            </OverlayTrigger>
        );
    } else {
        var data =
            <td className="innerConntent text-center">
                <tr>
                    <td>in</td>
                    <td>out</td>
                </tr>
                <tr>
                    <td><strong>No data</strong></td>
                    <td><strong>No data</strong></td>
                </tr>
            </td>
    }
    return (
        data
    )
}

function AllTimeInout(props) {
    const list = props.list;
    // console.log(props.list);
    // console.log(list);
    const all = list.map((param) =>
        <div>
            <p>{param.timein} IN  {param.timeout} OUT</p>
        </div>
    );
    return (
        all
    )
}

