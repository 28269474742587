import React from 'react';
import './userJournal.css';
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import Timekeeper from 'react-timekeeper';
import Api from "../../../Providers/Api";
import Modal from "react-modal";
import {BounceLoader} from "react-spinners";
import {css} from "@emotion/react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import close from "../../Personal/LeaveDetail/close.png";
var api = new Api();
const loaderCSS = css`
    display: flex;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    transform: 'translate(-50%, -50%) !important',
`;
const customStyles2 = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        backgroundColor: '#dad9e9',
        width: '600px',
    }
};
export default class UserJournal extends React.Component{
    employeeDetail = {};
    MonthArray =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    dayArray =  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    constructor() {
        super();
        this.state = {
            startTimePicker: false,
            startTime: '',
            endTimePicker: false,
            endTime: '',
            startTimeCheckObj: {
                hour: 0,
                minute: 0
            },
            startTimeMeridian: 'AM',
            remark: '',
            modalIsOpen: false,
            projectModal: false,
            journalJson: [
            ],
            projectarray: [],
            date: new Date(),
            showJournal: [],
            journalHistory: [],
            currentDate: '',
            addJournalStatus: true,
            day: this.dayArray[new Date().getDay()],
            showingDate: this.MonthArray[new Date().getMonth()] + ',' + new Date().getDate()
        };
        this.employeeDetail = JSON.parse(localStorage.getItem('userdetail'));
        this.JournalHistory();
        this.currentDate();
    }

    /**
     * function to set the current date
     */
    currentDate() {
    //     let year, date, month;
    //     let cDate = new Date();
    //     year = cDate.getFullYear();
    //     month = cDate.getMonth() + 1;
    //     date = cDate.getDate();
    //     if(month < 10){
    //         month = '0' + month;
    //         // console.log(month);
    //     }
    //    if(date < 10){
    //        date = '0' + date;
    //     //    console.log(date); 
    //    }
    //    this.state.currentDate = year + '-' + month + '-' + date;  
    //    console.log('Current date', this.state.currentDate);
        
        if ((new Date().getMonth() + 1) < 10) {
        //  console.log(new Date().getMonth());
            if (new Date().getDate() > 9) {
                this.state.currentDate = new Date().getFullYear() + '-0' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
            } else {
                this.state.currentDate = new Date().getFullYear() + '-0' + (new Date().getMonth() + 1) + '-0' + new Date().getDate()
            }
        } else {
            if (new Date().getDate() > 9) {
                this.state.currentDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
            } else {
                this.state.currentDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-0' + new Date().getDate()
            }
        }

        console.log(this.state.currentDate);
        this.ShowJournal(this.state.currentDate);
    }

    /**
     * function to open and close the time pickers of the form
     */
    openTimePicker(value, pickername, type) {
        if (pickername === 'startTimePicker') {
            this.setState({
                startTimePicker: value,
                endTimePicker: false,
            });
        } else if (pickername === 'endTimePicker') {
            this.setState({
                endTimePicker: value,
                startTimePicker: false,
            });
        } else {
            for (let i = 0; i < this.state.showJournal.length; i++ ) {
                if (pickername === (i+1)) {
                    if (type === 1) {
                        this.state.showJournal[i].startPicker = value;
                    }
                    else {
                        this.state.showJournal[i].endPicker = value;
                    }
                    // console.log(this.state.showJournal);
                }
            }
        }
        // console.log(this.state);
    }

    /***
     * function to change the value of the picker
     */
    saveTime(ev, pickername, type) {
        // console.log(ev);
        const currentTime = {
            hour: new Date().getHours(),
            min: new Date().getMinutes(),
            currentDay: new Date().getDate()
        };
        // console.log(currentTime);
        if (pickername === 'startTimePicker') {
            this.setState({
                startTime: ev.formatted24,
                startTimeCheckObj: {
                    hour: ev.hour,
                    minute: ev.minute
                },
                startTimeMeridian: ev.meridiem
            });  
            // console.log('startTimePicker', this.state.startTimeCheckObj);
        } else if (pickername === 'endTimePicker'){
            // console.log(this.state.showingDate);
            let showingdate = parseInt(this.state.showingDate[4] + this.state.showingDate[5]);
            // console.log('startTimePicker', this.state.startTimeCheckObj);
            // console.log((new Date().getDate()) === showingdate);
            if ((new Date().getDate()) === showingdate) {
                // console.log(this.state.showingDate);
                if (ev.hour > currentTime.hour) {
                    window.alert('you cannot select future time hour');
                } else if (ev.hour === currentTime.hour) {
                    // console.log(ev.minute);
                    // console.log(currentTime.min);
                    if (currentTime.min < ev.minute) {
                        window.alert('Please select minute time below ' + currentTime.min);
                    } else if (currentTime.min === ev.minute) {
                        window.alert('Please select minute time below ' + currentTime.min);
                    } else {
                        // console.log(this.state.startTimeCheckObj);
                        if (this.state.startTimeCheckObj.hour > ev.hour) {
                            window.alert('you cannot select future time');
                        } else if (this.state.startTimeCheckObj.hour === ev.hour) {
                            if (this.state.startTimeCheckObj.minute < ev.minute) {
                                this.setState({
                                    endTime: ev.formatted24
                                })
                            } else if(this.state.startTimeCheckObj.minute === ev.minute) {
                                window.alert('please select a  min time below ' + this.state.startTimeCheckObj.minute);
                            } else {
                                window.alert('please select valid time');
                            }
                        } else {
                            this.setState({
                                endTime: ev.formatted24
                            })
                        }
                    }
                } else {
                    // console.log(this.state.startTimeCheckObj);
                    if (this.state.startTimeCheckObj.hour > ev.hour) {
                        window.alert('please select a valid hour time ');
                    } else if (this.state.startTimeCheckObj.hour === ev.hour) {
                        if (this.state.startTimeCheckObj.minute < ev.minute) {
                            this.setState({
                                endTime: ev.formatted24
                            })
                        } else if(this.state.startTimeCheckObj.minute === ev.minute) {
                            window.alert('please select a  min time below ' + this.state.startTimeCheckObj.minute);
                        } else {
                            window.alert('please select valid time  ');
                        }
                    } else {
                        this.setState({
                            endTime: ev.formatted24
                        })
                    }
                }
            } else {
                if (this.state.startTimeCheckObj.hour > ev.hour) {
                    window.alert('you cannot select future time');
                } else if (this.state.startTimeCheckObj.hour === ev.hour) {
                    if (this.state.startTimeCheckObj.minute < ev.minute) {
                        this.setState({
                            endTime: ev.formatted24
                        })
                    } else if(this.state.startTimeCheckObj.minute === ev.minute) {
                        window.alert('please select a  min time below ' + this.state.startTimeCheckObj.minute);
                    } else {
                        window.alert('please select valid time');
                    }
                } else {
                    this.setState({
                        endTime: ev.formatted24
                    })
                }
            }
        } else {
            for (let i = 0; i < this.state.showJournal.length; i++ ) {
                if (pickername === (i+1)) {
                    if (type === 1) {
                        const temp_data  = this.state.showJournal[i];
                        temp_data.start_at = ev.formatted12;
                        // console.log(temp_data);
                        this.state.showJournal.splice(i, 1, temp_data);
                        // console.log(this.state.showJournal);
                    } else {
                        const temp_data  = this.state.showJournal[i];
                        temp_data.end_at = ev.formatted12;
                        // console.log(temp_data);
                        this.state.showJournal.splice(i, 1, temp_data);
                        // console.log(this.state.showJournal);
                    }
                    this.AddJournal(ev, 'update', this.state.showJournal[i]);
                }
            }
        }
    }

    /**
     * function to listen the key
     */
    KeyListner(ev, type, data) {
        // console.log(ev.shiftKey);
        if (ev.shiftKey) {
            if (ev.keyCode === 13) {
                // console.log('enter with shift');
            } else if (ev.keyCode === 50){
                // console.log('getting the projects');
                if (data === 'nodata') {
                    this.GetProjects();
                    localStorage.setItem('add_project_data', JSON.stringify({remark: this.state.remark, type: 'add'}));
                } else {
                    this.GetProjects();
                    localStorage.setItem('add_project_data', JSON.stringify(data));
                }
            } else {

            }
        } else {
            // console.log(ev.keyCode);
            if (ev.keyCode === 13) {
                // console.log('enter only');
                if (type === 'edit') {
                    this.AddJournal('ev', 'edit', data);
                } else {
                    this.AddJournal('ev', 'add', 'no data');
                }
            } else {

            }
        }
    }

    /**
     * functions to get the projects
     */
    GetProjects() {
        this.setState({
            modalIsOpen: true,
            projectModal: true
        });
        if (localStorage.getItem('projectJSON')) {
            if (JSON.parse(localStorage.getItem('projectJSON')).length) {
                this.setState({
                    projectarray: JSON.parse(localStorage.getItem('projectJSON')),
                    modalIsOpen: false,
                });
            }
        } else {
            const param = '/ShowProjects';
            api.getApi(param).then(res => {
                // console.log(res);
                this.setState({
                    modalIsOpen: false,
                });
                if (res.data.status === 200) {
                    this.setState({
                        projectarray: res.data.response,
                    });
                    // console.log(this.state.projectarray);
                    localStorage.setItem('projectJSON', JSON.stringify(this.state.projectarray));
                } else {
                    this.setState({
                        modalIsOpen: false,
                    });
                    window.alert(JSON.stringify(res));
                }
            }).catch(err => {
                // console.log(err);
                this.setState({
                    modalIsOpen: false,
                });
                window.alert(JSON.stringify(err));
            });
        }
    }

    /**
     * function to show the journal of the user
     */
    ShowJournal(date) {
        this.setState({
            modalIsOpen: true
        });
        const param = '/ShowEmpJournal_OfaDate?empid=' + this.employeeDetail.empid + '&date=' + date;
        api.getApi(param).then(res => {
            this.setState({
                modalIsOpen: false,
                remark: '',
                endTime: ''
            });
            // console.log(res.data);
            if (res.data.status === 200) {
                const data = [];
                for (let i = 0 ; i < res.data.response.length; i++) {
                    const tempObj = res.data.response[i];
                    tempObj.index = i+1;
                    tempObj.startPicker = false;
                    tempObj.endPicker = false;
                    data.push(tempObj)
                }
                if (data.length === 0) {
                    this.getTodayCheckinTime();
                    this.setState({
                        showJournal: []
                    });
                } else {
                    this.setState({
                        showJournal: data,
                        startTime: data[data.length - 1 ].end_at,
                    });
                    if (data[data.length -1].end_at.length === 5) {
                        this.setState({
                            startTimeCheckObj: {
                                hour: parseInt((data[data.length - 1].end_at[0] + data[data.length - 1 ].end_at[1])),
                                minute: parseInt((data[data.length - 1].end_at[3] + data[data.length - 1 ].end_at[4]))
                            },
                        });
                    } else {
                        this.setState({
                            startTimeCheckObj: {
                                hour: parseInt((data[data.length - 1].end_at[0])),
                                minute: parseInt((data[data.length - 1].end_at[2] + data[data.length - 1 ].end_at[3]))
                            },
                        });
                    }
                }
                // console.log(this.state.startTimeCheckObj);
            } else {
                window.alert(JSON.stringify(res));
            }
            // console.log(this.state.showJournal);
        }).catch(err => {
            // console.log(err);
            this.setState({
                modalIsOpen: false
            });
        });
    }

    /**
     * function to add journal
     */
    AddJournal(ev, type, data) {
        // console.log(this.state.currentDate);
        this.setState({
            modalIsOpen: true,
        });
        const param = {
            api: '/AddEmployeeJournal',

        };
        if (type === 'add') {
            this.setState({
                addJournalStatus: false
            });
            param.data = {
                jid: 0,
                empid: this.employeeDetail.empid,
                start_at: this.state.startTime.toUpperCase(),
                end_at: this.state.endTime.toUpperCase(),
                remark: this.state.remark,
                journal_date: this.state.currentDate
            }
        } else {
            param.data = {
                jid: data.jid,
                empid: this.employeeDetail.empid,
                start_at: data.start_at.toUpperCase(),
                end_at: data.end_at.toUpperCase(),
                remark: data.remark,
                journal_date: this.state.currentDate
            }
        }
        api.postApi(param).then(res => {
            this.setState({
                addJournalStatus: true
            });
            // console.log(res);
            if (res.data.status === 200) {
                if (res.data.message === 'Journal Can Not Add For This Date Now') {
                    window.alert('Journal Can Not Add For This Date Now');
                } else if (res.data.message === 'Journal Can Not Update Now') {
                    window.alert('Journal Can Not Update Now');
                }
                else {
                    this.setState({
                        startTime: '',
                        endTime: '',
                        remark: ''
                    });
                    this.ShowJournal(this.state.currentDate);
                }
            } else {
                window.alert(JSON.stringify(res));
            }
            this.setState({
                modalIsOpen: false,
            });
        }).catch(err => {
            // console.log(err);
            window.alert(JSON.stringify(err));
            this.setState({
                modalIsOpen: false,
            });
        });
    }

    /**
     * function to edit the remark
     */
    editRemark(ev, data) {
        // console.log('value', ev.target.value);
        // console.log(data);
        // console.log(data.index);
        // console.log(data);
        var temp_data = data;
        // console.log(temp_data);
        temp_data.remark = ev.target.value;
        this.setState({
            'showJournal[data.index - 1]': temp_data
        })
    }

    /**
     * function to add the project
     */
    addProject(data) {
        // console.log(data);
        this.setState({
            projectModal: false
        });
        const tempdata = JSON.parse(localStorage.getItem('add_project_data'));
        if (tempdata.type === 'add') {
            // console.log(tempdata.type);
            let temp_data = tempdata;
            temp_data.remark = temp_data.remark + ' @' + data.name + ' ';
            // console.log(temp_data.remark);
            this.setState({
                remark: temp_data.remark
            })
        } else {
            let temp_data = tempdata;
            temp_data.remark = temp_data.remark + ' @' + data.name + ' ';
            // console.log(temp_data.remark);
            this.state.showJournal.splice((tempdata.index -1), 1, temp_data);
            // console.log(this.state.showJournal);
        }
    }

    /**
     * function to encode the string and add css to special
     * @param str: string
     */
    encodeStr(str) {
        return str.replace(/(\@\S+)/g, (key) => `<span class="class-at">${key}</span>`).replace(/(\#\S+)/g, (key) => `<span class="class-hash">${key}</span>`);
    }

    /**
     * function to show the history date of the journal
     */
    JournalHistory() {
        let month = new Date().getMonth();
        if (month < 10) {
            month = '0' +  month;
        }
        let number = Number(month) + 1;
        let totalval = '0' + number;
        const param = '/showDailyDatesOfMonth?month=' + new Date().getFullYear() + '-' + totalval;
        api.getApi(param).then(res => {
            // console.log(res);
            if (res.data.status === 200) {
                this.setState({
                    journalHistory: res.data.response
                })
            } else {
                window.alert(JSON.stringify(res));
            }
        }).catch(err => {
            // console.error(err);
            window.alert(JSON.stringify(err));
        })
    }
    /**
     * function to show the todays checkin time
     * */
    getTodayCheckinTime() {
        const param = '/showEmployeeTodaysTimeIn?empid=' + this.employeeDetail.empid + '&date=' + this.state.currentDate;
        // console.log(param);
        api.getApi(param).then(res => {
            // console.log(param);
            // console.log(res.data.response);
            if (res.data.status === 200) {
                if (res.data.message === 'Employee Did Not Checked In Today') {
                    window.alert('You haven\'t checked in today please checkin first to add journal');
                    this.setState({
                        startTimeCheckObj: {
                            hour: '',
                            minute: '',
                        },
                        startTime: ''
                    });
                } else {
                    this.setState({
                        startTimeCheckObj: {
                            hour: parseInt(res.data.response[0] + res.data.response[1]),
                            minute: parseInt(res.data.response[3] + res.data.response[4]),
                        },
                        startTime: res.data.response
                    });
                    // console.log(this.state);
                }
            } else {
                window.alert(JSON.stringify(res));
            }
        }).catch(err => {
            console.error(err);
            window.alert(JSON.stringify(err));
        })
    }

    render() {
        return (
            <div className={'row userjournal'}>
                <div className={'col-md-8'}>
                    <div className={'journalSec'}>
                        <div className={'user_journal_header'}>
                            <h6>
                                <span className={'font-weight-bold'}> Journal </span>
                                <span className={'vline h2 text-muted'}> | </span>
                                { this.state.showingDate} <span className={'vline h2 text-muted'}> | </span>
                                {this.state.day}
                            </h6>
                            <OverlayTrigger placement={'bottom'}
                                            overlay={
                                                <Tooltip id={'journal_tooltip'}>
                                                    <div className={'journaltooltip'}>
                                                        <h5 className={'mt-2 text-center'}>Instructions</h5>
                                                        <ul className={'text-left tooltipdata'}>
                                                            <li> <span>-</span> <span>You can not select starting time of journal before your check-in time. </span></li>
                                                            <li> <span>-</span> <span>Please make sure to select time as per task. </span></li>
                                                            <li> <span>-</span> <span>Do not mix multiple tasks in same time. </span></li>
                                                            <li> <span>-</span> <span>Please make sure to check out of system before you complete the journal for the day. </span></li>
                                                            <li> <span>-</span> <span>You can add journal items until the expiry of next 24 hours from the end of the date of journal i.e. 1 days are available for you to enter in addition of journal date. </span></li>
                                                            <li> <span>-</span> <span>Once an item is entered you only have 24 hours to edit it from the time of entry however this time is superseded by the 24 hour time rule. </span></li>
                                                            <li> <span>-</span> <span>Please make sure to use projects name in the description by using "@" symbol. </span></li>
                                                            <li> <span>-</span> <span>If the project is not found after using the "@" symbol inform the management immediately. </span></li>
                                                        </ul>
                                                    </div>
                                                </Tooltip>
                                            }>
                                <button><i className={'fa fa-info'}></i></button>
                            </OverlayTrigger>
                        </div>
                        <MDBTable className="table-bordered">
                            <MDBTableHead>
                                <tr>
                                    <th className={'col1'}>Time (From - To)</th>
                                    <th>Descriptions in details</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {   this.state.addJournalStatus &&
                                    <tr>
                                    <td className={'d-flex inputBox'}>
                                        <button className={'btn btn-light'}
                                                onClick={() => (this.openTimePicker(true, 'startTimePicker',  1))}
                                        >{this.state.startTime ? this.state.startTime : 'Start'} </button>
                                        <span className={'vdot'}>-</span>
                                        <button className={'btn btn-light'}
                                                onClick={() => (this.openTimePicker(true, 'endTimePicker', 2))}
                                        > { this.state.endTime ? this.state.endTime :' End'}</button>
                                        { this.state.endTimePicker &&
                                        <div className={'timepicker'}>
                                            <Timekeeper
                                                switchToMinuteOnHourSelect={true}
                                                closeOnMinuteSelect={true}
                                                hour24Mode={true}
                                                onDoneClick={
                                                    () => (this.openTimePicker(false, 'endTimePicker', 2))
                                                }
                                                onChange={
                                                    (ev) => (this.saveTime(ev, 'endTimePicker', 2))
                                                }
                                            />
                                        </div>
                                        }
                                    </td>
                                    <td>
                                        <textarea
                                            name="journal"
                                            placeholder={'Enter Journal'}
                                            value={this.state.remark}
                                            onChange={(ev) => {
                                                this.encodeStr(this.state.remark);
                                                this.setState({
                                                    remark: ev.target.value
                                                });
                                            }}
                                            className={'w-100 border-0'}
                                            onKeyDown={(ev) => (
                                                this.KeyListner(ev, 'add', 'nodata')
                                            )}
                                            disabled={
                                                !(this.state.startTime && this.state.endTime)
                                            }
                                        />
                                    </td>
                                </tr>
                                }
                                { this.state.showJournal.map((data) => (
                                    <tr>
                                        <td className={'d-flex inputBox'}>
                                            <button className={'btn btn-lighto'}
                                                    onClick={() => (this.openTimePicker(true, data.index, 1))}
                                            >{data.start_at ? data.start_at : 'Start'}</button>
                                            <span className={'vdot'}>-</span>
                                            <button className={'btn btn-light'}
                                                    onClick={() => (this.openTimePicker(true, data.index, 2))}
                                            >{ data.end_at ? data.end_at :'End'}</button>
                                        </td>
                                        <td>
                                            <textarea type="text"  name="journal" value={data.remark}
                                                      onChange={
                                                          (ev) => (this.editRemark(ev, data))
                                                      }
                                                      className={'w-100 border-0'}
                                                      onKeyDown={(ev) => (
                                                          this.KeyListner(ev, 'edit', data)
                                                      )}
                                                      disabled={
                                                          !(data.start_at && data.end_at)
                                                      }
                                            />
                                        </td>
                                    </tr>
                                ))
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
                <div className={'col-md-4'}>
                    <div className={'historySec'}>
                        <div className={'user_journal_header'}>
                            <h6> <span className={'font-weight-bold'}> History </span> </h6>
                        </div>
                        <ul className={'history_data_wrapper'}>
                            {
                                this.state.journalHistory.map((item) => (
                                    <li className={'history_data'} onClick={() => {
                                        this.setState({
                                            currentDate: item.createdate,
                                            showingDate: item.date,
                                            day: item.day
                                        });
                                        this.ShowJournal(item.createdate);
                                    }}>
                                        Journal update on {item.date}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.projectModal}
                    style={customStyles2}
                    contentLabel="Example Modal"
                >
                    <button className={'crossBtn'} onClick={() => (
                        this.setState({
                            projectModal: false
                        })
                    )}>
                        <img src={close} alt={"close"} width="40" height="40"/>
                    </button>
                    <h6 className={'border-bottom p-2 text-center'}>Select the Project</h6>
                    <div className={'project_modal'}>
                        {
                            this.state.projectarray.map(data => (
                                <p className={'project_name'}
                                   onClick={() => (
                                       this.addProject(data)
                                   )}>{data.name}</p>
                            ))
                        }
                    </div>
                </Modal>
                {
                    this.state.modalIsOpen &&
                    <div className="loading">
                        <div className="loader">
                            <BounceLoader sizeUnit={"px"}
                                          css={loaderCSS}
                                          size={80}
                                          color={'#66CC75'}
                                          loading={this.state.modalIsOpen}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}
