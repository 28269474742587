import React from 'react';
import './Personnel.css';
import { Tab, Tabs, Card, Button } from 'react-bootstrap';
export default class personnel extends React.Component {
  render() {

    return (
      <section className={'personnel'}> 
        <Tabs id="controlled-tab-example" className={'tabview'}>
          <Tab eventKey="home" title="Project Wise">
            <div className={'projectwise'}>
              <div className="headwar">
                <div className="searchbox">
                  <form>
                    <input type="search" placeholder="Search" />
                  </form>
                </div>
                <Tabs>
                  <Tab eventKey={1} title="Day" defaultActiveKey="1">
                  </Tab>
                  <Tab eventKey={2} title="Week">
                  </Tab>
                  <Tab eventKey={3} title="Month">
                  </Tab>
                </Tabs>
                <div className="paginationBotton">
                  <ul className="pagination pagination-sm">
                    <li className="page-item"><a className="page-link color-primary"><span class="fas fa-angle-left"></span>Prev</a></li>
                    <li className="page-item"><a className="page-link color-primary">Next <span class="fas fa-angle-right"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className={'ProjectWiseCard'}>
                <div className={'container'}>
                  <div className={'row'}>
                    <div className={'col-sm-3'}>
                      <Card>
                        <Card.Header>Pratibha Packwell Pvt Ltd</Card.Header>
                        <Card.Body>
                        <ul>
                          <li>Lokendra Prajapat</li>
                          <li>Aman Mishra</li>
                          <li>Mahendra Kushwaha</li>
                          <li>Lokesh Patidar</li>
                          <li>Jayant Verma</li>
                          <li>Nandan Garg</li> 
                        </ul> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          6
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3'}>
                    <Card>
                        <Card.Header>Funds Portfolio Manage..</Card.Header>
                        <Card.Body>
                        <ul>
                          <li>Anshita Mohta</li>
                          <li>Sanskriti Pawar</li>
                          <li>Vijay Patidar</li>
                          <li>Anjali Jaiswal</li>
                          <li>Gaurav Bairagi</li>
                          <li>Neha Mandloi</li>
                          <li>Nandan Garg</li>
                        </ul> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          7
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3'}>
                    <Card>
                        <Card.Header>Paipai Hisab</Card.Header>
                        <Card.Body>
                        <ul>
                          <li>Pankaj Chandratrey</li>
                          <li>Gopal Oswal</li>
                          <li>Lokesh Rajput</li>
                          <li>Atal Patidar</li> 
                        </ul> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          4
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3'}>
                    <Card>
                        <Card.Header>Onion Project Management</Card.Header>
                        <Card.Body>
                        <ul>
                          <li>Prakash Jha</li>
                          <li>Gopal Oswal</li>
                          <li>Vijay Patidar</li>
                          <li>Lokesh Rajput</li>
                        </ul> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          4
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Employee wise">
          <div className={'projectwise'}>
              <div className="headwar">
                <div className="searchbox">
                  <form>
                    <input type="search" placeholder="Search" />
                  </form>
                </div>
                <Tabs>
                  <Tab eventKey={1} title="Day" defaultActiveKey="1">
                  </Tab>
                  <Tab eventKey={2} title="Week">
                  </Tab>
                  <Tab eventKey={3} title="Month">
                  </Tab>
                </Tabs>
                <div className="paginationBotton">
                  <ul className="pagination pagination-sm">
                    <li className="page-item"><a className="page-link color-primary"><span class="fas fa-angle-left"></span>Prev</a></li>
                    <li className="page-item"><a className="page-link color-primary">Next <span class="fas fa-angle-right"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className={'ProjectWiseCard'}>
                <div className={'container'}>
                  <div className={'row'}>
                    <div className={'col-sm-3 mb-25'}>
                      <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                      <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                      <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                    <div className={'col-sm-3 mb-25'}>
                    <Card className={'productDetail'}>
                        <Card.Header>Lokesh Rajput</Card.Header>
                        <Card.Body>
                        <p>Pratibha Packwell Pvt Ltd</p> 
                        </Card.Body>
                        <div className={'cardCircle'}>
                          1
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs> 
      </section>
    )
  }
}
