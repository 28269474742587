 import dashboardIcon from './dashboard.svg';
 import ProductIcon from './document.svg'
 import sprintsIcon from './sprint.svg'
 import ganttChart from './chart-gantt.svg'
 import Team from './people.svg'
 import issues from './problems-with-synchronization.svg'
 import document from './writing.svg'
 
 export default class Card {
     cardjson;
     constructor(){
       this.cardjson =  [
            {"icon":dashboardIcon,  "name":'Dashboard'},
            {"icon": ProductIcon, "name":'Product Backlog'},
            {"icon": sprintsIcon, "name":'Sprints'},
            {"icon": ganttChart, "name":'Gantt Chart'},
            {"icon": Team, "name":'Team'},
            {"icon": issues, "name":'Issues'},
            {"icon": document, "name":'Documents'},


            
         ];
     }
   
 }
 
 