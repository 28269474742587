import React from 'react';
import './Setting.css';
import { Tabs, Tab, Form } from 'react-bootstrap';
import { DropzoneArea } from 'material-ui-dropzone';

export default class Setting extends React.Component {
    state = {
        show: false,
    }
    render() {
        const { show } = this.state;
        return (
            <section className={'setting'}>
                <Tabs defaultActiveKey="Settings" >
                    <Tab eventKey="Settings" title="Settings">
                        <div className={'settingard'}>
                            <div className={'row'}>
                                <figure className={'col-md-3 text-center'}>
                                    <img src="../Logo-Beelytical.png" className={'img-fluid'} />
                                    <h6> Change Logo</h6>
                                </figure>
                                <div className={'col-md-9 pt-2'}>
                                    <h5>Company Name</h5>
                                    <h6>website.com</h6>
                                    <div className={'SettingTab'}>
                                        <Form >
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Working Days</Form.Label>
                                                <ul className="days">
                                                    <li>Sun</li>
                                                    <li>Mon</li>
                                                    <li>Tue</li>
                                                    <li>Wed</li>
                                                    <li>Thu</li>
                                                    <li>Fri</li>
                                                    <li>Sat</li>
                                                </ul>
                                            </Form.Group>
                                            <h6>Country</h6>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Time Zone</Form.Label>
                                                <Form.Control type="email" placeholder="GMT +5:30 hours" />
                                            </Form.Group>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Working Hours</Form.Label>
                                                <div className={'bgcolor'}>
                                                    <Form.Control type="email" placeholder="GMT +5:30 hours" />
                                                </div>
                                            </Form.Group>
                                        </Form>
                                        <div className={'editBtn'}>
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                            <span>Edit</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Payment Plan" title="Payment Plan">

                        {show ? (
                            <div className={'paymentPage'}>
                                <div class="row text-center">
                                    <div class="col-md-7 leftContent">
                                        <div class="row">
                                            <div class="col-12 pt-2 mb-4">
                                                <h5>How many employees do you have?</h5>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>5<br />or less</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>10<br />or less</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>10 + </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 pt-2 mt-4 mb-4">
                                                <h5>For how many months you want to pay?</h5>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>3 <br /> months</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>6 <br /> months</p>
                                                    </div>
                                                    <h6>5% discount</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>12 <br /> months</p>
                                                    </div>
                                                    <h6>15% discount</h6>
                                                    <h6 className={'offer'}>Best offer</h6>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-center">
                                                    <div class="procing-logo">
                                                        <p>Subscribe</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5 text-center rightCard">
                                        <h5>You Chose</h5>
                                        <div class="card mt-3">
                                            <div class="card-body row pb-0">
                                                <div class="col-6 text-left">
                                                    <p>10 or less users</p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p>₹3500</p>
                                                </div>
                                                <div class="col-6 text-left">
                                                    <p>Months</p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p>6 mo</p>
                                                </div>
                                                <div class="col-6 text-left borderTB">
                                                    <p>10 or less users</p>
                                                </div>
                                                <div class="col-6 text-right borderTB">
                                                    <p>₹3500</p>
                                                </div>
                                                <div class="col-12 text-left">
                                                    <p class="underline">Discount</p>
                                                </div>
                                                <div class="col-6 text-left">
                                                    <p>6 month 5%</p>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <p>₹1050</p>
                                                </div>
                                                <div class="col-6 text-left borderT">
                                                    <p>Net Pay</p>
                                                </div>
                                                <div class="col-6 text-right borderT">
                                                    <p>₹19950</p>
                                                </div>
                                            </div>
                                        </div>
                                        <button>PROCEED</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div className={'activePlan'}>
                                    <button className={'renewBtn'} onClick={() => this.setState({ show: !show })}>
                                        {show ? 'Renew your plan' : 'Renew your plan'}</button>
                                    <div className={'activePlanCard'}>
                                        <h6>Active Plan</h6>
                                        <p>Current plan active until <span>08/07/2020. </span>60<span></span> days left.</p>
                                        <h6>Number of employees</h6>
                                        <ul className={'PlanDetails'}>
                                            <li>5 or less</li>
                                            <li>3 Months</li>
                                            <li>Subscription On</li>
                                            <span>Click to unsubscribe</span>
                                        </ul>
                                    </div>

                                </div>
                            )}
                    </Tab>
                    <Tab eventKey="Documents" title="Documents">
                        <div className={'documents'}>
                            <h5>Documents of Company</h5>
                            <p className={'dropText'}>Leave Policies</p>
                            <DropzoneArea
                                acceptedFiles={['application/pdf']}
                                dropzoneText={"Drag and drop an PDF here or click"}
                                onChange={(files) => console.log('Files:', files)}
                                filesLimit={1}
                                className={'dropzone'}
                            />
                            <p className={'dropText'}>Employment Terms</p>
                            <DropzoneArea
                                acceptedFiles={['application/pdf']}
                                dropzoneText={"Drag and drop an PDF here or click"}
                                onChange={(files) => console.log('Files:', files)}
                                filesLimit={1}
                                className={'dropzone'}
                            />
                            <p className={'dropText'}>Other Documents</p>
                            <input className={'documentInput'}
                            type="text"
                            placeholder="Name of Document" />
                            <DropzoneArea
                                acceptedFiles={['application/pdf']}
                                dropzoneText={"Drag and drop an PDF here or click"}
                                onChange={(files) => console.log('Files:', files)}
                                filesLimit={1}
                                className={'dropzone'}
                            />
                            {/* <Form className={'documentForm'} >
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload your leave policies</Form.Label>
                                    <Form.Control type="email" placeholder="" />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload your employment terms</Form.Label>
                                    <Form.Control type="email" placeholder="" />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload other documents</Form.Label>
                                    <div className={'bgcolor'}>
                                        <Form.Control type="email" placeholder="Name of document" className={'inputDocument'} />
                                    </div>
                                </Form.Group>
                            </Form> */}
                        </div>
                    </Tab>
                </Tabs>
            </section>
        )
    }
}
