import React from "react";
import Api from '../../Providers/Api';
import {  NavLink ,Link, Redirect, Route, Switch} from 'react-router-dom';
import UserProjectDashboard from './userProjectDashboard/userProjectDashboard.jsx';
import UserProjectDetail from './userProjectDetail/userProjectDeatil.jsx'
import './UserProjects.css';
import { Collapse } from 'react-bootstrap';
import Icon from './project.svg';
import  dashboardIcon from './dashboard.svg';
import { BrowserRouter } from 'react-router-dom';
var api = new Api();
export default class UserProjects extends React.Component {
    constructor() {
        super();
        this.state = {
            deactiveProjects: [],
            activeProjects: [],
            firstThreeProject:[],
            afterThreeProject: [],
            showDeactiveProject: false,
            showAfterThree: false
        }
        this.getProjects();
    }
    /**
     * funtio to manage collaspe
     * for archived projects
     * @param {bolean} value 
     */
    archive(value) {
        this.setState({
            showDeactiveProject: value
        })
    }// end of archive..
/**
     * funtion to get Project
     * list 
     */
    async getProjects() {
        const param = '/ShowProjects';
        const activeProject = [];
        const deactiveProject = [];
        await api.getApi(param).then(res => {
            if (res.data.status === 200) {
                for (let i = 0; i < res.data.response.length; i++) {
                    if (res.data.response[i].status === "Active") {
                        activeProject.push(res.data.response[i])
                    } else {
                        deactiveProject.push(res.data.response[i])
                    }
                }
            }
            this.setState({
                activeProjects: activeProject,
                deactiveProjects: deactiveProject
            })
            this.getfirstThreeProject();
        }).catch(error => {
            window.alert(JSON.stringify(error));
        })
    } // get Project end..

    /**
     * funtion to get project List
     * of project after 3
     */
   getfirstThreeProject(){
       const firstThreeProject  = []; // get List of first Three Project
       const afterThreeProject =  []; // get list of project after three projets
    
           for(let i = 0; i< this.state.activeProjects.length; i++){
               if(i<3){
                firstThreeProject.push(this.state.activeProjects[i]);
               } else {
                afterThreeProject.push(this.state.activeProjects[i]);
               }   
       } // for loop ends..
       console.log('first three project', firstThreeProject);
       console.log('after first three project', afterThreeProject);
       this.setState({
           firstThreeProject:firstThreeProject,
           afterThreeProject: afterThreeProject
       })
   }// get First three project end..
/**
 * funtion to show project 
 * After three
 * @param {boolean} value 
 */
   showAfterThree(value){
       console.log('show after three called');
       this.setState({
           showAfterThree: value
       })
   }// show After threee end..
    render() {
        
        return (
            <div className="col-md-12">
                <div className="row ">
                    <div className="col-md-3 userSidebar">
                        <ul className="sideNav" >
                        <li className="userProjectName"> 
                            <img
                                src={dashboardIcon}
                                alt="dash-icon"
                                className="projectIconDashboard"
                            /> 
                            <NavLink  className="text-capitalize" to={'/projects/projectDashboard'} > Dashboard </NavLink>
                                </li>
                            {/*  active project list */}
                            {
                                this.state.firstThreeProject.map((item, index) => (
                                    <li key={item.pjid} className="userProjectName ">
                                        <img 
                                        src={Icon}
                                        alt="a"
                                        className="projectIcon"
                                        />
                                        
                                        <NavLink className="text-capitalize" to= {'/projects/UserProjectDetail'}>  {item.name }  </NavLink>
                                        
                                        
                                    </li>
                                ))
                            }

                            {/* show First threee end. */}
                            {/* show after Three Section start */}
                            {
                                <li  className="userProjectName">
                                    <p
                                        onClick={() => {
                                            this.showAfterThree(!this.state.showAfterThree)
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={this.state.showAfterThree}
                                        className="pClass"
                                    >
                                        
                                        More Projects
                              <i className={
                                            (this.state.showAfterThree) ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                                        }></i>
                                    </p>
                                    <Collapse in={this.state.showAfterThree}>
                                        <div id="example-collapse-text new">
                                            <ul className="scroll">
                                                {
                                                    this.state.afterThreeProject.map((item) => (
                                                        <li  key={item.pjid} className="userProjectName ">
                                                            <img 
                                                   src={Icon}
                                                alt="a"
                                                    className="projectIcon"
                                                        />
                                                            <NavLink to={item.name}>
                                                                
                                                                 {item.name} </NavLink>
                                                        </li>
                                                    ))

                                                }

                                            </ul>
                                        </div>
                                    </Collapse>
                            </li>
                            }
                            {/* after Three end. */}
                            
                            
                            {/* Deactive Project List */}
                            {

                                <li className="userProjectName">
                                    <p
                                        onClick={() => {
                                            this.archive(!this.state.showDeactiveProject)
                                        }}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={this.state.showDeactiveProject}
                                        className="pClass"
                                    >
                                        
                                        Archived Projects
                        <i className={
                                            (this.state.showDeactiveProject) ? 'fas fa-caret-up' : 'fas fa-caret-up down'
                                        }></i>
                                    </p>

                                    <Collapse in={this.state.showDeactiveProject}>
                                        <div id="example-collapse-text">
                                            <ul className="scroll">
                                                {
                                                    this.state.deactiveProjects.map((item) => (
                                                        <li className="userProjectName">
                                                            <img 
                                                   src={Icon}
                                                alt="a"
                                                    className="projectIcon"
                                                        />
                                                            <NavLink to={item.name}>
                                                                
                                                                 {item.name} </NavLink>
                                                        </li>
                                                    ))

                                                }

                                            </ul>
                                        </div>
                                    </Collapse>
                                </li>

                            }
                        </ul>
                    </div>
                    <div className="col-md-9 p-0">
                        <BrowserRouter basename={'OPM/#dashboard/projects'}>
                            <div> 
                            
                                <Route exact path='/' render={
                                    () => (
                                        <Redirect to='/projectDashboard'/>
                                        )
                                }/>
                                <Route  path='/projectDashboard' component={UserProjectDashboard}
                                 render={
                                    ()=> (
                                        <UserProjectDashboard/>
                                    )  
                                }
                                />
                            <Route  path='/UserProjectDetail'  component={UserProjectDetail} render={
                                ()=>(
                                    <UserProjectDetail/>
                                )
                            } />
                            
                            </div>
                        </BrowserRouter>
                    </div>

                </div>
            </div>

        )// retrun  end..
    } // render end..
    


}// main calss end..



