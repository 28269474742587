import React from "react";
import Api from "../../../Providers/Api";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
const api = new Api();
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
`;
export default class AdDashboard extends React.Component {
  userDetail = {};

  constructor() {
    super();
    this.state = {
      employeeCount: "",
      loading: true,
      totalemp: "",
    };
    this.GetDashboardDetail();
  }

  /**
   * function to the details of the admin dashboard
   */
  GetDashboardDetail() {
    this.userDetail = JSON.parse(localStorage.getItem("userdetail"));
    // console.log(this.userDetail);
    // console.log(this.userDetail.emptype);
    if (this.userDetail.emptype === "Admin") {
      const param = "/ShowDashboardOnAdmin";
      api
        .getApi(param)
        .then((res) => {
          // console.log('response from admin dashboard api:', res);
          // console.log(res.data.response.empcount);
          this.setState({
            employeeCount: res.data.response.empcount,
            loading: false,
            totalemp: res.data.response.totalemp,
          });
          // console.log('employee count:', this.state.employeeCount);
        })
        .catch((err) => {
          console.error("error from admin dashboard api:", err);
          this.setState({
            loading: false,
          });
        });
    }
  }

  /**
   * function to show time of employees
   */
  showTimes() {}
  render() {
    return (
      <div className="dashboarwrapper">
        <div class="card-deck">
          <div class="card text-center" onClick={this.showTimes.bind(this)}>
            <Link to={"/AttendanceSheet"}>
              <div className="card-header">Today</div>
              <div className="card-body">
                <h4>{this.state.employeeCount + " /" + this.state.totalemp}</h4>
              </div>
            </Link>
          </div>
          <div class="card text-center">
            <div className="card-header">Birthdays</div>
            <div className="card-body">
              <h4>2 Birthdays</h4>
              <p className="card-text">In Next 10 Days</p>
            </div>
          </div>
          <div class="card text-center">
            <div className="card-header">Projects</div>
            <div className="card-body">
              <h4>7 Projects</h4>
              <p className="card-text">4 currently active</p>
            </div>
          </div>
          <div>
            <DotLoader
              sizeUnit={"px"}
              css={loaderCSS}
              size={150}
              color={"#123abc"}
              loading={this.state.loading}
            />
          </div>
        </div>
      </div>
    );
  }
}
