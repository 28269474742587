import React, { Component } from 'react';
import { NavLink, Link, Redirect, Route, Switch, BrowserRouter as Router, HashRouter } from 'react-router-dom';
import './Tracker.css';
import TrackerProjects from './TrackerProjects/TrackerProjects';
import TrackerActivities from './TrackerActivities/TrackerActivities';
import TrackerReports from './TrackerReports/TrackerReports';
import TrackerWebTracker from './TrackerWebTracker/TrackerWebTracker';
import WorkIcon from '@material-ui/icons/Work';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import WidgetsIcon from '@material-ui/icons/Widgets';

export default class Tracker extends Component {
    render() {
        return (
            // <HashRouter hashType={"noslash"}>
            // {/* //     <Router basename={"OPM/#dashboard/tracker"}> */}
                    <div className={'col-md-12'}>
                        <div className={'row'}>

                            {/* Tracker sidebar starts here */}
                            <div className={'col-md-2'}>
                                <div className={'trackerSidebar'}>
                                    <ul className={'sideNav'}>
                                        <li className={'heading text-center'}>
                                            <WidgetsIcon className={'headingIcon'} /><br />
                                            <NavLink to={'/tracker/trackerProjects'}>OPM Tracker</NavLink>
                                        </li>
                                        <li>
                                            <WorkIcon className={'icons'} />
                                            <NavLink to={'/tracker/trackerProjects'}>Projects</NavLink>
                                        </li>
                                        <li>
                                            <BarChartIcon className={'icons'} />
                                            <NavLink to={'/tracker/trackerReports'}>Reports</NavLink>
                                        </li>
                                        <li>
                                            <TrendingUpIcon className={'icons'} />
                                            <NavLink to={'/tracker/trackerActivities'}>My Activities</NavLink>
                                        </li>
                                        <li>
                                            <AccessAlarmIcon className={'icons'} />
                                            <NavLink to={'/tracker/trackerWebTracker'}>Web Tracker</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Tracker sidebar ends here */}

                            {/* Tracker main content starts here */}
                            <div className={'col-md-10 p-0'}>
                                <div className={'trackerContent'}>
                                <Router basename={"OPM/#dashboard/tracker"}>
                                    <div>
                                        <Switch>
                                            <Route exact path='/' render={
                                                () => (
                                                    <Redirect to='/trackerProjects' />
                                                )
                                            } />

                                            <Route path='/trackerProjects' 
                                                render={
                                                    () => (
                                                        <TrackerProjects />
                                                    )
                                                } />

                                            <Route path='/trackerReports' 
                                                render={
                                                    () => (
                                                        <TrackerReports />
                                                    )
                                                } />

                                            <Route path='/trackerActivities' 
                                                render={
                                                    () => (
                                                        <TrackerActivities />
                                                    )
                                                } />

                                            <Route path='/trackerWebTracker' 
                                                render={
                                                    () => (
                                                        <TrackerWebTracker />
                                                    )
                                                } />
                                        </Switch>
                                    </div>
                                    </Router>
                                </div>
                            </div>
                            {/* Tracker main content endss here */}

                        </div>
                    </div>
            // {/* //     </Router> */}
            //  </HashRouter>
        )
    }
}

