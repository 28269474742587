import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core";
import { firestore, auth } from "../../../config/firebase.config";
import firebase from "firebase/app";
import ChatList from "../ChatList/ChatList";
import { Link } from "react-router-dom";
import ChatView from "../ChatView/ChatView";
import ChatTextBox from "../ChatTextBox/ChatTextBox";
import NewChat from "../NewChat/NewChat";

class Chat extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedChat: null,
      newChatFormVisible: false,
      email: null,
      friends: [],
      chats: [],
    };
  }

  render() {
    const { classes } = this.props;
    if (this.state.email) {
      return (
        <div className={"row userjournal"}>
          <div className={"col-md-12"} style={{height: "80vh"}}>
            <div className="dashboard-container" id="dashboard-container">
              {/* rendering chat list component... */}
              <ChatList
                history={this.props.history}
                userEmail={this.state.email}
                selectChatFn={this.selectChat}
                chats={this.state.chats}
                selectedChatIndex={this.state.selectedChat}
                newChatBtnFn={this.newChatBtnClicked}
              />
              {this.state.newChatFormVisible ? null : (
                <ChatView
                  user={this.state.email}
                  chat={this.state.chats[this.state.selectedChat]}
                />
              )}
              {this.state.selectedChat !== null &&
              !this.state.newChatFormVisible ? (
                <ChatTextBox
                  userClickedInputFn={this.messageRead}
                  submitMessageFn={this.submitMessage}
                />
              ) : null}

              {this.state.newChatFormVisible ? (
                <NewChat
                  goToChatFn={this.goToChat}
                  newChatSubmitFn={this.newChatSubmit}
                />
              ) : null}
              <Link
                to="/dashboard"
                className={classes.signOutBtn}
                style={{ textAlign: "center" }}
              >
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"row userjournal"}>
          <div className={"col-md-8"}>
            <div>LOADING...</div>
          </div>
        </div>
      );
    }
  }

  submitMessage = (msg) => {
    const docKey = this.buildDocKey(
      this.state.chats[this.state.selectedChat].users.filter(
        (_usr) => _usr !== this.state.email
      )[0]
    );
    console.log("doc key: ", docKey);
    console.log("message: ", msg);
    firestore
      .collection("chats")
      .doc(docKey)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          sender: this.state.email,
          message: msg,
          timestamp: Date.now(),
        }),
        receiverHasRead: false,
      });
  };

  // Always in alphabetical order:
  // 'user1:user2'
  buildDocKey = (friend) => [this.state.email, friend].sort().join(":");

  newChatBtnClicked = () =>
    this.setState({ newChatFormVisible: true, selectedChat: null });

  newChatSubmit = async (chatObj) => {
    const docKey = this.buildDocKey(chatObj.sendTo);
    await firestore
      .collection("chats")
      .doc(docKey)
      .set({
        messages: [
          {
            message: chatObj.message,
            sender: this.state.email,
          },
        ],
        users: [this.state.email, chatObj.sendTo],
        receiverHasRead: false,
      });
    this.setState({ newChatFormVisible: false });
    await this.selectChat(this.state.chats.length - 1);
  };

  selectChat = async (chatIndex) => {
    await this.setState({ selectedChat: chatIndex, newChatFormVisible: false });
    this.messageRead();
  };

  goToChat = async (docKey, msg) => {
    const usersInChat = docKey.split(":");
    const chat = this.state.chats.find((_chat) =>
      usersInChat.every((_user) => _chat.users.includes(_user))
    );
    this.setState({ newChatFormVisible: false });
    await this.selectChat(this.state.chats.indexOf(chat));
    this.submitMessage(msg);
  };

  // Chat index could be different than the one we are currently on in the case
  // that we are calling this function from within a loop such as the chatList.
  // So we will set a default value and can overwrite it when necessary.
  messageRead = () => {
    const chatIndex = this.state.selectedChat;
    const docKey = this.buildDocKey(
      this.state.chats[chatIndex].users.filter(
        (_usr) => _usr !== this.state.email
      )[0]
    );
    if (this.clickedMessageWhereNotSender(chatIndex)) {
      firestore
        .collection("chats")
        .doc(docKey)
        .update({ receiverHasRead: true });
    } else {
      console.log("Clicked message where the user was the sender");
    }
  };

  clickedMessageWhereNotSender = (chatIndex) =>
    this.state.chats[chatIndex].messages[
      this.state.chats[chatIndex].messages.length - 1
    ].sender !== this.state.email;

  componentWillMount = () => {
    try{
      auth.onAuthStateChanged(async (_usr) => {
        console.log("user: ", _usr);
        if (!_usr) this.props.history.push("/login");
        else {
          await firestore
            .collection("chats")
            .where("users", "array-contains", _usr.email)
            .onSnapshot(async (res) => {
              const chats = res.docs.map((_doc) => _doc.data());
              await this.setState({
                email: _usr.email,
                chats: chats,
                friends: [],
              });
            });
        }
      });
    }
    catch(err){
      console.error(err);
    }

  };
}

export default withStyles(styles)(Chat);
