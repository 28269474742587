import React from "react";
import "./Journal Detail.css";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import JournalPreview from "../JournalPreview/JournalPreview";
import Api from "../../../../Providers/Api";
import { CircleLoader } from "react-spinners";
import Modal from "react-modal";
import { css } from "@emotion/react";
import DatePicker from "react-datepicker";
var api = new Api();
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default class Journal extends React.Component {
  userDetail = {};
  datewiseJournalDate = new Date();
  datewiseJournalFullDate = new Date();
  constructor(props) {
    super();
    this.state = {
      activeTab: props.activeTab || 1,
      employeeList: [],
      GlobalEmployeeList: [],
      journalPreview: [],
      selectedEmployee: "",
      selectedEmployeeId: 0,
      selectedEmployeeJournalSummary: [],
      previewDate: "",
      previewJournal: [],
      showLoader: false,
      previewData: {},
      searchText: "",
      dateJournal: [],
    };
    this.handleSelect = this.handleSelect.bind(this);
    // this.GetDate();
    this.userDetail = localStorage.getItem("userdetail");
    let tempdate;
    if (new Date().getDate() > 9) {
      tempdate =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
    } else {
      tempdate =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
    }
    if (new Date().getMonth() + 1 < 10) {
      if (new Date().getDate() < 10) {
        this.datewiseJournalDate =
          new Date().getFullYear() +
          "-0" +
          (new Date().getMonth() + 1) +
          "-0" +
          new Date().getDate();
      } else {
        this.datewiseJournalDate =
          new Date().getFullYear() +
          "-0" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate();
      }
    } else {
      if (new Date().getDate() < 10) {
        this.datewiseJournalDate =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-0" +
          new Date().getDate();
      } else {
        this.datewiseJournalDate =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate();
      }
    }
    // console.log(tempdate);
    this.CurrentDateJournal(tempdate);
  }

  /**
   * function for search
   */
  handleSearch(ev) {
    this.setState({
      searchText: ev.target.value,
    });
    // console.log(ev.target.value);
    const val = ev.target.value;
    if (val && val.trim() !== "") {
      this.setState({
        employeeList: this.state.GlobalEmployeeList.filter((item) => {
          return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
        }),
      });
    } else {
      this.setState({
        employeeList: this.state.GlobalEmployeeList,
      });
    }
  }

  /**
   * function to get the list of the employees
   */
  async GetEmployee() {
    this.setState({
      showLoader: true,
    });
    const param = "/ShowAllEmployee";
    await api
      .getApi(param)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          this.setState({
            GlobalEmployeeList: res.data.response,
            employeeList: res.data.response,
            selectedEmployee: res.data.response[0].name,
            selectedEmployeeId: res.data.response[0].empid,
          });
          // this.GetJournalSummary(this.state.selectedEmployeeId);
          this.GetJournalSummary(res.data.response[0].empid);
          this.setState({
            showLoader: false,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          showLoader: false,
        });
      });
  }

  /**
   * function to get journal summary of all the employee
   */
  GetJournalSummary(id) {
    this.setState({
      showLoader: true,
      previewDate: "",
      previewData: {},
      selectedEmployeeJournalSummary: [],
    });
    const param = "/ShowEmpJournal_Empwise?empid=" + id;
    api
      .getApi(param)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          if (res.data.response.length) {
            this.setState({
              selectedEmployeeJournalSummary: res.data.response,
              showLoader: false,
              previewDate: this.datewiseJournalDate,
              previewData: res.data.response[0],
            });
            // console.log(res.data.response[0]);
            sessionStorage.setItem(
              "journalDetails",
              JSON.stringify(res.data.response[0])
            );
            this.child.ShowJournal(
              res.data.response[0].empid,
              this.datewiseJournalDate
            );
          }
        } else {
          window.alert(JSON.stringify(res));
          this.setState({
            showLoader: false,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          showLoader: false,
        });
      });
  }

  /**
   * function to get the current day journal
   */
  CurrentDateJournal(date) {
    this.setState({
      showLoader: true,
    });
    const param = "/ShowEmpJournal_datewise?date=" + date;
    api
      .getApi(param)
      .then((res) => {
        // console.log(res);
        if (res.data.status === 200) {
          this.setState({
            dateJournal: res.data.response,
          });
          // console.log(this.state.dateJournal);
          res.data.response[0].createdates = this.datewiseJournalDate;
          sessionStorage.setItem(
            "journalDetails",
            JSON.stringify(res.data.response[0])
          );
          this.child2.ShowJournal(
            res.data.response[0].empid,
            this.datewiseJournalDate
          );
          this.setState({
            showLoader: false,
          });
        } else {
          // window.alert(JSON.stringify(res));
          this.setState({
            showLoader: false,
          });
        }
      })
      .catch((err) => {
        // window.alert(JSON.stringify(err));
        // console.log(err);
        this.setState({
          showLoader: false,
        });
      });
  }

  /**
   * function to change the tab
   * @param selectedTab: tab
   */
  handleSelect(selectedTab) {
    this.setState({
      activeTab: selectedTab,
    });
    // console.log(this.state.activeTab);
    if (this.state.activeTab === 1) {
      this.GetEmployee();
    } else {
    }
  }

  /**
   * function to show the preview of the user journal
   */
  PreviewJournal(data) {
    // console.log(data);
    this.setState({
      previewDate: data.createdates,
    });
    sessionStorage.setItem("journalDetails", JSON.stringify(data));
    this.child.ShowJournal(data.empid, data.createdates);
  }

  /**
   * function to preview the current day journal
   */
  ShowCurrentDayJournal(data) {
    // console.log(data);
    data.createdates = this.datewiseJournalDate;
    sessionStorage.setItem("journalDetails", JSON.stringify(data));
    this.child2.ShowJournal(data.empid, this.datewiseJournalDate);
  }

  render() {
    return (
      <div className={"journal"}>
        <Tabs activeKey={this.activeTab} onSelect={this.handleSelect}>
          <Tab eventKey={1} title="By Date" className="journaltoday">
            <div className="row">
              <div className={"col-6"}>
                <p>
                  <i className={"fas fa-calendar mr-1"}></i>
                  {this.datewiseJournalFullDate.toLocaleDateString() ===
                  new Date().toLocaleDateString() ? (
                    <label className={"todayDatePicker"}>
                      Today
                      <DatePicker
                        className={"journalDatePicker p-1"}
                        placeholderText={"yyyy-mm-dd"}
                        dateFormat="yyyy-MM-dd"
                        selected={this.datewiseJournalFullDate}
                        onChange={(ev) => {
                          this.datewiseJournalFullDate = ev;
                          if (ev.getMonth() + 1 < 10) {
                            if (ev.getDate() < 10) {
                              this.datewiseJournalDate =
                                ev.getFullYear() +
                                "-0" +
                                (ev.getMonth() + 1) +
                                "-0" +
                                ev.getDate();
                            } else {
                              this.datewiseJournalDate =
                                ev.getFullYear() +
                                "-0" +
                                (ev.getMonth() + 1) +
                                "-" +
                                ev.getDate();
                            }
                          } else {
                            if (ev.getDate() < 10) {
                              this.datewiseJournalDate =
                                ev.getFullYear() +
                                "-" +
                                (ev.getMonth() + 1) +
                                "-0" +
                                ev.getDate();
                            } else {
                              this.datewiseJournalDate =
                                ev.getFullYear() +
                                "-" +
                                (ev.getMonth() + 1) +
                                "-" +
                                ev.getDate();
                            }
                          }
                          this.CurrentDateJournal(this.datewiseJournalDate);
                        }}
                      />
                    </label>
                  ) : (
                    <DatePicker
                      className={"journalDatePicker p-1"}
                      placeholderText={"yyyy-mm-dd"}
                      dateFormat="yyyy-MM-dd"
                      selected={this.datewiseJournalFullDate}
                      onChange={(ev) => {
                        this.datewiseJournalFullDate = ev;
                        if (ev.getMonth() + 1 < 10) {
                          if (ev.getDate() < 10) {
                            this.datewiseJournalDate =
                              ev.getFullYear() +
                              "-0" +
                              (ev.getMonth() + 1) +
                              "-0" +
                              ev.getDate();
                          } else {
                            this.datewiseJournalDate =
                              ev.getFullYear() +
                              "-0" +
                              (ev.getMonth() + 1) +
                              "-" +
                              ev.getDate();
                          }
                        } else {
                          if (ev.getDate() < 10) {
                            this.datewiseJournalDate =
                              ev.getFullYear() +
                              "-" +
                              (ev.getMonth() + 1) +
                              "-0" +
                              ev.getDate();
                          } else {
                            this.datewiseJournalDate =
                              ev.getFullYear() +
                              "-" +
                              (ev.getMonth() + 1) +
                              "-" +
                              ev.getDate();
                          }
                        }
                        this.CurrentDateJournal(this.datewiseJournalDate);
                      }}
                    />
                  )}
                </p>
                <div className={"today todayone"}>
                  <div className={"cardlist"}>
                    <div className={"row"}>
                      {!this.state.dateJournal.length && (
                        <div className={"col-12"}>
                          <div className={"employeecard"}>
                            <div className={"row"}>
                              <h6 className={"col-6 name"}>No data yet</h6>
                              <p className={"col-6 time"}>No data yet</p>
                            </div>
                            <div className={"row"}>
                              <p className={"col-4 detail"}>No data yet</p>
                              <p className={"col-4 detail"}>No data yet</p>
                              <p className={"col-4 detail2"}>No data yet</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.dateJournal.map((item) => (
                        <div className={"col-12"}>
                          <div
                            className={
                              item.hours !== "00H:00M"
                                ? "employeecard"
                                : "employeecard borderDanger"
                            }
                            onClick={() => this.ShowCurrentDayJournal(item)}
                          >
                            <div className={"row"}>
                              <h6 className={"col-6 name"}>{item.empname}</h6>
                              <p className={"col-6 time"}>20 min ago</p>
                            </div>
                            <div className={"row"}>
                              <p className={"col-4 detail"}>{item.hours}</p>
                              <p className={"col-4 detail"}>
                                {item.project_count} Project
                              </p>
                              {/* <p className={'col-4 detail2'}>
                                                                    2 Tasks
                                                                </p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.dateJournal.length ? (
                <div className={"col-6"}>
                  <JournalPreview
                    ref={(instance) => {
                      this.child2 = instance;
                    }}
                    empid={this.state.dateJournal[0].empid}
                    hours={this.state.dateJournal[0].hours}
                    project_count={this.state.dateJournal[0].project_count}
                    employeeName={this.state.dateJournal[0].empname}
                    date={this.datewiseJournalDate}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </Tab>
          <Tab eventKey={2} title="Employee wise" className={"journaltoday"}>
            <div className="row">
              <div className={"col-6"}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    {this.state.selectedEmployee}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className={"seachbarfix"}>
                      <input
                        type="search"
                        className={"searchbar"}
                        onChange={this.handleSearch.bind(this)}
                        value={this.state.searchText}
                        placeholder={"Search"}
                      />
                    </div>
                    {this.state.employeeList.map((item) => (
                      <Dropdown.Item
                        onClick={(ev) => {
                          this.setState({
                            selectedEmployee: item.name,
                          });
                          this.GetJournalSummary(item.empid);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className={"today todayone"}>
                  <div className={"cardlist"}>
                    <div className={"row"}>
                      {this.state.selectedEmployeeJournalSummary.map((data) => (
                        <div className={"col-12"}>
                          <div
                            className={
                              data.hours !== "00H:00M"
                                ? "employeecard"
                                : "employeecard borderDanger"
                            }
                            onClick={() => this.PreviewJournal(data)}
                          >
                            <div className={"row"}>
                              <h6 className={"col-6 name"}>
                                {data.createdate}
                              </h6>
                              <p className={"col-6 time"}>20 min ago</p>
                            </div>
                            <div className={"row"}>
                              <p className={"col-4 detail"}>{data.hours}</p>
                              <p className={"col-4 detail text-center"}>
                                {data.project_count} Project
                              </p>
                              {/* <p className={'col-4 detail2'}>
                                                                2 Tasks
                                                            </p> */}
                            </div>
                          </div>
                        </div>
                      ))}
                      {!this.state.selectedEmployeeJournalSummary.length && (
                        <div className={"col-12"}>
                          <div className={"employeecard"}>
                            <h2 className={"text-center pt-3"}>
                              No Data Found
                            </h2>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {this.state.selectedEmployeeId &&
                  this.state.previewDate &&
                  this.state.previewData && (
                    <JournalPreview
                      ref={(instance) => {
                        this.child = instance;
                      }}
                      empid={this.state.selectedEmployeeId}
                      hours={this.state.previewData.hours}
                      project_count={this.state.previewData.project_count}
                      employeeName={this.state.selectedEmployee}
                      date={this.state.previewDate}
                    />
                  )}
              </div>
            </div>
          </Tab>
        </Tabs>
        <Modal
          isOpen={this.state.showLoader}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <CircleLoader
            css={override}
            sizeUnit={"px"}
            size={50}
            color={"#123abc"}
            loading={true}
          />
        </Modal>
      </div>
    );
  }
}
