import React, { Component } from 'react';
import './TrackerReports.css';
import {
    DropdownButton,
    Dropdown,
    Button
} from 'react-bootstrap';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Badge,
    Avatar,
    Paper,
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Table,
    TableBody,
    TableHead,
    TableContainer
} from '@material-ui/core';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

// Projects Chart Data 
const data = [
    {
        date: '3 Nov', hours: 10
    },
    {
        date: '4 Nov', hours: 12.5
    },
    {
        date: '5 Nov', hours: 15.02
    },
    {
        date: '6 Nov', hours: 7.4
    },
    {
        date: '7 Nov', hours: 0
    },
    {
        date: '8 Nov', hours: 9
    },
    {
        date: '9 Nov', hours: 15
    },
    {
        date: '10 Nov', hours: 6
    },
];

/**
 * Row functional component for Accordion table
 */
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    // const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={'tableRow'}>
                <TableCell className={'tableIcon'}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} className={'iconBtn'}>
                        {open ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                    <strong>{row.projectname}</strong>
                </TableCell>
                <TableCell align="right">{row.time}</TableCell>
                <TableCell align="right">{row.amount ?
                    <div>Rs {row.amount}</div> : <div><RemoveIcon /></div>
                }</TableCell>
            </TableRow>
            <TableRow className={'tableCollapseRow'}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* <Box margin={1}> */}
                        <Table >
                            <TableBody>
                                <TableRow className={'collapsibleTable'}>
                                    {/* <TableCell  className={'emptyCell'}></TableCell> */}
                                    <TableCell component="th" scope="row" className={'employeeCell'}>{row.employee}</TableCell>
                                    <TableCell component="th" scope="row" className={'timeCell'}>{row.time}</TableCell>
                                    <TableCell component="th" scope="row" className={'amountCell'}>{row.amount ?
                                        <div>Rs {row.amount}</div> : <div>Rs 0.00</div>
                                    }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {/* </Box> */}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

/**
 * Functional component to render Project Tabs content 
 */
function ProjectTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`simple-tabpanel-${index}`}
            // aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

/**
 * Functional component to render Work Tabs content 
 */
function WorkTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`simple-tabpanel-${index}`}
            // aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default class TrackerProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            projectTab: 0,
            workTab: 0,
            selectionRange: [
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }
            ],
            isAccordionOpen: false,
            workSummaryData: [
                {
                    projectname: 'Onion Project Management',
                    time: '22:38',
                    amount: 0,
                    employee: 'XYZ',
                },
                {
                    projectname: 'Vote Do',
                    time: '42:38',
                    amount: 0,
                    employee: 'XYZ',
                },
                {
                    projectname: 'Transferboss',
                    time: '22:38',
                    amount: 0,
                    employee: 'XYZ',
                },
            ],
            worksheet: [
                {
                    project: 'Onion Project Management',
                    Nov3: '06:47',
                    Nov4: '09:47',
                    Nov5: '07:47',
                    Nov6: '06:47',
                    Nov7: '07:47',
                    Nov8: '06:47',
                    Nov9: '05:47',
                    Nov10: '04:47',
                },
                {
                    project: 'Vote Do',
                    Nov3: '06:47',
                    Nov4: '09:47',
                    Nov5: '07:47',
                    Nov6: '06:47',
                    Nov7: '07:47',
                    Nov8: '06:47',
                    Nov9: '05:47',
                    Nov10: '04:47',
                },
                {
                    project: 'Transferboss',
                    Nov3: '06:47',
                    Nov4: '09:47',
                    Nov5: '07:47',
                    Nov6: '06:47',
                    Nov7: '07:47',
                    Nov8: '06:47',
                    Nov9: '05:47',
                    Nov10: '04:47',
                }
            ]
        }
    }

    /**
     * function to handle the rendering of project tabs 
     */
    projectTabHandler = (e, value) => {
        this.setState({
            projectTab: value
        })
    }

    /**
     * function to handle the rendering of work tabs 
     */
    workTabHandler = (e, value) => {
        this.setState({
            workTab: value
        })
    }

    render() {
        return (
            <div className={'col-md-12'}>
                <div className={'trackerReports'}>
                    <div className={'header'}>
                        <h4>Reports</h4>
                        <p>Timezone: (-08:00) America - Los Angeles</p>
                    </div>
                    <div className={'btnHeader'}>
                        <div className={'btnGrp'}>
                            <DropdownButton id="dropdown-basic-button" title={<span> <WorkOutlineOutlinedIcon className={'icons'} /> All Projects (3)</span>} className={'btnDropdown'}>
                                <Dropdown.Item>All Projects</Dropdown.Item>
                                <Dropdown.Item>OPM</Dropdown.Item>
                                <Dropdown.Item>Transferboss</Dropdown.Item>
                                <Dropdown.Item>Vote Do</Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton id="dropdown-basic-button" title={<span> <PeopleAltOutlinedIcon className={'icons'} /> All Members (1)</span>} className={'btnDropdown'}>
                                <Dropdown.Item>All Members</Dropdown.Item>
                                <Dropdown.Item>ABC</Dropdown.Item>
                                <Dropdown.Item>XYZ</Dropdown.Item>
                                <Dropdown.Item>PQR</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        {/* <DateRangePicker
                            onChange={item => this.setState({
                                selectionRange: [item.selection]
                            })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={this.state.selectionRange}
                            direction="horizontal"
                        /> */}
                        <DropdownButton id="dropdown-basic-button" title={<span> <CalendarTodayOutlinedIcon className={'icons'} /> 3-10 Nov</span>} className={'btnDropdown'}>
                            <Dropdown.Item>All Projects</Dropdown.Item>
                            <Dropdown.Item>OPM</Dropdown.Item>
                            <Dropdown.Item>Transferboss</Dropdown.Item>
                            <Dropdown.Item>Vote Do</Dropdown.Item>
                        </DropdownButton>
                    </div>

                    <p className={'amtText'}><strong>Total amount is Rs 0.00</strong> - 53.34</p>

                    {/* Projects tab header starts here */}
                    <Tabs
                        value={this.state.projectTab}
                        onChange={this.projectTabHandler}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullwidth"
                        className={'tabsHeader'}
                    >
                        <Tab label="Projects" className={'tabs'} />
                        <Tab label="Members" className={'tabs'} />
                    </Tabs>
                    {/* Projects tab header ends here */}

                    {/* Projects tab content starts here */}
                    <ProjectTabPanel value={this.state.projectTab} index={0}>

                        {/* Bar Graph for Project Analysing */}
                        <BarChart
                            width={900}
                            height={500}
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="hours" fill="#8884d8" />
                            {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                        </BarChart>
                    </ProjectTabPanel>
                    <ProjectTabPanel value={this.state.projectTab} index={1}>

                        {/* Bar Graph for Member Analysing */}
                        <BarChart
                            width={900}
                            height={500}
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {/* <Bar dataKey="hours" fill="#8884d8" /> */}
                            <Bar dataKey="hours" fill="#82ca9d" />
                        </BarChart>
                    </ProjectTabPanel>
                    {/* Projects tab content ends here */}

                    <div className={'bottomSection'}>
                        {/* Work summary tab header starts here */}
                        <Tabs
                            value={this.state.workTab}
                            onChange={this.workTabHandler}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullwidth"
                            className={'tabsHeader'}
                        >
                            <Tab label="Work Summary" className={'tabs'} />
                            <Tab label="Timesheet" className={'tabs'} />
                            <Tab label="Activity Log" className={'tabs'} />
                        </Tabs>
                        {/* Work summary header ends here */}
                        <div className={'exportBtns'}>
                            <Button variant="outline-primary" className={'pdfBtn'}>Export as PDF</Button>
                            <Button variant="outline-primary">Export as CSV</Button>
                        </div>
                    </div>

                    <DropdownButton id="dropdown-basic-button" title="Shown By:-" className={'shownBy'}>
                        <Dropdown.Item>Projects</Dropdown.Item>
                        <Dropdown.Item>Members</Dropdown.Item>
                        <Dropdown.Item>Activity</Dropdown.Item>
                    </DropdownButton>
                    <hr />


                    {/* Work summary content starts here */}
                    <WorkTabPanel value={this.state.workTab} index={0}>
                        {/* <TableContainer component={Paper}> */}
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Project | Member</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.workSummaryData.map((data) => (
                                    <Row key={data.projectname} row={data} />
                                ))}
                            </TableBody>
                        </Table>
                        {/* </TableContainer> */}
                    </WorkTabPanel>
                    <WorkTabPanel value={this.state.workTab} index={1}>
                        <Table className={'worksheet'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell>Nov 04</TableCell>
                                    <TableCell>Nov 05</TableCell>
                                    <TableCell>Nov 06</TableCell>
                                    <TableCell>Nov 07</TableCell>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell>Nov 09</TableCell>
                                    <TableCell>Nov 10</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.worksheet.map((data) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row">{data.project}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov3}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov4}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov5}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov6}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov7}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov8}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov9}</TableCell>
                                            <TableCell component="th" scope="row">{data.Nov10}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                            <TableCell component="th" scope="row"><strong>Total</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                            <TableCell component="th" scope="row"><strong>12:00</strong></TableCell>
                                        </TableRow>
                            </TableBody>
                        </Table>
                    </WorkTabPanel>
                    <WorkTabPanel value={this.state.workTab} index={2}>
                        <Table className={'activityLog'} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Activity Name</TableCell>
                                    <TableCell>Member</TableCell>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Duration</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Screenshots</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 10</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 09</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 09</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 09</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 08</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Nov 03</TableCell>
                                    <TableCell component="th" scope="row">5:04pm – 7:09pm</TableCell>
                                    <TableCell component="th" scope="row">Tracker implementation</TableCell>
                                    <TableCell component="th" scope="row">Ashay Tiwari</TableCell>
                                    <TableCell component="th" scope="row">Onion Project Managem</TableCell>
                                    <TableCell component="th" scope="row">02:05</TableCell>
                                    <TableCell component="th" scope="row"><RemoveIcon /></TableCell>
                                    <TableCell component="th" scope="row">
                                        <PhotoCameraOutlinedIcon className={'screenshotIcon'} /> 0
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </WorkTabPanel>
                    {/* Work summary content ends here */}

                </div>
            </div>
        )
    }
}


