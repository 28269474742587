import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./Components/login/login";
import Signup from "./Components/signup/signup";
import dashboard from "./Components/dashboard/dashboard";
import AdminDashboard from "./Components/Admin/Admindashboard";
import { HashRouter } from "react-router-dom";
var login;
export default class App extends React.Component {
  userDetail = {};
  constructor() {
    super();
    if (localStorage.getItem("userdetail") !== null) {
      login = true;
      this.userDetail = JSON.parse(localStorage.getItem("userdetail"));
    } else {
      login = false;
    }
  }

  /**
   * function to enable and disable full screen
   */
  EnableDisableFullScreen() {
    /*let el = document.getElementById('main');
        el.requestFullscreen().then(r => {
            // console.log(r);
        }).catch(onerror => {
            console.error(onerror);
        })*/
  }

  render() {
    return (
      <div onDoubleClick={this.EnableDisableFullScreen.bind(this)} id="main">
        <Router basename="/">
          <HashRouter hashType={"noslash"}>
            <Switch>
              {" "}
              {/* this is will redirect on the basis of the login status that the user is logged in or not*/}{" "}
              <Route
                exact
                path="/"
                render={() =>
                  login ? (
                    this.userDetail.emptype === "Employee" ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/AdminDashboard" />
                    )
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/dashboard" component={dashboard} />
              <Route path="/AdminDashboard" component={AdminDashboard} />
            </Switch>
          </HashRouter>
        </Router>
      </div>
    );
  }
}
