import React from "react";
import "./header.css";
import { Accordion, Button, Tab, Tabs, Modal, Form } from "react-bootstrap";
import { BrowserRouter as Link, NavLink, withRouter } from "react-router-dom";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import {
  IconButton
} from '@material-ui/core';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

import Api from "../../Providers/Api";
var api = new Api();

class Header extends React.Component {
  userDetail = {};
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      isSubmenuOpen: false,
      isProfileModalOpen: false,
      isDocumentModalOpen: false
    };
    this.userDetail = JSON.parse(localStorage.getItem("userdetail"));
    this.state = {
      date: new Date().getDate(),
      day: new Date().getDay(),
      month: new Date().getMonth(),
      min: new Date().getMinutes(),
      hours: new Date().getHours(),
      year: new Date().getFullYear(),
      dayArray: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthArray: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
    setInterval(this.ChangeTime.bind(this), 1000);
  }

  userDetail = JSON.parse(localStorage.getItem('userdetail'));

  /**
   * function to change the time every second
   */
  ChangeTime() {
    this.setState({
      date: new Date().getDate(),
      day: new Date().getDay(),
      month: new Date().getMonth(),
      min: new Date().getMinutes(),
      hours: new Date().getHours(),
      year: new Date().getFullYear(),
    });
  }

  /**
   * function for logout
   */
  Logout = () => {
    localStorage.clear();
    this.props.history.push("/login");
  }
  // function to open the leave modal



  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  /**
   * function to handle the submenu modal
   */
  submenuHandler = () => {
    this.setState({
      isSubmenuOpen: !this.state.isSubmenuOpen
    })
  }

  /**
   * function to handle profile modal
   */
  profileModalHandler = () => {
    this.setState({
      isProfileModalOpen: !this.state.isProfileModalOpen
    })
  }

  /**
   * function to handle document modal
   */
  documentModalHandler = () => {
    this.setState({
      isDocumentModalOpen: !this.state.isDocumentModalOpen
    })
  }

  /**
   * function to navigate to setting page 
   */
  navigateToSetting = () => {
    console.log('Setting');
    this.props.history.push('/Setting');
  }

  render() {
    let boxClass = ["box"];
    if (this.state.addClass) {
      boxClass.push("arrows");
    }
    return (
      <header
        className={this.userDetail.emptype === "Admin" ? "adminHeader" : ""}
      >
        <label className="open-menu-mobile" htmlFor="open-menu-mobile">
          <i className="fas fa-bars"></i>
        </label>
        <input
          className="input-menu"
          id="open-menu-mobile"
          type="checkbox"
          name="menu"
        />
        <nav className="nav-content">
          <ul className="nav-content-list">
            {this.userDetail.emptype !== "Employee" ? (
              <li className="nav-content-item logout">
                <button onClick={() => this.Logout()}>
                  <a href="">
                    <span className="arebox">|</span>
                  Logout
                </a>
                </button>
              </li>
            ) : null}
            
            <li className="nav-content-item userProfile" onClick={() => this.submenuHandler()}>
              <div className="user">
                <img
                  src="../iron-man.jpg"
                  alt="user-img"
                  className="img-fluid"
                />
              </div>
              <div className="designation">
                <h6>
                  {this.userDetail.name}{" "}
                  {api.mode === "development" ? "(" + "Development" + ")" : ""}
                </h6>
                <p>Yay! you are here</p>
              </div>
            </li>

            {this.userDetail.emptype === "Employee" && (
              <NavLink to={"/dashboard"} className={"nav-content-item"}>
                Dashboards
              </NavLink>
            )}
            {this.userDetail.emptype === "Employee" && (
              <NavLink to="/journal" className={"nav-content-item"}>
                Journal
              </NavLink>
            )}
            {this.userDetail.emptype === "Employee" && (
              <NavLink to="/leaverequest" className={"nav-content-item"}>
                Leave Request
              </NavLink>
            )}
            {/* {this.userDetail.emptype === "Employee" && (
              <NavLink to="/messages" className={"nav-content-item"}>
                Messages
              </NavLink>
            )} */}
            {/* {this.userDetail.emptype === "Employee" && (
              <NavLink to="/projects" className={"nav-content-item cmSoon"}>
                Projects
                <div className={"cmstext"}>Coming Soon</div>
              </NavLink>
            )} */}
            {/* {this.userDetail.emptype === "Employee" && (
              <NavLink to="/userTasks" className={"nav-content-item"}>
                Tasks
              </NavLink>
            )}
            {this.userDetail.emptype === "Employee" && (
              <NavLink to="/tracker" className={"nav-content-item"}>
                Tracker
              </NavLink>
            )} */}

            {/* {this.userDetail.emptype === 'Employee' &&
              <NavLink to='/UserTask' className={'nav-content-item'}>Task</NavLink>
            }  */}
          </ul>
        </nav>
        {this.userDetail.emptype !== "Employee" && (
          <React.Fragment>
            {/* <h5 className={'devModeAdmin'}>{api.mode === "development" ? "Development Mode" : ""}</h5> */}
            <IconButton className={'settingButton'} onClick={() => this.navigateToSetting()}>
              <SettingsIcon className={'settingIcon'} />
            </IconButton>
            <div className="rightTime float-right">
              <h5>
                {this.state.monthArray[this.state.month] + " " + this.state.year}
              </h5>
              <p>
                {this.state.dayArray[this.state.day] +
                  ", " +
                  (this.state.hours >= 10
                    ? this.state.hours
                    : "0" + this.state.hours) +
                  ":" +
                  (this.state.min >= 10 ? this.state.min : "0" + this.state.min)}
              </p>
            </div>
          </React.Fragment>
        )}

        {/* Profile modal starts here */}
        <Modal
          show={this.state.isProfileModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-70w"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={() => this.profileModalHandler()}
        >
          <section className={"profilemodal"}>
            <div className={"row pb-2"}>
              <div className={"col-sm-3"}>
                <h6>
                  Personal Info <i className="fas fa-pen penIcon"></i>
                </h6>
              </div>
              <div className={"col-sm-9"}>
                <div
                  className="float-right mb-1 text-right"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    className={"approve_reject_button btn btn-reject btnDay"}
                  >
                    Save{" "}
                  </button>
                  <button
                    className={"approve_reject_button btn btn-approve btnDay"}
                    onClick={() =>
                      this.setState({
                        profileModal: false,
                      })
                    }
                  >
                    Save and close{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-sm-4 userleftSec"}>
                <img
                  src="../user-img.png"
                  alt="user-img"
                  className="img-fluid"
                />
                <h6>Dummy User</h6>
                <p>Full Time</p>
                <p>Joined: 01/01/2019</p>
              </div>
              <div className={"col-sm-8  pr-5"}>
                <div className={"row"}>
                  <div className={"col-sm-6"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="Designation" type="text" />
                    </Form>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-sm-4"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="DOB*" type="text" />
                    </Form>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Permanent Address"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}>
                    <select class="mdb-select md-form">
                      <option value="" disabled selected>
                        Gender
                        </option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Correspondence Address"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Aadhar Number*"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="Bank Name" type="text" />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="E mail id" type="text" />
                    </Form>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Bank Account Number"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Marital Status"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="IFSC Code" type="text" />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}>
                    <Form className={"form"}>
                      <Form.Control
                        placeholder="Anniversary Date"
                        type="text"
                      />
                    </Form>
                  </div>
                  <div className={"col-sm-8"}>
                    <Form className={"form"}>
                      <Form.Control placeholder="Branch Name" type="text" />
                    </Form>
                  </div>
                  <div className={"col-sm-4"}></div>
                  <div className={"col-sm-8"}>
                    <div className={"row"}>
                      <div className={"col-sm-6"}>
                        <Form className={"form"}>
                          <Form.Control
                            placeholder="Phone Number 1"
                            type="number"
                          />
                        </Form>
                      </div>
                      <div className={"col-sm-6"}>
                        <Form className={"form"}>
                          <Form.Control
                            placeholder="Phone Number 2"
                            type="number"
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        {/* Profile modal ends here */}

        {/* Document modal starts here */}
        <Modal
          show={this.state.isDocumentModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.documentModalHandler()}
        >
          <section className={"documentModal"}>
            <h6>Documents of Company</h6>
            <Form className={"documentForm"}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Leave Policy</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Terms of Employment</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Other Document</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Privacy Policy</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Terms and Conditions</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
            </Form>
          </section>
        </Modal>
        {/* Document modal ends here */}

        {/* Submenu modal starts here */}
        {
          (this.userDetail.emptype === "Employee" && this.state.isSubmenuOpen) ? (
            <div className={'submenu'}>
              <IconButton onClick={() => this.submenuHandler()} className={'closeBtn'}>
                <ClearOutlinedIcon className={'icons'} />
              </IconButton>
              <div className={'company'}>
                <div className={"company-logo"}>
                  <img
                    src="../companylogo.png"
                    alt="user-img"
                    className={"img-fluid"}
                  />
                </div>
                <div>
                  <h5>OPM</h5>
                  <h6>Kritin Digital Solutions Pvt. Ltd.</h6>
                </div>
              </div>
              <hr />
              <p className={'text-center emailText'}>You are signed in as {this.userDetail.emailoffice}</p>
              <div className={'userSec'}>
                <div className={"user"}>
                  <img
                    src="../iron-man.jpg"
                    alt="user-img"
                    className={"img-fluid userLogo"}
                  />
                </div>
                <div>
                  <h6>{this.userDetail.name}</h6>
                </div>
              </div>
              <hr />
              <ul className={'submenuNav'}> 

                {/* <li className={'navLink'} onClick={() => this.profileModalHandler()}>
                  <PermIdentityOutlinedIcon className={'icons'} />
                  <h6>Profile</h6>
                </li> */}

                <li className={'navLink'} onClick={() => this.documentModalHandler()}>
                  <PostAddOutlinedIcon className={'icons'} />
                  <h6>Documents</h6>
                </li>

                <li className={'navLink'} onClick={() => this.Logout()}>
                  <a href="">
                    <ExitToAppOutlinedIcon className={'icons'} />
                    <h6>Logout</h6>
                  </a>
                </li>

              </ul>
            </div>
          ) : null
        }
        {/* Submenu modal ends here */}

      </header>
    );
  }
}

export default withRouter(Header);
