import React, { Component } from 'react';
import './MessageGroupView.css';
import PeopleIcon from '@material-ui/icons/People';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Avatar,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Chip
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import Api from "../../../Providers/Api";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';


/**
 * functional component to render chat history
 */
function ChatHistory(chatHistory) {
    const userDetail = JSON.parse(localStorage.getItem('userdetail'));
    // console.log(chatHistory);
    return (
        // <h2>from chat history</h2>
        chatHistory.chat.map((chatData, index) => (
            <div key={index}
                className={
                    userDetail.empid === chatData.sender_empid ? 'userSent' : 'friendSent'
                }>
                {
                    userDetail.empid !== chatData.sender_empid ? (
                        <React.Fragment>
                            <span className={'senderName'}>
                                {chatData.sender_name}
                            </span><br />
                        </React.Fragment>
                    ) : null
                }
                {chatData.chat}
                <br />
                <span className={'timeStamp'}>
                    {chatData.send_time}
                </span>
            </div>
        ))
    )
}

const api = new Api();


class MessageGroupView extends Component {

    constructor() {
        super();

        this.state = {
            loadMoreCounter: 1,
            isGroupSettingsModalOpen: false,
            groupName: '',
            groupInformation: {},
            groupInfoLoading: false,
            groupMembers: [],
            isEdit: false,
            employeeList: [],
            updatedEmplList: [],
            updatedEmplListId: [],
            groupId: ''
        }
    }
    userDetail = JSON.parse(localStorage.getItem('userdetail'));

    /**
     * components lifecycle methods to handle the scrolling of msgView-container
     */
    componentDidMount = () => {
        const container = document.getElementById("msgView-container");
        if (container) container.scrollTo(0, container.scrollHeight);
    };
    // componentDidUpdate = () => {
    //   const container = document.getElementById("msgView-container");
    //   if (container) container.scrollTo(0, container.scrollHeight);
    // };

    /**
     * function to increment the laod more counter
     */
    loadMoreCounter = () => {
        this.setState({
            loadMoreCounter: this.state.loadMoreCounter + 1
        }, () => {
            console.log("Load more counter", this.state.loadMoreCounter);
            this.props.getMsgView(this.state.loadMoreCounter);
        });
    }

    /**
     * function to handle the group settings modal
     */
    groupSettingsHandler = () => {
        this.setState({
            isGroupSettingsModalOpen: !this.state.isGroupSettingsModalOpen
        });
        this.getGroupDetails();
        // console.log(this.props.recieverId, "group id");
    }

    /**
     * function to get the details of group
     */
    getGroupDetails = () => {
        this.setState({
            groupInfoLoading: true
        })
        const param = `/ShowDetailOfGroup?groupid=${this.props.recieverId}`;
        api
            .getApi(param)
            .then((res) => {
                console.log(res);
                this.setState({
                    groupInformation: res.data.response[0],
                    groupInfoLoading: false,
                    groupMembers: res.data.response[0].emp_name,
                    groupName: res.data.response[0].group_name,
                    groupId: res.data.response[0].group_id
                }, () => {
                    this.getEmployeeList();
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    groupInfoLoading: false
                })
            });
    }

    /**
     * function to handle the edit section
     */
    handleEditSection = () => {
        this.setState({
            isEdit: !this.state.isEdit,
            groupName: this.props.recieverName
        }, () => {
            this.editEmployeeList();
        });
        
    }

    /**
   * function to get the list of all employees
   */
    async getEmployeeList() {
        const param = `/ShowEmployeeData?employeeId=${this.userDetail.empid}`;
        // console.log(this.userDetail.empid);
        await api
            .getApi(param)
            .then((res) => {
                console.log(res);
                this.setState({
                    employeeList: res.data.response,
                }, () => this.editEmployeeList())
            })
            .catch((err) => {
                console.log(err);
            })
    }

    /**
     * function to edit the employee list
     */
    editEmployeeList = () => {
        // console.log(this.state.employeeList, this.state.groupMembers);
        this.state.updatedEmplList.splice(0, this.state.updatedEmplList.length);
        for(let empl = 0; empl < this.state.employeeList.length; empl++){
            for(let grpM = 0; grpM < this.state.groupMembers.length; grpM++){
                if(this.state.employeeList[empl].name === this.state.groupMembers[grpM]){
                    this.state.updatedEmplList.push(this.state.employeeList[empl]);
                    break;
                }
                else{
                    continue;
                }
            }
        }
        console.log(this.state.updatedEmplList);
    }

    /**
       * function to handle the multi-select dropdown of employees
       */
      selectEmployeesHandler = (newValue) => {
        this.setState({
            updatedEmplList: newValue
        },
        () => {
            // console.log(this.state.updatedEmplList);
            this.state.updatedEmplListId.splice(0, this.state.updatedEmplListId.length);
            // this.state.selectedEmployees.splice(0, this.state.selectedEmployees.length);
            for (let empIndex = 0; empIndex < this.state.updatedEmplList.length; empIndex++) {
                this.state.updatedEmplListId.push(this.state.updatedEmplList[empIndex].empid);
            }
            console.log(this.state.updatedEmplList, this.state.updatedEmplListId);
        })
    }

    /**
     * function to update the group settings
     */
    updateGroup = (e) => {
        e.preventDefault();
        let count = 0;
        for(let emplId = 0; emplId < this.state.updatedEmplListId.length; emplId++){
            if(this.userDetail.empid === this.state.updatedEmplListId[emplId]){
                count = 0;
                break;
            }
            else{
                count++;
            }
        }

        if(count > 0){
            this.state.updatedEmplListId.push(this.userDetail.empid);
        }
        // console.log(this.state.updatedEmplListId);

        if (this.state.updatedEmplListId.length !== 0 && this.state.groupName) {
            const params = {
                api: '/updateChatGroup',
                data: {
                    group_id: this.state.groupId,
                    emp_id: this.state.updatedEmplListId,
                    admin_id: this.userDetail.empid,
                    name: this.state.groupName
                }
            }
            console.log(params);
            api.postApi(params)
                .then((res) => {
                    console.log(res);
                    if(res.data.response === "Group Updated Successfully"){
                        this.setState({
                            isEdit: false
                        })
                        const notifyGroupUpdatedSuccess = () => {
                            toast.success(res.data.response, {
                                position: "top-center",
                                autoclose: 10000,
                            })
                        };
                        notifyGroupUpdatedSuccess();
                        this.getGroupDetails();
                    }
                    else{
                        const notifyGroupUpdatedError = () => {
                            toast.error(res.data.response, {
                                position: "top-center",
                                autoclose: 10000,
                            })
                        };
                        notifyGroupUpdatedError();
                    }
                    
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else{
            const notifyGroupEmptyValueError = () => {
                toast.error('Group cannot be created for empty values!', {
                    position: "top-center",
                    autoclose: 10000,
                })
            };
            notifyGroupEmptyValueError();
        }

    } 

    /**
     * function to handle the cancel button of edit box
     */
    cancelUpdation = () => {
        this.setState({
            isEdit: false,
            groupName: this.props.recieverName,
        }, () => this.editEmployeeList())
    }
    
    render() {
        const { classes, chat, recieverName, recieverStatus, recieverLogo } = this.props;
        // console.log(chat);
        return (
            <React.Fragment>
                <div className={'msgView'}>
                    <div className={'header'}>
                        <div className={'avatarSection'}>
                            <Avatar className={'avatar'}>
                                <PeopleIcon />
                            </Avatar>
                            <h5>{recieverName}</h5>
                        </div>
                        <div className={'iconBtnGroup'}>
                            <IconButton>
                                <SettingsIcon className={'icon'} onClick={() => this.groupSettingsHandler()} />
                            </IconButton>
                            <IconButton>
                                <CloseIcon className={'icon'} onClick={() => this.props.closeFn()} />
                            </IconButton>
                        </div>
                    </div>
                    <div className={'msgView-container'} id={'msgView-container'}>

                        {chat.length === 0 ? (
                            <div className={'noChatMsg'}>
                                <h6>No Chat Yet</h6>
                            </div>
                        ) : (
                                <div className={'chatHistory'}>
                                    <Button color="primary" onClick={() => this.loadMoreCounter()} className={'loadBtn'}>Load more...</Button>
                                    <ChatHistory
                                        chat={chat} />
                                </div>
                            )}
                    </div>
                </div>

                {/* Update Group Settings Modal starts here */}
                <Dialog
                    onClose={() => this.groupSettingsHandler()}
                    className={'groupSettingsModal'}
                    open={this.state.isGroupSettingsModalOpen}
                    fullWidth={true}
                    maxWidth={'sm'}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>
                        <div className={'header'}>
                            <div className={'avatarSection'}>
                                <Avatar className={'avatar'}><PeopleIcon /></Avatar>
                                <h5>{recieverName}</h5>
                            </div>
                            <div className={'iconBtnGroup'}>
                                {
                                    this.state.groupInformation.admin_id === this.userDetail.empid ? (
                                        <IconButton>
                                            <EditIcon className={'icon'} onClick={() => this.handleEditSection()} />
                                        </IconButton>
                                    ) : null
                                }
                                <IconButton>
                                    <CloseIcon className={'icon'} onClick={() => this.groupSettingsHandler()} />
                                </IconButton>
                            </div>
                        </div>
                        <hr />
                        <p className={'groupCreation'}>
                            Created by  
                            {
                                this.userDetail.name === this.state.groupInformation.admin_name ? ' You' : 
                                this.state.groupInformation.admin_name
                            }, {this.state.groupInformation.createdate}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.groupInfoLoading ? (
                            <div className={'groupInfoLoading'}>
                                <CircularProgress />
                            </div>
                        ) : (
                                <div className={'dialogContent'}>
                                    {
                                        this.state.isEdit ? (
                                            <div className={'editBox'}>
                                                <h6>Change Group Settings</h6>
                                                <hr />
                                                <div className={'row'}>
                                                    <div className={'col-md-4'}>
                                                        <p className={'editLabel'}>Change Group Name</p>
                                                    </div>
                                                    <div className={'col-md-8'}>
                                                        <TextField
                                                            id="standard"
                                                            placeholder="Enter your group's name"
                                                            className={'modalInput'}
                                                            value={this.state.groupName}
                                                            onChange={(e) => this.setState({ groupName: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className={'col-md-4'}>
                                                        <p className={'editLabel'}>Add New Members</p>
                                                    </div>
                                                    <div className={'col-md-8'}>
                                                        <Autocomplete
                                                            multiple
                                                            value={this.state.updatedEmplList}
                                                            onChange={(e, newValue) => this.selectEmployeesHandler(newValue)}
                                                            renderTags={(tagValue, getTagProps) =>
                                                                tagValue.map((option, index) => (
                                                                  <Chip
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                    // disabled={fixedOptions.indexOf(option) !== -1}
                                                                  />
                                                                ))
                                                              }
                                                            id="tags-standard"
                                                            className={'modalInput'}
                                                            options={this.state.employeeList}
                                                            getOptionLabel={(option) => option.name}
                                                            filterSelectedOptions
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                    placeholder="Enter your friend's name"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'editFooter float-right'}>
                                                    <button className={'cancelBtn'} onClick={() => this.cancelUpdation()}>Cancel</button>
                                                    <button className={'createBtn'} onClick={(e) => this.updateGroup(e)}>Update</button>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    <div className={'participants'}>
                                        <h6>{this.state.groupMembers.length} Participants</h6>
                                        <hr />
                                        <ul>
                                            {
                                                this.state.groupMembers.map((empl) => (
                                                    <li className={'participantsList'} key={empl}>
                                                        <div className={'row'}>
                                                            <div className={'col-md-9 avatarSection'}>
                                                                <Avatar className={'avatar'}>{empl.charAt(0)}</Avatar>
                                                                {
                                                                    this.userDetail.name === empl ? <h6>You</h6> : <h6>{empl}</h6>
                                                                }
                                                            </div>
                                                            <div className={'col-md-3'}>
                                                                {
                                                                    this.state.groupInformation.admin_name === empl ? (
                                                                        <div className={'adminBlock'}>Group Admin</div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )}
                    </DialogContent>
                </Dialog>
                {/* Update Group Settings Modal ends here */}

            </React.Fragment>
        )
    }
}

export default MessageGroupView;

