import React, { Component } from 'react';
import './MessageBox.css';
import { withStyles } from "@material-ui/core";
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    CircularProgress,
    LinearProgress
} from '@material-ui/core';
import MessageList from './MessageList/MessageList';
import MessageTextBox from './MessageTextBox/MessageTextBox';
import MessageView from './MessageView/MessageView';
import NewMessage from './NewMessage/NewMessage';
import MessageGroupView from './MessageGroupView/MessageGroupView';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CreateIcon from '@material-ui/icons/Create';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import Api from "../../Providers/Api";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';


const api = new Api();

export default class MessageBox extends Component {

    constructor() {
        super();

        this.state = {
            selectedChat: null,
            newChatFormVisible: false,
            email: null,
            friends: [],
            chats: [],
            isMsgMenuOpen: false,
            isNewMsgOpen: false,
            isNewMsgExpanded: false,
            isAlreadyChat: false,
            recieverId: 0,
            chat: [],
            recieverName: '',
            recieverLogo: '',
            recieverStatus: '',
            newGroupMenu: null,
            isGroupCreationModalOpen: false,
            isGroupChatOpen: false,
            selectedEmployees: [],
            selectedEmployeesId: [],
            employeeList: [],
            groupLoading: false,
            groupCreationLoading: false,
            groupName: '',
            groupMembers: []
        }
        this.getEmployeeList();
    }

    userDetail = JSON.parse(localStorage.getItem('userdetail'));

    /**
     * function to handle the Message Menu Box
     */
    messageMenuBoxHandler = () => {
        this.setState({
            isMsgMenuOpen: !this.state.isMsgMenuOpen
        });
    }

    /**
     * function to handle the Writing Panel
     */
    messageWritingPanelHandler = () => {
        this.setState({
            isNewMsgOpen: !this.state.isNewMsgOpen,
            isNewMsgExpanded: false,
            isAlreadyChat: false,
            isGroupChatOpen: false
        });
        // this.newChatBtnClicked();
    }

    /**
     * function to handle the expansion of writing panel
     */
    messageWritingPanelExapnsion = () => {
        this.setState({
            isNewMsgExpanded: !this.state.isNewMsgExpanded
        });
    }

    /**
     * function to handle the Group Chat Pannel
     */
    groupChatPanelHandler = () => {
        this.setState({
            isGroupChatOpen: !this.state.isGroupChatOpen,
            isNewMsgOpen: false,
            isNewMsgExpanded: false,
            isAlreadyChat: false
        })
    }

    /**
     * function to handle the close panel button
     */
    closeHandler = () => {
        this.setState({
            isNewMsgOpen: false,
            isNewMsgExpanded: false,
            isAlreadyChat: false,
            isGroupCreationModalOpen: false,
            isGroupChatOpen: false
        })
    }

    /**
     * function to get the recieverId from Message List comp. 
     */
    getRecieverId = (reciId, reciName, reciLogo, reciStatus) => {
        if (reciStatus === 'Group') {
            this.setState({
                isGroupChatOpen: true,
                isAlreadyChat: false
            })
        }
        else {
            this.setState({
                isGroupChatOpen: false,
                isAlreadyChat: true
            })
        }
        this.setState({
            recieverId: reciId,
            recieverName: reciName,
            recieverLogo: reciLogo,
            recieverStatus: reciStatus,
        }, () => {
            this.getMessageView();
        });
    }

    /**
     * function to get the chat history of clicked chat or clicked grp of message list
     */
    getMessageView = (count) => {
        this.setState({
            chat: []
        });
        if (this.state.recieverStatus !== 'Group') {
            const param = `/ShowEmployeeChatHistory?sender_empid=${this.userDetail.empid}&receiver_empid=${this.state.recieverId}`;
            api
                .getApi(param)
                .then((res) => {
                    // console.log(res);
                    this.setState({
                        chat: res.data.response
                    })
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            if(typeof count === "undefined"){
                count = 1;
            }
            console.log("Count from parent", count);
            const param = `/ShowEmployeeGroupChatHistory?group_id=${this.state.recieverId}&count=${count}`;
            api
                .getApi(param)
                .then((res) => {
                    console.log(res);
                    this.setState({
                        chat: res.data.response
                    })
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    /**
     * function to handle the new group creation modal 
     */
    groupCreationModalHandler = () => {
        this.setState({
            isGroupCreationModalOpen: !this.state.isGroupCreationModalOpen,
            newGroupMenu: null,
            isNewMsgOpen: false,
            isNewMsgExpanded: false,
            isAlreadyChat: false,
            selectedEmployees: [],
            selectedEmployeesId: []
        });
    }

    /**
   * function to get the list of all employees
   */
    async getEmployeeList() {
        this.setState({
            groupLoading: true
        })
        const param = `/ShowEmployeeData?employeeId=${this.userDetail.empid}`;
        // console.log(this.userDetail.empid);
        await api
            .getApi(param)
            .then((res) => {
                // console.log('Empl list', res);
                this.setState({
                    employeeList: res.data.response,
                    groupLoading: false
                })
            })
            .catch((err) => {
                console.log(err);
                window.alert(JSON.stringify(err));
                this.setState({
                    groupLoading: false
                })
            })
    }

    /**
   * function to handle the multi-select dropdown of employees
   */
    selectEmployeesHandler = (newValue) => {
        this.setState({
            selectedEmployees: newValue
        }, () => {
            this.state.selectedEmployeesId.splice(0, this.state.selectedEmployeesId.length);
            // this.state.selectedEmployees.splice(0, this.state.selectedEmployees.length);
            for (let empIndex = 0; empIndex < this.state.selectedEmployees.length; empIndex++) {
                this.state.selectedEmployeesId.push(this.state.selectedEmployees[empIndex].empid);
            }
        })
    }

    /**
     * function to create the Chat group 
     */
    CreateGroupChat(e) {
        e.preventDefault();

        this.setState({
            groupCreationLoading: true
        });

        if (this.state.selectedEmployeesId.length !== 0 && this.state.name !== '') {
            let empId = this.userDetail.empid;
            this.state.selectedEmployeesId.push(empId);

            const param = {
                api: '/AddEmployeeChatGroup',
                data: {
                    emp_id: this.state.selectedEmployeesId,
                    admin_id: empId,
                    name: this.state.groupName
                }
            };

            api.postApi(param)
                .then(res => {
                    // console.log(res);
                    if (res.data.response === "Group Created Successfully") {
                        const notifyGroupCreatedSuccess = () => {
                            toast.success(res.data.response, {
                                position: "top-center",
                                autoclose: 10000,
                            })
                        };
                        notifyGroupCreatedSuccess();
                        this.setState({
                            groupCreationLoading: false,
                            isGroupCreationModalOpen: false
                        });
                    }
                    else {
                        const notifyGroupCreatedError = () => {
                            toast.error(res.data.response, {
                                position: "top-center",
                                autoclose: 10000,
                            })
                        };
                        notifyGroupCreatedError();
                        this.setState({
                            groupCreationLoading: false
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        groupCreationLoading: false
                    });
                });
        }
        else {
            const notifyGroupEmptyValueError = () => {
                toast.error("Group cannot be created for empty values!", {
                    position: "top-center",
                    autoclose: 10000,
                })
            };
            notifyGroupEmptyValueError();
            this.setState({
                groupCreationLoading: false
            });
        }
    }

    /**
     * calling getEmployeeList function after every 5 seconds
     */
    // componentDidMount() {
    //     setInterval(() => {
    //         // console.log('call api');
    //         this.getMessageView();
    //     }, 5000);
    // }

    render() {
        const { classes } = this.props;

        return (
            <div className={"row messageContainer"}>
                <ToastContainer />
                <div className={"col-md-12"}>

                    {/* Msg Box settings popup starts here */}
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.newGroupMenu}
                        keepMounted
                        open={Boolean(this.state.newGroupMenu)}
                        onClose={() => this.setState({ newGroupMenu: null })}
                    >
                        <MenuItem onClick={() => this.groupCreationModalHandler()}>New Group Conversation</MenuItem>
                        {/* <MenuItem>Chat List</MenuItem>
                        <MenuItem>Group Settings</MenuItem> */}
                    </Menu>
                    {/* Msg Box settings popup starts here */}


                    {/* code for Message List starts here */}
                    {
                        this.state.isMsgMenuOpen ? (
                            <div className={'messageMenuBox'}>
                                <div className={'messageHeader'}>
                                    <div className={'avatarContent'}>
                                        <Avatar alt={this.userDetail.name} src="../iron-man.jpg" className={'avatar'} />
                                        <p className={'heading'}><strong>Messaging</strong></p>
                                    </div>
                                    <div className={'buttonSection'}>
                                        <IconButton className={'iconBtn'} onClick={() => this.messageWritingPanelHandler()}>
                                            <CreateIcon className={'icon'} />
                                        </IconButton>
                                        <IconButton className={'iconBtn'} onClick={(e) => this.setState({ newGroupMenu: e.currentTarget })}>
                                            <MoreHorizIcon className={'icon'} />
                                        </IconButton>
                                        <IconButton className={'iconBtn'} onClick={() => this.messageMenuBoxHandler()}>
                                            {
                                                this.state.isMsgMenuOpen ? <KeyboardArrowDownIcon className={'icon'} /> : <KeyboardArrowUpIcon className={'icon'} />
                                            }
                                        </IconButton>
                                    </div>
                                </div>

                                <div className={'messageBody'}>
                                    <MessageList
                                        getId={this.getRecieverId}
                                    />
                                </div>
                            </div>
                        ) : (
                                <div className={'messageFloater'}>
                                    <div className={'avatarContent'}>
                                        <Avatar alt={this.userDetail.name} src="../iron-man.jpg" className={'avatar'} />
                                        <p className={'heading'}><strong>Messaging</strong></p>
                                    </div>
                                    <div className={'buttonSection'}>
                                        <IconButton className={'iconBtn'} onClick={() => this.messageWritingPanelHandler()}>
                                            <CreateIcon className={'icon'} />
                                        </IconButton>
                                        <IconButton className={'iconBtn'} onClick={(e) => this.setState({ newGroupMenu: e.currentTarget })}>
                                            <MoreHorizIcon className={'icon'} />
                                        </IconButton>
                                        <IconButton className={'iconBtn'} onClick={() => this.messageMenuBoxHandler()}>
                                            {
                                                this.state.isMsgMenuOpen ? <KeyboardArrowDownIcon className={'icon'} /> : <KeyboardArrowUpIcon className={'icon'} />
                                            }
                                        </IconButton>
                                    </div>
                                </div>
                            )
                    }
                    {/* code for Message List ends here */}

                    {/* code for new msg block starts here */}
                    {
                        this.state.isNewMsgOpen ? (
                            this.state.isNewMsgExpanded ? (
                                <div className={'expandedWritingPanel'}>
                                    <div className={'header'}>
                                        <p className={'heading'}><strong>New message</strong></p>
                                        <div className={'buttonSection'}>
                                            <IconButton className={'iconBtn'} onClick={() => this.messageWritingPanelExapnsion()}>
                                                <FullscreenExitIcon className={'icon'} />
                                            </IconButton>
                                            <IconButton className={'iconBtn'}>
                                                <CloseIcon className={'icon'} onClick={() => this.closeHandler()} />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className={'newMsgBody'}>
                                        <NewMessage />
                                    </div>
                                </div>
                            ) : (
                                    <div className={'writingPanel'}>
                                        <div className={'header'}>
                                            <p className={'heading'}><strong>New message</strong></p>
                                            <div className={'buttonSection'}>
                                                <IconButton className={'iconBtn'} onClick={() => this.messageWritingPanelExapnsion()}>
                                                    <FullscreenIcon className={'icon'} />
                                                </IconButton>
                                                <IconButton className={'iconBtn'} onClick={() => this.closeHandler()} >
                                                    <CloseIcon className={'icon'} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <div className={'newMsgBody'}>
                                            <NewMessage />
                                        </div>
                                    </div>
                                )
                        ) : null
                    }
                    {/* code for new msg block ends here */}

                    {/* code for selected chat starts here */}
                    {
                        this.state.isAlreadyChat ? (
                            <div className={'writingPanel chatBox'}>
                                <MessageView
                                    chat={this.state.chat}
                                    recieverName={this.state.recieverName}
                                    recieverLogo={this.state.recieverLogo}
                                    closeFn={this.closeHandler}
                                    getMsgView={this.getMessageView}
                                />
                                <div className={'msgTextBox'}>
                                    <MessageTextBox
                                        recieverId={this.state.recieverId}
                                        getMsgView={this.getMessageView}
                                    />
                                </div>

                            </div>
                        ) : null
                    }
                    {/* code for selected chat ends here */}

                    {/* Code for group chat starts here */}
                    {
                        this.state.isGroupChatOpen ? (
                            <div className={'writingPanel chatBox'}>
                                <MessageGroupView
                                    chat={this.state.chat}
                                    recieverName={this.state.recieverName}
                                    recieverStatus={this.state.recieverStatus}
                                    closeFn={this.closeHandler}
                                    getMsgView={this.getMessageView}
                                    recieverId={this.state.recieverId}
                                    getGroupMembers={this.getGrpMembers}
                                />
                                <div className={'msgTextBox'}>
                                    <MessageTextBox
                                        recieverId={this.state.recieverId}
                                        getMsgView={this.getMessageView}
                                        recieverStatus={this.state.recieverStatus}
                                        recieverName={this.state.recieverName}
                                    />
                                </div>
                            </div>
                        ) : null
                    }
                    {/* Code for group chat ends here */}


                    {/* code for new group creation modal starts here */}
                    <Dialog
                        onClose={() => this.groupCreationModalHandler()}
                        className={'newGroupCreationModal'}
                        open={this.state.isGroupCreationModalOpen}
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle>
                            <h5>Create Group Chat Conversation</h5>
                            <hr />
                        </DialogTitle>
                        {
                            this.state.groupLoading ? (
                                <div className={'loadingContainer'}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                    <DialogContent>
                                        <div className={'row modalBody'}>
                                            <div className={'col-md-4'}>
                                                <p className={'modalLabel'}>Group Name</p>
                                            </div>
                                            <div className={'col-md-8'}>
                                                <TextField
                                                    id="standard"
                                                    placeholder="Enter your group's name"
                                                    className={'modalInput'}
                                                    value={this.state.groupName}
                                                    onChange={(e) => this.setState({ groupName: e.target.value })}
                                                />
                                            </div>
                                            <div className={'col-md-4'}>
                                                <p className={'modalLabel'}>Group Members</p>
                                            </div>
                                            <div className={'col-md-8'}>
                                                <Autocomplete
                                                    multiple
                                                    value={this.state.selectedEmployees}
                                                    onChange={(e, newValue) => this.selectEmployeesHandler(newValue)}
                                                    id="tags-standard"
                                                    className={'modalInput'}
                                                    options={this.state.employeeList}
                                                    getOptionLabel={(option) => option.name}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Enter your friend's name"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={'modalFooter float-right'}>
                                            <button className={'cancelBtn'} onClick={() => this.groupCreationModalHandler()}>Cancel</button>
                                            <button className={'createBtn'} onClick={(e) => this.CreateGroupChat(e)}>Create</button>
                                        </div>
                                    </DialogContent>
                                )
                        }
                    </Dialog>
                    {/* code for new group creation modal ends here */}

                </div>
            </div>
        );

    }
}
