import React from 'react'; 
import './Toptracker.css';  

export default class Toptracker extends React.Component{
    
    render() {
        return (
             <section className={'toptracker'}>
               Hello tracker
             </section>
        )
    }
}
