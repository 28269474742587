import React from "react";
import Header from "../header/header";
import "./dashboard.css";
import { Tabs, Tab, Modal } from "react-bootstrap";
import Api from "../../Providers/Api";
import AttendanceCard from "../attendance card/attendance_card";
import Calendar from "react-calendar";
import Holidays from "../holidays/holidays";
import { BounceLoader } from "react-spinners";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { css } from "@emotion/react";
import { Toast } from "react-bootstrap";
import LeaveRequest from "../Leave-Request/leave-request";
import Tracker from "../Tracker/Tracker";
import WebNotification from "react-web-notifications";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  Redirect,
} from "react-router-dom";
import UserJournal from "../Journal-Details/userJournal/userJournal";
import UserProjects from "../UserProjects/UserProjects";
import UserTask from "../UserTask/UserTask";
import { Messages } from "../Chat";
import MessageBox from '../MessageBox/MessageBox';
// import UserTask from '../UserTask/UserTask';

const loaderCSS = css`
  display: flex;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  transform: "translate(-50%, -50%) !important";
`;
const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#dad9e9",
    display: "flex",
    justifyContent: "center",
  },
};
var holidaysJSON = [];
const api = new Api();
var attendancelist = [];
export default class dashboard extends React.Component {
  employeeDetail = {};
  showConfirm = false;
  checkinStatus = false;
  checkout = true;
  timeInterval;
  constructor() {
    super();
    this.ShowHoliday();
    const date = new Date();
    this.state = {
      MonthArray: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      loading: true,
      Hours: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds(),
      date: date.getDate(),
      year: date.getFullYear(),
      time: 0,
      modalIsOpen: false,
      taskJson: [],
      newtask: "",
      taskloading: false,
      tid: 0,
    };
    api.ProjectLogin();
    setInterval(this.ChangeTime.bind(this), 1000);
    this.employeeDetail = JSON.parse(localStorage.getItem("userdetail"));
    this.ShowHistory();
    this.GetUserStatus();
    localStorage.setItem("LeaveRequest", false);
    document.addEventListener("keypress", this.closeTask.bind(this));
  }

  /**
   * function to close the task
   */
  closeTask(ev) {
    // console.log(ev);
    if (ev.type === "keypress") {
      if (ev.keyCode === 127) {
        this.setState({
          modalIsOpen: false,
        });
      }
    } else if (this.state.modalIsOpen) {
      this.setState({
        modalIsOpen: !this.state.modalIsOpen,
      });
    }
  }
  /**
   * function to show the timer onto the toaster
   */
  ShowTime() {
    this.setState({
      time: this.state.time + 1,
    });
  }
  /**
   * get Week history of the user
   */
  ShowHistory() {
    this.setState({
      loading: true,
    });
    const param =
      "/ShowEmployeeTimeInOutHistory?empid=" + this.employeeDetail.empid;
    api
      .getApi(param)
      .then((res) => {
        // console.log('response from history api: ', res);
        attendancelist = res.data.response.reverse();
        this.setState({
          loading: false,
        });
        // console.log('attendance list', attendancelist);
      })
      .catch((err) => {
        console.error("error from history api:", err);
        window.alert(JSON.stringify(err));
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * function to ge the last status of the user
   */
  GetUserStatus() {
    const param = "/CheckEmployeeStatus?empid=" + this.employeeDetail.empid;
    api
      .getApi(param)
      .then((res) => {
        // console.log('response from get employee status', res.data.response);
        if (res.data.response === "Employee Time In") {
          this.checkinStatus = true;
        } else {
          this.checkinStatus = false;
        }
      })
      .catch((err) => {
        // console.error('error from get user status api:', err);
        window.alert(JSON.stringify(err));
      });
  }
  /**
   * function to perform checkin or checkout
   */
  CheckIn_Out() {
    this.setState({
      loading: true,
    });
    const param = "/CheckEmployeeStatus?empid=" + this.employeeDetail.empid;
    api
      .getApi(param)
      .then((res) => {
        // console.log('response from get employee status', res.data.response);
        const param2 = {
          api: "/EmployeeTimeInOut",
          data: {
            empid: this.employeeDetail.empid,
            events: "TimeIn",
          },
        };
        if (res.data.response === "Employee Time In") {
          param2.data.events = "TimeOut";
          this.checkout = false;
          this.showConfirm = true;
          this.timeInterval = setInterval(this.ShowTime.bind(this), 1000);
        } else {
          this.checkinStatus = true;
          this.checkout = true;
          this.setState({
            time: 0,
          });
        }
        // console.log(this.showConfirm);
        if (this.checkout) {
          api
            .postApi(param2)
            .then((response) => {
              // console.log('response from checkin or checkout api', response);
              this.ShowHistory();
              this.setState({
                loading: false,
              });
              this.GetProjects();
              this.setState({
                projectModal: false,
              });
            })
            .catch((error) => {
              // console.error('error from checkin or checkout api:', error);
              window.alert(JSON.stringify(error));
              this.setState({
                loading: false,
              });
            });
        }
      })
      .catch((err) => {
        // console.error('error from get employee status', err);
        window.alert(JSON.stringify(err));
      });
  }

  /**
   * function to change the time every second
   */
  ChangeTime() {
    var date = new Date();
    this.setState({
      Hours: date.getHours(),
      min: date.getMinutes(),
      sec: date.getSeconds(),
      date: date.getDate(),
      year: date.getFullYear(),
    });
  }

  /**
   * function to close the alert of confirmation
   */
  closeAlert() {
    this.showConfirm = false;
    this.checkout = false;
    clearInterval(this.timeInterval);
    this.setState({
      loading: false,
      time: 0,
    });
  }

  /**
   * functions to get the projects
   */
  GetProjects() {
    this.setState({
      modalIsOpen: true,
      projectModal: true,
    });
    const param = "/ShowProjects";
    api
      .getApi(param)
      .then((res) => {
        // console.log(res);
        if (res.data.status === 200) {
          this.setState({
            projectarray: res.data.response,
          });
          // console.log(this.state.projectarray);
          localStorage.setItem(
            "projectJSON",
            JSON.stringify(this.state.projectarray)
          );
        } else {
          window.alert(JSON.stringify(res));
        }
      })
      .catch((err) => {
        // console.log(err);
        window.alert(JSON.stringify(err));
      });
  }

  /**
   * function to get the holidaylist
   */
  async ShowHoliday() {
    this.setState({
      loadingL: true,
    });
    const param = "/ShowOfficeHoliday";
    await api
      .getApi(param)
      .then((res) => {
        console.log(res);
        holidaysJSON = res.data.response;
        this.setState({
          loadingL: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loadingL: false,
        });
      });
  }

  /**
   * function to show the task
   * @param value: value
   */
  Showtask(value) {
    this.setState({
      modalIsOpen: !value,
    });
    if (value) {
    } else {
      this.getTask();
    }
  }

  /**
   * function to confirm the alert of confirmation
   */
  Confirm() {
    this.checkout = true;
    this.showConfirm = false;
    this.checkinStatus = false;
    const param2 = {
      api: "/EmployeeTimeInOut",
      data: {
        empid: this.employeeDetail.empid,
        events: "TimeOut",
      },
    };
    api
      .postApi(param2)
      .then((response) => {
        // console.log('response from checkin or checkout api', response);
        this.ShowHistory();
        this.setState({
          loading: false,
        });
        clearInterval(this.timeInterval);
      })
      .catch((error) => {
        // console.error('error from checkin or checkout api:', error);
        window.alert(JSON.stringify(error));
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * function to add the task
   * */
  Addtask(data, type) {
    if (type === "edit") {
      var param = {
        api: "/AddUpdateEmployeeTask",
        data: {
          tid: 0,
          empid: this.employeeDetail.empid,
          task: this.state.newtask,
        },
      };
    } else {
      var param = {
        api: "/AddUpdateEmployeeTask",
        data: {
          tid: this.state.tid,
          empid: this.employeeDetail.empid,
          task: this.state.newtask,
        },
      };
    }

    this.setState({
      loading: true,
    });
    api
      .postApi(param)
      .then((res) => {
        // console.log(res);
        this.setState({
          newtask: "",
          loading: false,
          tid: 0,
        });
        this.getTask();
      })
      .catch((err) => {
        // console.log(err);
        window.alert(JSON.stringify(err));
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * function to edit the task
   */
  editTask(tid, type) {
    this.setState({
      taskloading: true,
    });
    const param = {
      api: "/UpdateStatus_And_DeleteEmployeeTask",
      data: {
        tid: tid,
        operation: type,
      },
    };
    api
      .postApi(param)
      .then((res) => {
        // console.log(res);
        this.setState({
          taskloading: false,
          newtask: "",
          tid: 0,
        });
        this.getTask();
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          taskloading: false,
          newtask: "",
          tid: 0,
        });
      });
  }

  /**
   * function to get the task of the user
   */
  getTask() {
    this.setState({
      loading: true,
    });
    const param = "/ShowEmployeeTask?empid=" + this.employeeDetail.empid;
    api
      .getApi(param)
      .then((res) => {
        // console.log(res);
        if (res.data.response === "No tasks Found") {
          this.setState({
            taskJson: [],
            loading: false,
          });
        } else {
          this.setState({
            taskJson: res.data.response,
            loading: false,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * function to handle the new task
   * */
  handleTask(ev) {
    this.setState({
      newtask: ev.target.value,
    });
  }

  /***
   * function to update task
   */
  Updatetask(data, type) {
    this.setState({
      newtask: data.task,
      tid: data.tid,
    });
  }

  render() {
    return (
      <HashRouter hashType={"noslash"}>
        <Router basename={"OPM/#dashboard"}>
          <section id="sec">
            {this.state.Hours + ":" + this.state.min === "18:30" && (
              <WebNotification
                title={"Hello " + this.employeeDetail.name}
                icon="../../../public/favicon.png"
                body="Don't Forget to checkout when you checkout from office"
                timeout={9000}
              />
            )}
            <Header />
            <MessageBox />
            <div
              className={"modalarea"}
              show={this.showConfirm}
              style={customStyles2}
            >
              {this.showConfirm && (
                <Toast
                  show={this.showConfirm}
                  onClose={this.closeAlert.bind(this)}
                  className={"confirmAlert"}
                >
                  <Toast.Header>
                    <img src="../../logo.svg" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">Confirm</strong>
                    <small>
                      {this.state.time > 360
                        ? (this.state.time / 360).toFixed(0) + " hours ago"
                        : this.state.time > 60
                        ? (this.state.time / 60).toFixed(0) + " mins ago"
                        : this.state.time + " secs ago"}
                    </small>
                  </Toast.Header>
                  <Toast.Body>
                    <p>Are you leaving?</p>
                    <p className={"confirm"}>
                      By clicking confirm button system will{" "}
                      <strong>check out</strong> on your behalf.
                    </p>
                    <button
                      className={"btn btn-reject float-right"}
                      onClick={this.closeAlert.bind(this)}
                    >
                      Cancel
                    </button>
                    <button
                      className={"btn btn-approve float-right"}
                      onClick={this.Confirm.bind(this)}
                    >
                      Checkout
                    </button>
                  </Toast.Body>
                </Toast>
              )}
            </div>
            <div className="col-12">
              <div className="row dashboard">
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route
                    path={"/dashboard"}
                    render={() => (
                      <div className="col-md-4 col-12 calendarSec text-center">
                        <div className="calender">
                          <Tabs
                            defaultActiveKey="Month"
                            transition={false}
                            id="noanim-tab-example"
                            className="calendar"
                          >
                            <Tab eventKey="Month" title="Month" className={'calendarlist'}>
                              <div>
                                <Calendar value={new Date()} />
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Holidays"
                              title="Holidays"
                              className="holidaylist"
                            >
                              <Holidaylist />
                            </Tab>
                          </Tabs>
                          {this.state.modalIsOpen && (
                            <div className="triangle-left"></div>
                          )}
                          {this.state.modalIsOpen && (
                            <div className={"rahucontent"}>
                              <div className={"row mb-2 addtask"}>
                                <input
                                  type="text"
                                  placeholder={"Write your task"}
                                  className="col-8 addtaskcard offset-2"
                                  value={this.state.newtask}
                                  onKeyDown={(ev) => {
                                    if (ev.keyCode === 13) {
                                      this.Addtask("no data", "add");
                                      localStorage.setItem(
                                        "edittask",
                                        this.state.newtask
                                      );
                                    }
                                  }}
                                  onChange={this.handleTask.bind(this)}
                                />
                                <div className="col-2 text-center">
                                  <button
                                    className={"addtasticonbtn"}
                                    disabled={!this.state.newtask}
                                    onClick={() =>
                                      this.Addtask("no data", "add")
                                    }
                                  >
                                    <i className="fas fa-plus-circle "></i>
                                  </button>
                                </div>
                              </div>
                              <div className={"addlistwrap"}>
                                {this.state.taskJson.map((item) => (
                                  <div className={"row user_tasks"}>
                                    <div className={"col-2 text-center"}>
                                      <i
                                        className="far fa-trash-alt taskicons"
                                        onClick={() =>
                                          this.editTask(item.tid, "Delete")
                                        }
                                      ></i>
                                    </div>
                                    <div
                                      className="col-8 taskcard"
                                      onClick={() =>
                                        this.Updatetask(item, "edit")
                                      }
                                    >
                                      {item.task}
                                    </div>
                                    <div className="col-2 text-center">
                                      <i
                                        className="far fa-check-circle taskicons"
                                        onClick={() =>
                                          this.editTask(
                                            item.tid,
                                            "UpdateStatus"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div
                            className={"rahuwrapper"}
                            onClick={() =>
                              this.Showtask(this.state.modalIsOpen)
                            }
                          >
                            <i className="fas fa-file-alt rahuicon"></i>
                            <p className={"Rahu"}>Rahu</p>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/leaverequest"
                    render={() => (
                      <div className="col-md-4 col-12 calendarSec text-center">
                        <div className="calender">
                          <Tabs
                            defaultActiveKey="Month"
                            transition={false}
                            id="noanim-tab-example"
                            className="calendar"
                          >
                            <Tab eventKey="Month" title="Month" className={'calendarlist'}>
                              <div className="span12">
                                <Calendar value={new Date()} />
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Holidays"
                              title="Holidays"
                              className="holidaylist"
                            >
                              <Holidaylist />
                            </Tab>
                          </Tabs>
                          {this.state.modalIsOpen && (
                            <div className="triangle-left"></div>
                          )}
                          {this.state.modalIsOpen && (
                            <div className={"rahucontent"}>
                              <div className={"row mb-2 addtask"}>
                                <input
                                  type="text"
                                  placeholder={"Write your task"}
                                  className="col-8 addtaskcard offset-2"
                                  value={this.state.newtask}
                                  onKeyDown={(ev) => {
                                    if (ev.keyCode === 13) {
                                      this.Addtask("no data", "add");
                                      localStorage.setItem(
                                        "edittask",
                                        this.state.newtask
                                      );
                                    }
                                  }}
                                  onChange={this.handleTask.bind(this)}
                                />
                                <div className="col-2 text-center">
                                  <button
                                    className={"addtasticonbtn"}
                                    disabled={!this.state.newtask}
                                    onClick={() =>
                                      this.Addtask("no data", "add")
                                    }
                                  >
                                    <i className="fas fa-plus-circle "></i>
                                  </button>
                                </div>
                              </div>
                              <div className={"addlistwrap"}>
                                {this.state.taskJson.map((item) => (
                                  <div className={"row user_tasks"}>
                                    <div className={"col-2 text-center"}>
                                      <i
                                        className="far fa-trash-alt taskicons"
                                        onClick={() =>
                                          this.editTask(item.tid, "Delete")
                                        }
                                      ></i>
                                    </div>
                                    <div
                                      className="col-8 taskcard"
                                      onClick={() =>
                                        this.Updatetask(item, "edit")
                                      }
                                    >
                                      {item.task}
                                    </div>
                                    <div className="col-2 text-center">
                                      <i
                                        className="far fa-check-circle taskicons"
                                        onClick={() =>
                                          this.editTask(
                                            item.tid,
                                            "UpdateStatus"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div
                            className={"rahuwrapper"}
                            onClick={() =>
                              this.Showtask(this.state.modalIsOpen)
                            }
                          >
                            <i className="fas fa-file-alt rahuicon"></i>
                            <p className={"Rahu"}>Rahu</p>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  {/* <Route
                    path="/messages"
                    render={() => (
                      <div className="col-md-4 col-12 calendarSec text-center">
                        <div className="calender">
                          <Tabs
                            defaultActiveKey="Month"
                            transition={false}
                            id="noanim-tab-example"
                            className="calendar"
                          >
                            <Tab eventKey="Month" title="Month">
                              <div className="span12">
                                <Calendar value={new Date()} />
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Holidays"
                              title="Holidays"
                              className="holidaylist"
                            >
                              <Holidaylist />
                            </Tab>
                          </Tabs>
                          {this.state.modalIsOpen && (
                            <div className="triangle-left"></div>
                          )}
                          {this.state.modalIsOpen && (
                            <div className={"rahucontent"}>
                              <div className={"row mb-2 addtask"}>
                                <input
                                  type="text"
                                  placeholder={"Write your task"}
                                  className="col-8 addtaskcard offset-2"
                                  value={this.state.newtask}
                                  onKeyDown={(ev) => {
                                    if (ev.keyCode === 13) {
                                      this.Addtask("no data", "add");
                                      localStorage.setItem(
                                        "edittask",
                                        this.state.newtask
                                      );
                                    }
                                  }}
                                  onChange={this.handleTask.bind(this)}
                                />
                                <div className="col-2 text-center">
                                  <button
                                    className={"addtasticonbtn"}
                                    disabled={!this.state.newtask}
                                    onClick={() =>
                                      this.Addtask("no data", "add")
                                    }
                                  >
                                    <i className="fas fa-plus-circle "></i>
                                  </button>
                                </div>
                              </div>
                              <div className={"addlistwrap"}>
                                {this.state.taskJson.map((item) => (
                                  <div className={"row user_tasks"}>
                                    <div className={"col-2 text-center"}>
                                      <i
                                        className="far fa-trash-alt taskicons"
                                        onClick={() =>
                                          this.editTask(item.tid, "Delete")
                                        }
                                      ></i>
                                    </div>
                                    <div
                                      className="col-8 taskcard"
                                      onClick={() =>
                                        this.Updatetask(item, "edit")
                                      }
                                    >
                                      {item.task}
                                    </div>
                                    <div className="col-2 text-center">
                                      <i
                                        className="far fa-check-circle taskicons"
                                        onClick={() =>
                                          this.editTask(
                                            item.tid,
                                            "UpdateStatus"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div
                            className={"rahuwrapper"}
                            onClick={() =>
                              this.Showtask(this.state.modalIsOpen)
                            }
                          >
                            <i className="fas fa-file-alt rahuicon"></i>
                            <p className={"Rahu"}>Rahu</p>
                          </div>
                        </div>
                      </div>
                    )}
                  /> */}
                  <Route
                    path="/journal"
                    render={() => (
                      <div className="col-md-4 col-12 calendarSec text-center">
                        <div className="calender">
                          <Tabs
                            defaultActiveKey="Month"
                            transition={false}
                            id="noanim-tab-example"
                            className="calendar"
                          >
                            <Tab eventKey="Month" title="Month" className={'calendarlist'}>
                              <div className="span12">
                                <Calendar value={new Date()} />
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Holidays"
                              title="Holidays"
                              className="holidaylist"
                            >
                              <Holidaylist />
                            </Tab>
                          </Tabs>
                          {this.state.modalIsOpen && (
                            <div className="triangle-left"></div>
                          )}
                          {this.state.modalIsOpen && (
                            <div className={"rahucontent"}>
                              <div className={"row mb-2 addtask"}>
                                <input
                                  type="text"
                                  placeholder={"Write your task"}
                                  className="col-8 addtaskcard offset-2"
                                  value={this.state.newtask}
                                  onKeyDown={(ev) => {
                                    if (ev.keyCode === 13) {
                                      this.Addtask("no data", "add");
                                      localStorage.setItem(
                                        "edittask",
                                        this.state.newtask
                                      );
                                    }
                                  }}
                                  onChange={this.handleTask.bind(this)}
                                />
                                <div className="col-2 text-center">
                                  <button
                                    className={"addtasticonbtn"}
                                    disabled={!this.state.newtask}
                                    onClick={() =>
                                      this.Addtask("no data", "add")
                                    }
                                  >
                                    <i className="fas fa-plus-circle "></i>
                                  </button>
                                </div>
                              </div>
                              <div className={"addlistwrap"}>
                                {this.state.taskJson.map((item) => (
                                  <div className={"row user_tasks"}>
                                    <div className={"col-2 text-center"}>
                                      <i
                                        className="far fa-trash-alt taskicons"
                                        onClick={() =>
                                          this.editTask(item.tid, "Delete")
                                        }
                                      ></i>
                                    </div>
                                    <div
                                      className="col-8 taskcard"
                                      onClick={() =>
                                        this.Updatetask(item, "edit")
                                      }
                                    >
                                      {item.task}
                                    </div>
                                    <div className="col-2 text-center">
                                      <i
                                        className="far fa-check-circle taskicons"
                                        onClick={() =>
                                          this.editTask(
                                            item.tid,
                                            "UpdateStatus"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div
                            className={"rahuwrapper"}
                            onClick={() =>
                              this.Showtask(this.state.modalIsOpen)
                            }
                          >
                            <i className="fas fa-file-alt rahuicon"></i>
                            <p className={"Rahu"}>Rahu</p>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  {/* <Route path="/userTasks" render={() => <UserTask />} />

                  <Route path="/tracker" render={() => <Tracker />} /> */}

                </Switch>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route
                    path="/dashboard"
                    render={() => (
                      <div className="col-md-4 dashclock">
                        {JSON.parse(localStorage.getItem("LeaveRequest")) ? (
                          <LeaveRequest />
                        ) : this.state.loading ? (
                          ""
                        ) : (
                          <div className="clock">
                            <div
                              className={
                                this.checkinStatus
                                  ? "clockbg active"
                                  : "clockbg"
                              }
                              onClick={this.CheckIn_Out.bind(this)}
                            >
                              <h1>
                                {this.state.Hours >= 10
                                  ? this.state.Hours
                                  : "0" + this.state.Hours}
                                :
                                {this.state.min >= 10
                                  ? this.state.min
                                  : "0" + this.state.min}
                                :
                                {this.state.sec >= 10
                                  ? this.state.sec
                                  : "0" + this.state.sec}
                              </h1>
                              <h4>
                                {this.state.date +
                                  " " +
                                  this.state.MonthArray[new Date().getMonth()] +
                                  ", " +
                                  this.state.year}
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <Route
                    path="/leaverequest"
                    render={() => (
                      <div className="col-md-4 leaveSecCenter">
                        <LeaveRequest />
                      </div>
                    )}
                  />
                  <Route
                    path="/journal"
                    render={() => (
                      <div className={"col-md-8 col-12"}>
                        <UserJournal />
                      </div>
                    )}
                  />
                  {/* <Route
                    path="/messages"
                    render={() => (
                      <div className={"col-md-8 col-12"}>
                        <Messages />
                      </div>
                    )}
                  /> */}
                </Switch>
                <div className="col-md-4 rightCard">
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    <Route
                      path="/leaverequest"
                      render={() => (
                        <div className="attSec">
                          <ul>
                            <AttendanceList />
                          </ul>
                        </div>
                      )}
                    />
                    <Route
                      path="/dashboard"
                      render={() => (
                        <div className="attSec">
                          <ul>
                            <AttendanceList />
                          </ul>
                        </div>
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
            {this.state.loading && (
              <div className="loading">
                <div className="loader">
                  <BounceLoader
                    sizeUnit={"px"}
                    css={loaderCSS}
                    size={80}
                    color={"#66CC75"}
                    loading={this.state.loading}
                  />
                </div>
              </div>
            )}
          </section>
        </Router>
      </HashRouter>
    );
  }
}
function AttendanceList() {
  const list = attendancelist.map((number) => (
    <AttendanceCard
      key={number.i}
      index={number.i}
      Day={number.day}
      Date={number.date}
      time={number.timeinout}
      totalTime={number.time}
      attendance={number.attaindance}
    />
  ));
  return list;
}

function Holidaylist() {
  if(Array.isArray(holidaysJSON)){
    if(holidaysJSON.length !== 0){
    // console.log('holidaysJSON is array');
    const list = holidaysJSON.map((data) => <Holidays data={data} />);
    return list;
  }
  else{
    const list = 'No holidays left in this year';
    return list;
  }
  }
}
