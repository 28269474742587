import React from 'react';
import './UserTask.css';
import { Modal, Form } from 'react-bootstrap'; 
export default class Task extends React.Component {
    userDetail = {};
    applyingFor = 'E';
    toaster = {};
    date = {};
    constructor(props) {
        super(props);
        this.state = {
            showDatePicker: false, 
            toasterStatus: false,
            toasterMessage: '',
            toasterHeader: '',
            time: 0,
            showT: false,
            loadingL: false
        }; 
    }
 

    // modal function
    /**
  * function to open the leave modal
  */
 OpenDayCountmodal(data) {
    this.setState({
        pendingDetailsModal: true
    })
} 

    render() { 
        return (
            <section className='task'>
                <h6 className={'taskHeadding'}>Archived Tasks</h6>
                <div className={'row'}>
                    <div className={'col-md-4'}>
                        <div class="card pendingCard">
                            <div class="card-header">Pending</div>
                            <div class="card-body">
                                <ul>
                                    <li onClick={() => (this.OpenDayCountmodal(true))}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-4'}>
                        <div class="card ProgressCard">
                            <div class="card-header">In Progress</div>
                            <div class="card-body">
                                <ul>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-4'}>
                        <div class="card CompletedCard">
                            <div class="card-header">Completed</div>
                            <div class="card-body">
                                <ul>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
                 <div>
                 <Modal 

                        dialogClassName="modal-70w" 
                    show={this.state.pendingDetailsModal}
                  
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <section className={'pendingModal'}> 
                        <h6 className={'peddingHead'}>Pending</h6> 
                        <h4 className={'mainHeading'}>Lorem Ipsum is simply dummy text of the  printing and typesetting industry.</h4>
                        <p className={'taskRemaining'}>Task due in 16 hours</p>
                        <div className={'taskDetail'}>
                        <Form className={"form"}>
                          <Form.Control
                            placeholder="Details about the task"
                            type="text" />
                            <Form.Control
                            placeholder="Subtask"
                            type="text" />
                         </Form>
                        </div>
                        <div className={'asignPeople'}>
                         <h6>Assigned people</h6>
                         <ul>
                             <li>
                             <img src="../iron-man.jpg" alt="user-img" className="img-fluid" />
                             <p>Becky</p>
                             </li>
                             <li>
                             <img src="../iron-man.jpg" alt="user-img" className="img-fluid" />
                             <p>John</p>
                             </li>
                         </ul>
                        </div>
                        <div className="float-right mb-1 text-right" role="group" aria-label="Basic example">
                            <button className={'approve_reject_button btn btn-reject btnMark'} onClick={() => (
                                this.setState({
                                    pendingDetailsModal: false
                                })
                            )}>Mark In Progress </button>
                            <button className={'approve_reject_button btn btn-approve btnMark'} onClick={() => (
                                this.setState({
                                    pendingDetailsModal: false
                                })
                            )}>Mark Done</button>
                        </div>
                    </section>
                </Modal>
                 </div>   
            </section>
        )
    }
}
