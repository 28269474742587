import React from 'react';
import './userProjectDashboard.css'
export default class UserProjectDashboard extends React.Component{
    render(){
        return(
            <div className="col-md-12"> 
                    <div className="projecDetail">
                        <div className="boxcss"> 
                        <h4> DashBoard</h4>
                        </div>
                    </div> 
            </div>
        )// return end..
    }// render end..
}// class end..