import React from 'react';
import './signup.css';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Name', 'Website', 'Logo', 'Days', 'Country', 'Time', 'Hours'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <div>
          <ul>
              <li>
                <input type="text"   name="name" placeholder="Name of your organization"/>
              </li>
              <li>Your Website</li>
              <li>Your Logo</li>
              <li>Working days</li>
              <li>Country</li>
              <li>Time Zone (multiple)</li>
              <li>Working hours</li>
          </ul>
      </div>;
    case 1:
      return <div>
      <ul>
          <li>Name of your organization</li>
          <li><input type="text" name="Website" placeholder="Your Website"/></li>
          <li>Your Logo</li>
          <li>Working days</li>
          <li>Country</li>
          <li>Time Zone (multiple)</li>
          <li>Working hours</li>
      </ul>
  </div>;
    case 2:
      return <div>
      <ul>
          <li>Name of your organization</li>
          <li>Your Website</li>
          <li> <h5><span className="logo"></span>Your Logo</h5></li>
          <li>Working days</li>
          <li>Country</li>
          <li>Time Zone (multiple)</li>
          <li>Working hours</li>
      </ul>
  </div>;
    case 3:
      return <div>
      <ul>
          <li>Name of your organization</li>
          <li>Your Website</li>
          <li>Your Logo</li>
          <li>
              <div className="workingDays">
              <div className="row">
              <div className="col-md-4">
              <h4>Working days</h4></div>
                  <div className="col-md-8 text-right">
                      <ul className="days">
                          <li>Sun</li>
                          <li>Mon</li>
                          <li>Tue</li>
                          <li>Wed</li>
                          <li>Thu</li>
                          <li>Fri</li>
                          <li>Sat</li>
                      </ul>
                  </div>
              </div> 
              </div>
          </li>
          <li>Country</li>
          <li>Time Zone (multiple)</li>
          <li>Working hours</li>
      </ul>
  </div>;
    case 4:
      return <div>
          <ul>
          <li>Name of your organization</li>
          <li>Your Website</li>
          <li>Your Logo</li>
          <li>Working days</li>
          <li>
          <div>
      <select id="country" name="country">
      <option value="Country">Country</option>
<option value="India">India</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="American">American</option>
<option value="Andorra">Andorra</option>
</select>
      </div>
          </li>
          <li>Time Zone (multiple)</li>
          <li>Working hours</li>
      </ul>
      </div>;
    case 5:
      return <div>
      <ul>
      <li>Name of your organization</li>
      <li>Your Website</li>
      <li>Your Logo</li>
      <li>Working days</li>
      <li>Country</li>
      <li><input type="text"   name="name" placeholder="Time Zone (multiple)"/></li>
      <li>Working hours</li>
  </ul>
  </div>;
    case 6:
      return <div>
      <ul>
      <li>Name of your organization</li>
      <li>Your Website</li>
      <li>Your Logo</li>
      <li>Working days</li>
      <li> </li>
      <li>Time Zone (multiple)</li>
      <li><input type="text"   name="name" placeholder="Working hours"/></li>
  </ul>
  </div>;
    default:
      return 'Unknown step';
  }
}

export default function HorizontalNonLinearAlternativeLabelStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <section className="signupDetails">
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {}; 
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div className="maincontent">  
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
           
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div className="text-right">   
              {activeStep !== steps.length &&
                (completed.has(activeStep) ? (
                  <Typography variant="caption" className={classes.completed}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Next'}
                  </Button>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
    </section>
  );
}